import React, {Text} from 'react';
import './../../css/main.scss';

const talon = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
    respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída o mal gesto del talón'},
                {id:2,text:'Ha empezado con el tiempo',trigger:7,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                {id:3,text:'No lo sé',trigger:7,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },
    //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías especificar cómo ha sido el traumatismo que asocias a la lesión?',
        respuestas:[{id:1,text:'Un mal gesto/gesto forzado', masInfo:'Un movimiento o gesto inusual del talón', trigger:2,asociacion:0},
                    {id:3,text:'Un traumatismo directo sobre el talón', masInfo:'Un golpe o choque directo sobre el talón', trigger:2,asociacion:1},
                    {id:2,text:'No lo sé', masInfo:'no estoy seguro o no es ninguna de las acciones anteriores', trigger:2,asociacion:2}],
        template:10,
      },
    {
        id:2,
        preg:'¿Y su grado del dolor?',
        type:'trauma',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Cuál ha sido el grado de inflamación?',
        type:'inflamacion',
        template:3,
        trigger:4,
    },
    {
        id:4,
        preg: [ 
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Se reproduce tu dolor al presionar sobre el talón por ambos lados?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON1.jpg'},
          {text:'¿Y al presionar en el centro del talón?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON2.jpg'},
          {text:'¿Se reproduce tu dolor al ponerte de puntillas sobre el pie lesionado?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/PUNTILLASREPETIDO.jpg'},
          {text:'¿Has notado una sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON3.jpg'},
          {text:'¿Has sentido como si se hubiese arrancado algo en la planta del pie en el momento de la lesión?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Es una sensación como si se hubiese roto la suela del zapato'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON4.jpg'
          },
          {text:'¿Has presentado incapacidad para mover el pie en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON5.jpg'},
          {text:'Por último, ¿has sido incapaz para apoyar el peso del cuerpo después de la lesión?',
            masInfo:{
              state:true,
              titulo:'Más información',
              text:'Apoyar el pie afectado en el suelo resulta imposible debido al dolor'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON6.jpg'
          },
    ],
        trigger:5,
        template:7,
    },

    {
      id:5,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:6,
    },
    {
      id:6,
      respuestas:[],
      template:8,
      trigger:5,
    },
    
    //TRAUMA NO
    {
        id:7,
        preg:'¿En cuál de las siguientes imagenes se refleja mejor el dolor?',
        respuestas:[{id:1,text:'Anterior en el talón',masInfo:'El dolor se localiza entre el talón y la planta del pie',trigger:8,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON8ANTERIOR.jpg'},
                    {id:3,text:'Centro del talón',masInfo:'El dolor es localizado en el centro del talón',trigger:8,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON8CENTRO.jpg'},
                    {id:2,text:'Difuso',masInfo:'El dolor es más general y difícil de localizar en un punto concreto',trigger:8,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON7AMBOS.jpg'}],
        template:4,
      },
      {
        id:8,
        preg:'¿En cuál de los siguientes puntos se aumenta el dolor al presionar?',
        respuestas:[{id:1,text:'Anterior en el talón ',masInfo:'El punto entre el talón y la planta del pie',trigger:9,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON7ANTERIOR.jpg'},
                    {id:2,text:'Centro del talón',masInfo:'El punto se ubica en el centro del talón',trigger:9,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON7CENTRO.jpg'},
                    {id:3,text:'Ambos',masInfo:'El dolor al presionar en los dos puntos',trigger:9,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON7AMBOS.jpg'},
                    {id:4,text:'Ninguno de ellos',masInfo:'El dolor no se exacerba al presionar en ninguno de los puntos',trigger:9,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON7NINGUNO.jpg'}],
        template:4,
      },
      {
        id:9,
        preg:'¿En qué momento del día molestan más los síntomas?',
        respuestas:[{id:1,text:'Por la mañana',masInfo:'Los sintomas son más intensos en las primeras horas',trigger:10,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/DIA.jpg'},
                    {id:2,text:'Por la noche',masInfo:'Los síntomas son más intensos al final del día',trigger:10,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/NOCHE.jpg'},
                    {id:3,text:'Indiferente',masInfo:'Los síntomas no cambian durante el día',trigger:10,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NOLOSEREPETIDO.jpg'}],
        template:10,
      },
      {
        id:10,
        preg: [ 
          {text:'¿Has tenido lesiones previas del talón o de la planta del pie?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON5.jpg'},
          {text:'¿Has aumentado la carga de entrenamiento o las distancias al andar?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'¿Habías aumentado la actividad física en los últimos días o semanas antes de que empezará el dolor?'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON9.jpg'
          },
          {text:'¿El dolor se irradia desde el talón hacia la planta y los dedos del pie?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON3.jpg'},
          {text:'¿Presentas sensación de hormigueo o adormecimiento en la planta del pie?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON10.jpg'},
          {text:'¿Y de chasquido o rotura en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON4.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿El dolor aumenta al realizar una presión por ambos lados en el talón?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/TALON/TALON1.jpg'},
          {text:'Por último, ¿el dolor empeora al llevar los dedos hacia arriba (hiperextensión pasiva) con la mano?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Sobretodo el dolor se reproduce al elevar el dedo gordo'
              },
              imagen:'x',
              video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/PLANTA+PIE/EXTENSIONDEDOS.mp4'

          },
  ],
        trigger:11,
        template:7,
      },

    {
      id:11,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:12,
    },
    {
      id:12,
      respuestas:[],
      template:8,
    }]

export default talon