import React, {Text} from 'react';
import './../../css/main.scss';

const cervical = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe o mal gesto de la cabeza/cuello'},
                  {id:2,text:'Época de estrés y/o de ansiedad',trigger:6,asociacion:1,masInfo:'El inicio del dolor está asociado a una época de estrés y/o de ansiedad ' },
                  {id:3,text:'Ha empezado con el tiempo',trigger:6,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:6,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar el grado de traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Y cuál ha sido la intensidad del dolor?',
        type:'trauma',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg: 
        [{text:'¿Has sufrido un accidente automovilístico relacionado con el inicio del dolor?', masInfo:{state:false,},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA2REP.jpg'},
        {text:'¿Llevas más de un mes con los síntomas?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},   
        {text:'¿Presentas sensación de mareo?',
          masInfo:{
            state:true,
            titulo:'Más información',
            text:'Presentar mareos se relaciona con la sensación de que vas a perder el equilibrio'
          },
          imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA5REP.jpg'
        },
        {text:'¿Presentas sensación de rigidez al mover el cuello?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA2REP.jpg'},
        {text:'¿Y bloqueo al mover el cuello?',
          masInfo:{
            state:true,
            titulo:'¿Qué es exactamente un bloqueo?',
            text:'una limitación del movimiento, siendo imposible mover el cuello en todo su rango de movimiento'
          },
          imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZACERVICAL8.jpg'
        },
        {text:'¿Presentas sensación de pesadez o sobrecarga en la parte posterior del cuello?',
          masInfo:{
            state:true,
            titulo:'Más información',
            text:'La sensación de pesadez se relaciona con la presencia de rigidez al tocar los músculos de la nuca'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA7REP.jpg'
          },
          {text:'Por último, ¿el dolor se irradia hacia la mano?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL/CERVICAL1.jpg'}, 
      ],
        trigger:4,
        template:7,
    },
    {
      id:4,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:5,
    },
    {
      id:5,
      respuestas:[],
      template:8,
    },
    
    //TRAUMA NO
    {
        id:6,
        preg:'¿Relacionas tu dolor con alguno de los siguientes motivos?',
        respuestas:[{id:1,text:'Movimiento o gesto brusco con la cabeza',masInfo:'He realizado un giro o inclinación rapido de la cabeza',trigger:7,asociacion:0},
                    {id:2,text:'Mantener la cabeza en una mala posición durante un tiempo prolongado',masInfo:'Por ejemplo, dormir con la cabeza y cuello en una mala posición',trigger:7,asociacion:1},
                    {id:3,text:'Ninguna de las anteriores',masInfo:'No asocio mi dolor a ninguno de los motivos anteriores',trigger:7,asociacion:2}],
        template:10,
    },
    {
        id:7,
        preg:'¿Me podrias indicar cuál ha sido la intensidad del dolor?',
        type:'trauma',
        template:3,
        trigger:8,
    },
      {
        id:8,
        preg: [ {text:'¿Has presentado sensación de rigidez al mover el cuello?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA2REP.jpg'},   
                {text:'¿Llevas más de un mes con tu dolor?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},   
                {text:'¿Sientes un bloqueo al mover el cuello?',
                  masInfo:{
                    state:true,
                    titulo:'¿Qué es exactamente un bloqueo?',
                    text:'Un bloqueo es una limitación del movimiento, siendo imposible mover el cuello en todo su rango de movimiento'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZACERVICAL8.jpg'
                },
                {text:'¿Y una sensación de pesadez o sobrecarga en la parte posterior del cuello?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'La sensación de pesadez se relaciona con la presencia de rigidez al tocar los músculos de la nuca'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA7REP.jpg'
                },
                {text:'¿El dolor se irradia hacia el brazo/mano?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL/CERVICAL1.jpg'},
                {text:'¿Has presentado sensación de dolor eléctrico u hormigueo?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL/CERVICAL2.jpg'},
                {text:'Por último, ¿permaneces mucho tiempo en posturas mantenidas?',
                  masInfo:{
                    state:true,
                    titulo:'Ver ejemplos',
                    text:'Por ejemplo, más de 4 o 5 horas delante del ordenador'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL/CERVICAL3.jpg'
                },
      ],
        trigger:9,
        template:7,
      },

    {
      id:9,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:10,
    },
    {
      id:10,
      respuestas:[],
      template:8,
    }]

export default cervical