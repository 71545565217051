import React, {Text} from 'react';
import './../../css/main.scss';

const codoAnterior = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída o mal gesto del codo'},
                  {id:2,text:'Un sobresfuerzo',trigger:7,asociacion:1,masInfo:'Realizar un esfuerzo excesivo o más de lo habitual'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:7,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:7,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },
    //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Cuál ha sido el grado de inflamación?',
        type:'inflamacion',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Y la intensidad del dolor al mover el codo en el momento de la lesión?',
        type:'trauma',
        template:3,
        trigger:4,
    },
      {
        id:4,
        preg: [ {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
                {text:'¿Has notado una sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR1.jpg'},
                {text:'¿Has presentado deformidad en el codo después de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR2.jpg'},
                {text:'Por último, ¿has sentido como si el codo hubiese salido y entrado de su sitio?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Sensación de desplazamiento inusual del codo (subluxación del codo)'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR3.jpg'
                },    
        ],
        trigger:5,
        template:7,
      },

    {
      id:5,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:6,
    },
    {
      id:6,
      respuestas:[],
      template:8,
      trigger:5,
    },
    
    //TRAUMA NO
 
      {
        id:7,
        preg: [ {text:'¿Realizas alguna actividad laboral o deportiva con movimientos repetitivos de muñeca y mano?', 
                  masInfo:{
                    state:true,
                    titulo:'Ver ejemplos',
                    text:'Actividades laborales con el destornillador, oficinistas, jardineros, tenistas, escaladores, etc.'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR4.jpg'
                },
                {text:'¿Se reproduce tu dolor al presionar alrededor del hueso prominente en la parte externa del codo (epicóndilo)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/EXTERNA/CODOANTERIOR5.jpg'},
                {text:'¿Y al presionar alrededor del hueso prominente en la parte interna del codo (epitróclea)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR6.jpg'},
                {text:'Perfecto, ¿Se reproduce tu dolor al realizar una flexión de muñeca contra resistencia?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Realiza este movimiento aplicando fuerza contra tu mano'
                  },
                  imagen:'x',
                  video: 'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/Flexionresis.mp4'
                },
                {text:'¿Y al realizar una extensión de muñeca contra resistencia?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Realiza este movimiento aplicando fuerza contra tu mano'
                  },
                  imagen:'x',
                  video: 'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/Extensionresis.mp4'

                },
                {text:'¿Se reproduce tu dolor al realizar una pronación de muñeca contra resistencia?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Realiza este movimiento aplicando fuerza contra tu mano'
                  },
                  imagen:'x',
                  video: 'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/Pronacionresis.mp4'
                },
                {text:'¿Y al realizar una supinación de muñeca contra resistencia?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Realiza este movimiento aplicando fuerza contra tu mano'
                  },
                  imagen:'x',
                  video: 'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/Supinacionresis.mp4'
                },
                {text:'¿Has presentado sensación de hormigueo o parestesias en los 1º,2º y 3º dedos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR11.jpg'},
                {text:'¿Y en los 4º y 5º dedos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR12.jpg'},
                {text:'¿El dolor se irradia desde el hombro hasta el codo o hasta la mano?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR13.jpg'},
                {text:'Por último, ¿presentas sensación de debilidad o falta de fuerza en la mano a la hora de agarrar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR14.jpg'},
      ],
        trigger:8,
        template:7,
      },

    {
      id:8,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:9,
    },
    {
      id:9,
      respuestas:[],
      template:8,
      trigger:5,
    }]

export default codoAnterior