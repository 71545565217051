import React, {Text} from 'react';
import './../../css/main.scss';

const tobilloAnterior = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída con mal apoyo o mal gesto del tobillo'},
                  {id:2,text:'Ha empezado con el tiempo',trigger:9,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:3,text:'No lo sé',trigger:9,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
      {
          id:1,
          preg:'¿Cómo ha sido el traumatismo que asocias al dolor?',
          respuestas:[{id:1,text:'Golpe directo',masInfo:'Un golpe o choque sobre el tobillo',trigger:2,asociacion:0},
                      {id:2,text:'Mal apoyo / Movimiento forzado',masInfo:'Un mal gesto al apoyar',trigger:2,asociacion:1},
                      {id:3,text:'No lo sé',masInfo:'No estoy seguro o el traumatismo ha sido diferente',trigger:2,asociacion:2},],
          template:10,
    },   
    {
      id:2,
      preg:'¿Cuál ha sido su intensidad?',
      type:'trauma',
      template:3,
      trigger:3,
    },
    {
        id:3,
        preg:'¿En qué parte es el dolor más intenso?',
        respuestas:[{id:1,text:'Superior',masInfo:'En la parte alta del tobillo',trigger:4,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO6SUPERIOR.jpg'},
                    {id:2,text:'Inferior',masInfo:'En la parte baja del tobillo y/o empeine',trigger:4,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO6INFERIOR.jpg'},
                   ],
        template:4,
      },
      
    {
      id:4,
      preg:'¿Has presentado una inflamación? ',
      respuestas:[{id:1,text:'Sí, una pequeña inflamación',masInfo:'El tobillo se ha hinchado un poquito',trigger:5,asociacion:0},
                  {id:2,text:'Sí, está bastante hinchado',masInfo:'El tobillo se ha hinchado notablemente',trigger:5,asociacion:1},
                  {id:3,text:'Sí, la inflamación es grande',masInfo:'El tobillo ha experimentado una gran inflamación',trigger:5,asociacion:2},
                  {id:4,text:'No',masInfo:'El tobillo no ha experimentado ninguna inflamación',trigger:5,asociacion:3}],
      template:10,
  },

    {
        id:5,
        preg:'¿Cuanto tiempo llevas con el dolor?',
        respuestas:[{id:1,text:'Es reciente',masInfo:'Menos de tres meses',trigger:6,asociacion:0},
                    {id:2,text:'Ya llevo mucho tiempo',masInfo:'Más de tres meses',trigger:6,asociacion:1},],
        template:10,
    },
      {
        id:7,
        preg: [
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Y una sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO1.jpg'},
          {text:'¿Presentas dolor al mover el pie hacia abajo (flexión plantar)?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONPLANTAR.mp4'},
          {text:'¿Y al llevarlo hacia arriba (flexión dorsal)?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONDORSAL.mp4'},
          {text:'¿Has presentado incapacidad para mover el tobillo en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO2.jpg'},
          {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO3.jpg'},

  ],
        trigger:7,
        template:7,
    },
    {
      id:7,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:8,
    },
    {
      id:8,
      respuestas:[],
      template:8,
    },
    
    //TRAUMA NO
    {
        id:9,
        preg:'¿En qué parte es el dolor más intenso?',
        respuestas:[{id:1,text:'Superior',masInfo:'En la parte alta del tobillo',trigger:10,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO6SUPERIOR.jpg'},
                    {id:2,text:'Inferior',masInfo:'En la parte baja del tobillo y/o empeine',trigger:10,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO6INFERIOR.jpg'},
                   ],
        template:4,
      },
      {
        id:10,
        preg: [
          {text:'¿Has tenido lesiones previas de tobillo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO2.jpg'},
          {text:'¿Practicas algún deporte (3-4 veces/semana) que implique impactos repetidos para el pie?',
              masInfo:{
                state:true,
                titulo:'Ver ejemplos',
                text:'Actividades como correr largas distancias, baloncesto, fútbol, etc.'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/SPRINTREPETIDODEFINITVO.jpg'
          },
          {text:'¿Has incrementado la carga de entrenamiento o aumentado las distancias al andar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO8.jpg'},
          {text:'¿Presentas sensación de sobrecarga en la parte anterior de la pantorrilla?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO9.jpg'},
          {text:'¿Y sensación de rigidez por la mañana?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Al levantarte de cama los primeros pasos duelen pero al rato disminuye el dolor'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO10.jpg'
          },
          {text:'¿Presentas enrojecida y/o hinchada la zona de dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/DEDO6.jpg'},
          {text:'¿El dolor empeora al presionar sobre la zona de dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/ANTERIOR/TOBILLO12.jpg'},
          {text:'¿Se reproduce tu dolor al realizar una flexión plantar?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONPLANTAR.mp4'},
          {text:'¿Y al realizar una flexión dorsal?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONDORSAL.mp4'},
        
        ],
        trigger:11,
        template:7,
      },

    {
      id:11,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:12,
    },
    {
      id:12,
      respuestas:[],
      template:8,
      trigger:5,
    }]

export default tobilloAnterior