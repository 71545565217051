import React, {Component} from 'react';
import '../css/text-template.scss';
import '../css/main.scss';
import Fade from 'react-reveal/Fade';
import logo from './../img/logoAzulOscuro.png'
import './../css/animate.scss';


class Loading extends Component{
    constructor(props){
        super(props)
        this.state = {
            numeroTexto:0,
            cssText:'textBlock',
            cssSpinner:'display-none',
            cssQuote:'display-none',
            error:false    
        }
        this.handleCss = this.handleCss.bind(this)
    }
    handleCss = ()=> {
        console.log('acceso2')
        this.setState({
            cssText:'display-none',
            cssSpinner:"loadingio-spinner-eclipse-dhj14gaj5gi",
            cssQuote:'quote-container',
            numeroTexto: this.state.numeroTexto +1
    })
    }
    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({numeroTexto: this.state.numeroTexto +1});
          
        }, 3000);
      }
      
      componentWillUnmount() {
        clearInterval(this.interval);
      }

      componentDidUpdate(){

        const longitudArrayPreguntas = this.props.preguntas.preg.length;
        console.log('state',this.state)
        console.log('update')
        if(this.state.numeroTexto==1){
            console.log('acceso a handlecss')
            this.handleCss()
        }

        if(this.state.numeroTexto>2 && this.props.state.loading == false){

            this.props.handleclick(this.props.state.articulacion,this.props.state.zonaDolor,this.props.preguntas.trigger)
        }
        if(this.state.numeroTexto == 6){
            this.setState({error:true,numeroTexto:7})
            
        }
        if(this.state.numeroTexto == 10){
            this.props.handleclick(0,0,0)
            
        }
      }
      
    render(){

        return(         
            <div className='primaryContainer'>                
                 <div className='logo'>
                    <img src={logo} width='115px' height='40px'/>
                </div> 
                     
            <div className={this.state.cssText}>        
                <div className='line'>      
                    {this.props.preguntas.preg[this.state.numeroTexto]}
                </div>  
            </div>
           
            <div className='emptyBlock-loading'>
                <div class={this.state.cssSpinner}><div class="ldio-mods6jvarp">
                <div></div>
                </div></div>
                {this.state.error===true?
                <p className='text-aurya' style={{textAlign:'center'}}>Ha habido un error vuelve a intentarlo</p>:''
            }
            </div>

            </div>
            
        )
    }

}
//<Flip spy={this.state.numeroTexto} opposite='true' appear='true' top cascade></Flip>


export default Loading;