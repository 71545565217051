const lumbar = [
  {
  id:0,
  preg:'',
  nombre:'lumbar',
  respuestas:[{id:1,text:'Zona lumbar',trigger:1,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/zonaLumbar.jpg',masInfo:'El dolor es localizado en la zona lumbar'},
              {id:2,text:'Irradiado al muslo',trigger:2,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/irradiadoRodilla+(3).jpg',masInfo:'El dolor se irradia desde la zona lumbar hasta el muslo'},
              {id:3,text:'Irradiado al pie',trigger:3,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/irradiadoPie.jpg',masInfo:'El dolor se irradia desde la zona lumbar hasta el pie'}],
  template:2,
}]

export default lumbar