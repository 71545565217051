import React, {Text} from 'react';
import './../../css/main.scss';


const torax = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias el inicio del dolor?',
      respuestas:[{id:1,text:'Un traumatismo',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un traumatismo o golpe directo sobre las costillas'},
                  {id:2,text:'Un mal gesto',trigger:7,asociacion:1,masInfo:'El inicio del dolor se asocia a un mal gesto o movimiento brusco'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:11,asociacion:2,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:11,asociacion:2,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },
    //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Y el grado de dolor en el momento de la lesión?',
        type:'trauma',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Me podrías indicar en qué zona del tórax son más intensos los síntomas?',
        respuestas:[{id:1,text:'Superior',masInfo:'A la altura de la clavícula y pectoral',trigger:4,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/TORAX1.jpg'},
                    {id:2,text:'Inferior',masInfo:'A la altura del pectoral y costillas',trigger:4,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/TORAX1.2.jpg'}],
        template:4,
      },
      {
        id:4,
        preg: [
          {text:'¿Llevas más de 3 meses con el dolor ?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Se reproduce tu dolor al realizar una respiración profunda?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/TORAX2.jpg'},
          {text:'¿Presentas una inflamación o hinchazón?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/DORSAL7.jpg'},
          {text:'¿Has presentado sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/DORSAL8.jpg'},
], 
        trigger:5,
        template:7,
      },

    {
      id:5,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:6,
    },
    {
      id:6,
      respuestas:[],
      template:8,
    },
    //MAL GESTO
    {
        id:7,
        preg:'¿Me podrías indicar en qué zona del tórax son más intensos los síntomas?',
        respuestas:[{id:1,text:'Superior',masInfo:'A la altura de la clavícula y pectoral',trigger:8,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/TORAX1.jpg'},
                    {id:2,text:'Inferior',masInfo:'A la altura del pectoral y costillas',trigger:8,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/TORAX1.2.jpg'}],
        template:4,
      },
      {
        id:8,
        preg: [
          {text:'¿Llevas más de 3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Se reproduce tu dolor al realizar una respiración profunda?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/TORAX2.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Presentas una inflamación o hinchazón?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/DORSAL7.jpg'},
          {text:'¿Sensación de pinchazo en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/DORSAL8.jpg'},
          {text:'¿Se reproduce tu dolor al presionar con la mano?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/DORSAL7.jpg'},
], 
        trigger:9,
        template:7,
    },

    {
      id:9,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:10,
    },
    {
      id:10,
      respuestas:[],
      template:8,
    },
    //TRAUMA NO
    {
        id:11,
        preg:'¿Me podrías indicar en qué zona del tórax son más intensos los síntomas?',
        respuestas:[{id:1,text:'Superior',masInfo:'A la altura de la clavícula y pectoral',trigger:12,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/TORAX1.jpg'},
                    {id:2,text:'Inferior',masInfo:'A la altura del pectoral y costillas',trigger:12,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/TORAX1.2.jpg'}],
        template:4,
      },
      {
        id:12,
        preg: [
          {text:'¿Llevas más de 3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Se reproduce tu dolor al realizar una respiración profunda?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/TORAX2.jpg'},
          {text:'¿Realizas ejercicio físico que impliquen movimientos de brazo?',
              masInfo:{
                state:true,
                titulo:'Ver ejemplos',
                text:'Deportes como la natación, escalada, ejercicios de gimnasio, balomano, etc'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL3.jpg'
          },
          {text:'¿Se reproduce tu dolor al flexionar el tronco?',
              masInfo:{
                state:true,
                titulo:'Ver ejemplos',
                text:'Por ejemplo levantarte de la cama'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/DORSAL8.jpg'
          },
          {text:'Por último, ¿se reproduce tu dolor al presionar con la mano?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TORAX/DORSAL7.jpg'},
], 
        trigger:13,
        template:7,
      },

    {
      id:13,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:14,
    },
    {
      id:14,
      respuestas:[],
      template:8,
    }]

export default torax