const mano = [
  {
  id:0,
  preg:'',
  nombre:'Mano',
  respuestas:[{id:1,text:'Parte Palmar',trigger:1, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/manoPalmar.jpg',masInfo:'Dolor en la palma de la mano'},
              {id:2,text:'Parte Dorsal',trigger:2, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/manoDorsal.jpg',masInfo:'Dolor en el dorso de la mano'},
              {id:3,text:'Pulgar',trigger:3, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/pulgar.jpg',masInfo:'Dolor del primer dedo de la mano'},
              {id:4,text:'1º,2º y 3º Dedos',trigger:4, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/123Dedos.jpg',masInfo:'Dolor de pulgar, índice y corazón'},
              {id:4,text:'4º y 5º Dedos',trigger:5, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/4.jpg',masInfo:'Dolor de anular y meñique'}],
  template:2,
}]

export default mano