import React,{Component} from 'react';
import '../css/preguntaConRespuestas.scss' 
import '../css/main.scss';
import logo from './../img/logoAzulOscuro.png';
import Fade from 'react-reveal/Fade';
import scroll from './../img/scrollDown.gif'
import arrow from '../img/arrow-izq.png'


class Asociacion extends Component{
    constructor(props){
        super(props)
        this.state={
            preguntaCss:'asociacionBlock',}
    }
    componentDidMount(){
 
        let timer = setTimeout(this.setState.bind(this, {preguntaCss:'asociacionBlock-active'}), 3000);
  
      }
    
    componentWillReceiveProps(){
        console.log('update')
        this.setState({preguntaCss:'asociacionBlock'})
        let timer = setTimeout(this.setState.bind(this, {preguntaCss:'asociacionBlock-active'}), 3000);
    }
    componentWillUnmount(){
        clearTimeout(this.timer);
  
      }
    render(){
        console.log(this.props)
        const respuestas = this.props.preguntas.respuestas.map((resp) =>
            <div className='zona-articulacion'>
                <div className="container-titulo-asociacion"  key={resp.id} onClick={()=> this.props.creararray(resp.asociacion,this.props.state.articulacion,this.props.state.zonaDolor,resp.trigger)}>
                    <p className='titulo-parte'>{resp.text}</p>
                    <p className='texto-parte'>{resp.masInfo}</p>
                    <div className='circulo-oscuro'></div>
                </div>
            </div>)
   
        return(
            <div className='primaryContainer'>
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div> 
                    <img src={scroll} className='scroll-icon' />
                <div className={this.state.preguntaCss}>
                    <div className='asociacion-pregunta-container fade-in-right-short'>
                        <Fade right duration={1000} delay={100} spy={this.props} appear distance={'17px'}>
                            <div className='line-preguntas'>                         
                                <p className='text-aurya-small'>{this.props.preguntas.preg}</p>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className='imageBlock-asociacion fade-in-left desktop'>
                    <Fade bottom duration={1000} distance={'25px'} delay={1300} spy={this.props} appear>                    
                        <div className='respuestas-container'>
                            {respuestas}
                        </div>
                    </Fade>
                </div>
                <div className='imageBlock-asociacion fade-in-left mobile'>                    
                        <div className='respuestas-container'>
                            {respuestas}
                        </div>
                </div>
                
                <div className='atras-container' onClick={()=>this.props.handleclick(0,0,3)}>
                    <img src={arrow} width='20px' />
                    <p>Atras</p>
                </div>
            </div>
        )
    }
}

export default Asociacion