const pantorrilla = [
  {
  id:0,
  preg:'',
  nombre:'pantorrilla',
  respuestas:[{id:1,text:'Parte Anterior',trigger:1, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/pantorrillaAnterior.jpg',masInfo:'Dolor en la parte de delante de la pantorrilla'},
              {id:2,text:'Parte Posterior',trigger:2, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/pantorrillaPosterior.jpg',masInfo:'Dolor en la parte de atrás de la pantorrilla'}],
  template:2,
}]

export default pantorrilla