import React, {Text} from 'react';
import './../../css/main.scss';

const caderaIrradiado = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída o mal gesto de la cadera'},
                  {id:2,text:'Un sobresfuerzo',trigger:8,asociacion:1,masInfo:'Realizar un esfuerzo excesivo o más de lo habitual'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:8,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:8,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
    
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Cuál ha sido el grado de inflamación?',
        type:'inflamacion',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Cuál ha sido la intensidad del dolor al mover la cadera en el momento de la lesión?',
        type:'trauma',
        template:3,
        trigger:4,
    },
    {
        id:4,
        preg:'¿Se reproduce tu dolor al presionar en alguno de estos puntos?',
        respuestas:[{id:1,text:'Trocánter mayor',masInfo:'Hueso prominente en el lateral de la cadera',trigger:5,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO1.jpg'},
                    {id:2,text:'Glúteo',masInfo:'Dolor al presionar sobre el glúteo',trigger:5,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO1.2.jpg'},
                    {id:3,text:'Es un dolor difuso',masInfo:'El dolor no es localizado y es más general',trigger:5,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO1.3.jpg'},
                    {id:3,text:'No me duele al tocar',masInfo:'No se reproduce el dolor al presionar los puntos anteriores',trigger:5,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'}
                ],
        template:4,
    },  
        
      {
        id:5,
        preg:  [{text:'	¿Llevas más de 2-3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
                {text:'¿Has presentado un hematoma?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},   
                {text:'¿Presentas adormecimiento o sensación de hormigueo en la pierna?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERALATERAL2.jpg'}, 
                {text:'Por último, ¿notas una sensación de dolor eléctrico?',
                  masInfo:{
                    state:true,
                    titulo:'¿Qué significa eléctrico',
                    text:'Presentar sensación de dolor punzante (como si tuvieses agujas)'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO3.jpg'
                },
              ],
        trigger:6,
        template:7,
      },

    {
      id:6,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:7,
    },
    {
      id:7,
      respuestas:[],
      template:8,
    },
//TRAUMA NO

    {
        id:8,
        preg:'¿Me podrías indicar por dónde se irradia el dolor?',
        respuestas:[{id:1,text:'Parte anterior',masInfo:'Se irradia por la parte anterior del muslo',trigger:9,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO8.jpg'},
                    {id:2,text:'Parte interna',masInfo:'Se irradia por la parte interna del muslo',trigger:9,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO7.jpg'},
                    {id:3,text:'Parte lateral',masInfo:'Se irradia por la parte lateral del muslo',trigger:9,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO9.jpg'},
                    {id:4,text:'Parte posterior',masInfo:'Se irradia por la parte posterior del muslo',trigger:9,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO5.jpg'}
                ],
        template:4,
    },  
      {
        id:9,
        preg: [ {text:'¿Se reproduce tu dolor al presionar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO.jpg'},
                {text:'¿Llevas más de tres meses con los síntomas?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},   
                {text:'¿Presentas sensación de debilidad en la pierna afectada?',
                    masInfo:{
                      state:true,
                      titulo:'Más información',
                      text:'Sensación de pérdida de fuerza en la pierna'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO4.jpg'
                },
                {text:'¿Y una sensación de hormigueo o entumecimiento en la pierna afectada?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO5.jpg'},   
                {text:'¿Tienes una sensación de dolor eléctrico?',
                  masInfo:{
                    state:true,
                    titulo:'¿Qué significa eléctrico?',
                    text:'Presentar sensación de dolor punzante (como si tuvieses agujas)'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO3.jpg'
                },
                {text:'Por último, ¿notas una sensación de quemazón en la pierna afectada?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO6.jpg'},   

      ],
        trigger:10,
        template:7,
      },

    {
      id:10,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:11,
    },
    {
      id:11,
      respuestas:[],
      template:8,
    }
]

export default caderaIrradiado