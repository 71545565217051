import React, {Text} from 'react';
import './../../css/main.scss';

const tobilloInterna = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída con mal apoyo o mal gesto del tobillo'},
                  {id:2,text:'Ha empezado con el tiempo',trigger:10,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:3,text:'No lo sé',trigger:10,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
        {
          id:1,
          preg:'¿Cómo ha sido el traumatismo que asocias al dolor?',
          respuestas:[{id:1,text:'Golpe directo',masInfo:'Un golpe o choque sobre el tobillo',trigger:2,asociacion:0},
                      {id:2,text:'Mal apoyo / Movimiento forzado',masInfo:'Un mal gesto al apoyar',trigger:3,asociacion:1},
                      {id:3,text:'No lo sé',masInfo:'No estoy seguro o el traumatismo ha sido diferente',trigger:4,asociacion:2},],
          template:10,
    },   

    {
        id:2,
        preg:'¿Cuál ha sido la intensidad del golpe?',
        template:3,
        trigger:5,
    },
    {
        id:3,
        preg:'Perfecto, ¿cuál ha sido la intensidad del dolor en el momento del mal apoyo?',
        template:3,
        trigger:5,
    },
    {
      id:4,
      preg:'Perfecto, ¿cuál ha sido la intensidad del traumatismo?',
      template:3,
      trigger:5,
  },

    {
        id:5,
        preg:'¿Has presentado una inflamación? ',
        respuestas:[{id:1,text:'Si, una pequeña inflamación',masInfo:'El tobillo se ha hinchado un poquito',trigger:6,asociacion:0},
                    {id:2,text:'Sí, está bastante hinchado',masInfo:'El tobillo se ha hinchado notablemente',trigger:6,asociacion:1},
                    {id:3,text:'Sí, la inflamación es grande',masInfo:'El tobillo ha experimentado una gran inflamación',trigger:6,asociacion:2},
                    {id:4,text:'No',masInfo:'El tobillo no ha experimentado ninguna inflamación',trigger:6,asociacion:3}],
        template:10,
    },
    {
        id:6,
        preg:'¿Dónde se localiza exactamente tu dolor?',
        respuestas:[{id:1,text:'Justo detrás del maleolo',masInfo:' En la parte posterior al hueso prominente',trigger:7,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO17POSTERIOR.jpg'},
                    {id:2,text:'Irradiado hacia la planta del pie',masInfo:'Se irradia desde el tobillo a los dedos',trigger:7,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO17IRRADIADO.jpg'},
                    {id:2,text:'Difuso',masInfo:'Es un dolor más general, difícil de localizar en un punto concreto',trigger:7,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO17DIFUSO.jpg'}],
        template:4,
    },
      {
        id:7,
        preg:[
          {text:'¿Llevas más de tres meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Y una sensación de crack o chasquido en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO1.jpg'},
          {text:'¿Has sentido incapacidad para mover el tobillo en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO2.jpg'},
          {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Imposibilidad de apoyar tu peso sobre la pierna afectada'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO3.jpg'
          },
  ],
        trigger:8,
        template:7,
      },

    {
      id:8,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:9,
    },
    {
      id:9,
      respuestas:[],
      template:8,
    },
    
    //TRAUMA NO
    {
        id:10,
        preg:'¿Dónde localizas tu dolor?',
        respuestas:[{id:1,text:'Posterior al maleolo',masInfo:'Justo por detrás del hueso prominente',trigger:11,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO17POSTERIOR.jpg'},
                    {id:3,text:'Irradiado hacia el talón',masInfo:'Se irradia desde el tobillo al talón',trigger:11,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO17IRRADIADO.jpg'},
                    {id:4,text:'Difuso',masInfo:'Es difícil de localizar en un punto concreto',trigger:11,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO17DIFUSO.jpg'}
                  ],
        template:4,
      },
      {
        id:11,
        preg:'¿Cuándo es el dolor más intenso?',
        respuestas:[{id:1,text:'Por la noche',masInfo:'En las últimas horas del día',trigger:12,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NOCHE.jpg'},
                    {id:3,text:'Por la mañana',masInfo:'Al levantarte de la cama',trigger:12,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DIA.jpg'},
                    {id:4,text:'Indiferente',masInfo:'El dolor es muy similar',trigger:12,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NOLOSEREPETIDO.jpg'}
                  ],
        template:4,
      },
      {
        id:12,
        preg: [
          {text:'¿Realizas actividad física regularmente (3-4 días a la semana)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
          {text:'¿Has incrementado la carga de entrenamiento o aumentado las distancias al andar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/SPRINTREPETIDODEFINITVO.jpg'},
          {text:'¿Has presentado una sensación de hormigueos en la planta del pie?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO15.jpg'},
          {text:'¿El dolor persiste en reposo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TUMBADOREPETIDO.jpg'},
          {text:'¿Presentas sensación de quemazón?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Un dolor agudo con sensación de ardor'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO19.jpg'
          },
          {text:'Por último, ¿el dolor se disminuye durante la actividad?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'¿Al comenzar a caminar o a hacer deporte al principio sientes dolor pero al calentar se disminuye?'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/MEDIAL/TOBILLO18.jpg'
          },
  ],
        trigger:13,
        template:7,
    },

    {
      id:13,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:14,
    },
    {
      id:14,
      respuestas:[],
      template:8,
    }]

export default tobilloInterna

