import React, {Text} from 'react';
import './../../css/main.scss';

const dedos = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
    respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída o mal gesto de los dedos'},
                {id:2,text:'Ha empezado con el tiempo',trigger:7,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                {id:3,text:'No lo sé',trigger:7,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },
    //TRAUMATISMO SI
    {
      id:1,
      preg:'¿Me podrías indicar el grado del traumatismo recibido?',
      type:'trauma',
      template:3,
      trigger:2,
  },
  {
      id:2,
      preg:'¿Y la inflamación que has presentado en el 1º dedo?',
      type:'inflamacion',
      template:3,
      trigger:3,
  },
  {
      id:3,
      preg:'¿Cuál es el grado de dolor al mover el 1º dedo en el momento de la lesión (flexión-extensión)?',
      type:'trauma',
      template:3,
      trigger:4,
  },
    {
      id:4,
      preg:[ 
        {text:'¿Llevas más de 3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
        {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
        {text:'¿Has presentado incapacidad para apoyar el peso de tu cuerpo en el suelo?',
            masInfo:{
                state:true,
                titulo:'Más información',
                text:'Resulta imposible apoyar tu peso en el pie afectado debido al dolor'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/1%C2%BA+DEDO/PANTORRILLAANTERIOR2.jpg'
        },
        {text:'Por último, ¿presentas hormigueos en los dedos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/DEDO6.jpg'},
],
      trigger:5,
      template:7,
    },

  {
    id:5,
    preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
    respuestas:[],
    template:9,
    trigger:6,
  },
  {
    id:6,
    respuestas:[],
    template:8,
    trigger:5,
  }, 
    //TRAUMA NO
  {
      id:7,
      preg:'¿En qué parte son más intensos los síntomas?',
      respuestas:[{id:1,text:'Parte inferior',masInfo:'La parte inferior de los dedos',trigger:8,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/DEDO7INFERIOR.jpg'},
                  {id:2,text:'Parte dorsal',masInfo:'La parte superior de los dedos',trigger:8,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/DEDO7DORSAL.jpg'},
                  {id:3,text:'Irradiado a los dedos',masInfo:'El dolor se irradia desde el pie a los dedos',trigger:8,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/DEDO7IRRADIADO.jpg'},
                  {id:4,text:'Difuso',masInfo:'El dolor es general y difícil de localizar en un punto',trigger:8,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/DEDO7DIFUSO.jpg'}],
      template:4,
    },
    {
      id:8,
      preg:'¿El dolor en las primeras horas de la mañana?',
      respuestas:[{id:1,text:'Empeora',masInfo:'El dolor es más intenso',trigger:9,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'},
                  {id:2,text:'Mejora',masInfo:'El dolor es más suave',trigger:9,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                  {id:3,text:'Indiferente',masInfo:'No influye en el dolor',trigger:9,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NOLOSEREPETIDO.jpg'}],
      template:4,
    },
    {
      id:9,
      preg:'¿Cómo evoluciona tu dolor con la actividad?',
      respuestas:[{id:1,text:'Mejora una vez que se calienta la zona',masInfo:'El dolor se reduce al caminar un rato o calentar la zona',trigger:10,asociacion:0},
                  {id:2,text:'Empeora con la actividad',masInfo:'El dolor empeora al practicar alguna actividad durante un tiempo',trigger:10,asociacion:1},
                  {id:3,text:'Indiferente',masInfo:'No influye en el dolor',trigger:10,asociacion:2}],
      template:10,
    },
    {
      id:10,
      preg:'¿Cuál es la intensidad del dolor al ponerse de puntillas?',
      respuestas:[{id:1,text:'Soportable',masInfo:'Puedo aguantar el dolor',trigger:11,asociacion:0},
                  {id:2,text:'Insoportable',masInfo:'El dolor es inaguantable',trigger:11,asociacion:1}],
      template:10,
    },
    {
      id:11,
      preg: [ 
        {text:'¿Realizas actividad física de media/alta intensidad (3-4 veces por semana)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
        {text:'¿Presentas sensación de hormigueo en los dedos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/DEDO6.jpg'},
        {text:'¿Y ardor o quemazón?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/DEDO3.jpg'},
        {text:'¿El dolor empeora al calzar zapatos ajustados?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/DEDO4.jpg'},
        {text:'Por último, ¿el dolor aumenta al hacer una flexión plantar máxima de los dedos de forma pasiva?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/DEDOS/FLEXDEDOSPASIVO.mp4'},
],
      trigger:12,
      template:7,
    },

    {
      id:12,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:13,
    },
    {
      id:13,
      respuestas:[],
      template:8,
    }]

export default dedos