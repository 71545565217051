import React, {Text} from 'react';
import './../../css/main.scss';

const musloLateral = [
    {
        id:0,
        preg:'Indícame en cuál de las siguientes zonas se refleja mejor tu dolor',
        respuestas:[{id:1,text:'Trocanter mayor',masInfo:'Dolor en el hueso prominente del lateral de la cadera',trigger:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/LATERAL/MUSLOLATERAL1TROCANTER.jpg'},
                    {id:2,text:'Se irradia hacia la rodilla',masInfo:'El dolor se irradia desde el muslo a la rodilla',trigger:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/LATERAL/MUSLOALTERAL1LATERAL.jpg'},
                    {id:3,text:'Se irradia hacia la parte anterior del muslo',masInfo:'El dolor se irradia desde la parte lateral del muslo hacia anterior',trigger:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/LATERAL/MUSLOLATERAL1ANTERIOR.jpg'}
                  ],
        template:4,
      },
    {
        id:1,
        preg:'¿Me podrías señalar la intensidad del dolor en el momento de la lesión?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    { 
        id:2,
        preg: [ 
          {text:'¿Llevas más de un mes con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Asocias el inicio del dolor a un gesto específico o movimiento explosivo?',
              masInfo:{
              state:true,
              titulo:'Ver ejemplos',
              text:'Un mal gesto al realizar un sprint, cambio de dirección, salto, etc.'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/ANTERIOR/SPRINTREPETIDO.jpg'
          },
          {text:'¿Has notado un pinchazo en el momento de la lesión?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Un pinchazo es un dolor agudo, como si te hubieran lanzado una piedra'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/MUSLO+ANTERIOR/CADERALATERAL2.jpg'
          },
          {text:'¿Has aumentado la carga de entrenamiento, las distancias al andar o practicado nuevas actividades justo los días antes de lesionarte?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Aumentar la actividad física o de trabajo los días-semanas antes de lesión'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/ANTERIOR/CADERADEPORTE.jpg'
          },
          {text:'Por último, ¿presentas dolor al tumbarte de lado sobre el lateral del muslo afectado?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/MUSLO+LATERAL/TUMBADOREPETIDO.jpg'},

], 
        trigger:3,
        template:7,
      },

    {
      id:3,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:4,
    },
    {
      id:4,
      respuestas:[],
      template:8,
    },
]

export default musloLateral