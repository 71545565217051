import React, {Text} from 'react';
import './../../css/main.scss';

const cervicalIrradiado = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe o mal gesto de la cabeza/cuello'},
                  {id:2,text:'Época de estrés y/o de ansiedad',trigger:6,asociacion:1,masInfo:'El inicio del dolor está asociado a una época de estrés y/o de ansiedad ' },
                  {id:3,text:'Ha empezado con el tiempo',trigger:6,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:6,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Y cuál ha sido la intensidad del dolor?',
        type:'trauma',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg: [ {text:'¿Has sufrido un accidente automovilístico relacionado con el inicio del dolor?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA2REP.jpg'},   
                {text:'¿Llevas más de un mes con los síntomas?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},   
                {text:'¿Has presentado una sensación de mareo?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'La sensación de mareo se refiere a la sensación de que vas a perder el equilibrio'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA5REP.jpg'
                },
                {text:'¿Y rigidez al mover el cuello?',
                    masInfo:{
                      state:true,
                      titulo:'Más información',
                      text:' La rigidez de cuello es la sensación de falta de movilidad al rotar o inclinar el cuello'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZACERVICAL8.jpg'
                },
                {text:'Perfecto, ¿has presentado una sensación de pesadez o sobrecarga en la parte posterior de la cabeza?',
                    masInfo:{
                      state:true,
                      titulo:'Más información',
                      text:' La sensación de pesadez del cuello se refiere a la sensación de rigidez al tocar los músculos de la nuca'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA7REP.jpg'
                },
                {text:'¿Has presentado hormigueos o parestesias hacia la mano?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL/CERVICAL1.jpg'},
                {text:'¿Por último, ¿has presentado cambios de color o palidez en la mano?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'La palidez se refiere a la pérdida del color de la piel normal'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL+IRRADIADO/BRAZOANTERIOR6.jpg'
                },
],
        trigger:4,
        template:7,
    },
    {
      id:4,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:5,
    },
    {
      id:5,
      respuestas:[],
      template:8,
    },
    
    //TRAUMA NO
    {
        id:6,
        preg:'¿Me podrías indicar la intensidad del dolor?',
        type:'trauma',
        template:3,
        trigger:7,
    },
      {
        id:7,
        preg: [ 
        {text:'¿Los síntomas empeoran al mantener el brazo elevado?',
          masInfo:{
            state:true,
            titulo:'Ver ejemplos',
            text:' Por ejemplo, en actividades como: ducharse, peinarse, elevar o coger objetos, etc.'
          },
          imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL+IRRADIADO/CERVICALIRRADIADO1.jpg'
        },
        {text:'¿Y al permanecer en posturas mantenidas?',
            masInfo:{
              state:true,
              titulo:'Ver ejemplos',
              text:'Por ejemplo, al estar sentado delante del ordenador'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL/CERVICAL3.jpg'
        },
        {text:'¿Llevas más de un mes con los síntomas?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},  
        {text:'¿Has presentado hormigueos o parestesias hacia la mano?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL/CERVICAL1.jpg'},   
        {text:'¿Has presentado cambios de color o palidez en la mano?',
            masInfo:{
              state:true,
              titulo:'Más información',
              text:'La palidez se refiere a la pérdida del color de la piel normal'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CERVICAL+IRRADIADO/BRAZOANTERIOR6.jpg'
        },
        {text:'¿Tienes una sensación de rigidez al mover el cuello?',
            masInfo:{
              state:true,
              titulo:'Más información',
              text:'La rigidez de cuello es la sensación de falta de movilidad al rotar o inclinar el cuello'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZACERVICAL8.jpg'
        },
        {text:'Por último, ¿has presentado una sensación de pesadez o sobrecarga en la parte posterior de la cabeza?',
            masInfo:{
              state:true,
              titulo:'Más información',
              text:'La sensación de pesadez del cuello se refiere a la sensación de rigidez al tocar los músculos de la nuca'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA7REP.jpg'
        },
],
        trigger:8,
        template:7,
      },

    {
      id:8,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:9,
    },
    {
      id:9,
      respuestas:[],
      template:8,
    }]

export default cervicalIrradiado