import React, {Text} from 'react';
import './../../css/main.scss';

const brazoPosterior = [
    {
      id:0,
      preg:'¿Asocias el dolor a un gesto específico con inicio repentino o ha ido aumentando progresivamente?',
      respuestas:[{id:1,text:'Inicio repentino',masInfo:'El dolor ha empezado al realizar un movimiento o gesto',trigger:1,asociacion:0},
                  {id:2,text:'Inicio progresivo',masInfo:'El inicio del dolor no se asocia a ningún gesto en específico',trigger:1,asociacion:1}],
      template:10,
    },  
    { 
        id:1,
        preg: [{
          text:'¿Has sentido un pop notable o audible en el momento de la lesión?',
          masInfo:{
            state:true,
            titulo:'¿Que significa pop?',
            text:'Una sensación de pop se le llama a un chasquido o crujido en el momento de la lesión'
          },
          imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/BRAZO/BRAZOPOSTERIOR1.jpg'
        },
        {text:'¿Llevas más de un mes con tu dolor?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},   
        {text:'¿Has presentado un hematoma?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
        {text:'Perfecto, ¿el dolor es localizado y se sitúa justo por encima del codo?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/BRAZO/BRAZOPOSTERIOR4.jpg'},
        {text:'¿Y se irradia desde el hombro hacia la mano?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/BRAZO/BRAZOPOSTERIOR5.jpg'},
        {text:'¿Presentas sensación de hormigueo o entumecimiento en el brazo?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/BRAZO/BRAZOPOSTERIOR6.jpg'}, 
        {text:'Por último, ¿presentas cambios de color o palidez en la mano?',
          masInfo:{
            state:true,
            titulo:'Más información',
            text:'El cambio de color o palidez se refiere a la pérdida del color de la piel normal'
          },
          imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/BRAZO/BRAZOANTERIOR6.jpg'
        },
      ],
        trigger:2,
        template:7,
      },

    {
      id:2,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:3,
    },
    {
      id:3,
      respuestas:[],
      template:8,
    },
]

export default brazoPosterior