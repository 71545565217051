import React, {Text} from 'react';
import './../../css/main.scss';

const tobilloExterna = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída con mal apoyo o mal gesto del tobillo'},
                  {id:2,text:'Ha empezado con el tiempo',trigger:10,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:3,text:'No lo sé',trigger:10,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
        {
          id:1,
          preg:'¿Cómo ha sido el traumatismo que asocias al dolor?',
          respuestas:[{id:1,text:'Golpe directo',masInfo:'Un golpe o choque sobre el tobillo',trigger:2,asociacion:0},
                      {id:2,text:'Mal apoyo / Movimiento forzado',masInfo:'Un mal gesto al apoyar',trigger:3,asociacion:1},
                      {id:3,text:'No lo sé',masInfo:'No estoy seguro o el traumatismo ha sido diferente',trigger:4,asociacion:2},],
          template:10,
    },   

    {
        id:2,
        preg:'¿Indícame el grado de intensidad del golpe?',
        type:'trauma',
        template:3,
        trigger:5,
    },
    {
        id:3,
        preg:'Perfecto, ¿cuál ha sido la intensidad del dolor en el momento la lesión?',
        type:'trauma',
        template:3,
        trigger:5,
    },
    {
      id:4,
      preg:'Perfecto, y ¿cuál ha sido la intensidad del traumatismo?',
      type:'trauma',
      template:3,
      trigger:5,
    },
    {
        id:5,
        preg:'¿Has presentado una inflamación? ',
        respuestas:[{id:1,text:'Si, una pequeña inflamación',masInfo:'El tobillo se ha hinchado un poquito',trigger:6,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                    {id:2,text:'Sí, está bastante hinchado',masInfo:'El tobillo se ha hinchado notablemente',trigger:6,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/moderado.jpg'},
                    {id:3,text:'Sí, la inflamación es grande',masInfo:'El tobillo ha experimentado una gran inflamación',trigger:6,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'},
                    {id:4,text:'No',masInfo:'El tobillo no ha experimentado ninguna inflamación',trigger:6,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'}],
        template:4,
    },
    {
        id:6,
        preg:'¿Dónde se localiza exactamente tu dolor?',
        respuestas:[{id:1,text:'Justo anterior al maleolo',masInfo:'En la parte delantera al hueso prominente',trigger:7,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO13ANTERIOR.jpg'},
                    {id:2,text:'Posterior al maléolo',masInfo:'En la parte trasera al hueso prominente',trigger:7,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO13POSTERIOR.jpg'},
                    {id:3,text:'Difuso',masInfo:'Es un dolor más general, difícil de localizar en un punto concreto',trigger:7,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO13DIFUSO.jpg'}],
        template:4,
    },
      {
        id:7,
        preg:[
          {text:'¿Llevas más de tres meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Y una sensación de crack o chasquido en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO1.jpg'},
          {text:'¿Presentas sensación de inestabilidad de tobillo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO14.jpg'},
          {text:'¿Has sentido incapacidad para mover el tobillo en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO2.jpg'},
          {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Imposibilidad de apoyar tu peso sobre la pierna afectada'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO3.jpg'
          },
  ],
        trigger:8,
        template:7,
    },
    {
      id:8,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:9,
    },
    {
      id:9,
      respuestas:[],
      template:8,
    },
    
    //TRAUMA NO
    {
        id:10,
        preg:'¿En que zona son más intensos los síntomas?',
        respuestas:[{id:1,text:'Justo anterior al maleolo',masInfo:'En la parte delantera al hueso prominente',trigger:11,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO13ANTERIOR.jpg'},
                    {id:2,text:'Posterior al maléolo',masInfo:'En la parte trasera al hueso prominente',trigger:11,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO13POSTERIOR.jpg'},
                    {id:3,text:'Difuso',masInfo:'Es un dolor más general, difícil de localizar en un punto concreto',trigger:11,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO13DIFUSO.jpg'}],
        template:4,
      },
      {
        id:11,
        preg:'¿Cuánto tiempo llevas con el dolor?',
        respuestas:[{id:1,text:'Es reciente',masInfo:'Menos de tres meses',trigger:12,asociacion:0},
                    {id:2,text:'Ya llevo mucho tiempo',masInfo:'Más de tres meses',trigger:12,asociacion:1},],
        template:10,
    },

      {
        id:12,
        preg:[
          {text:'¿Has tenido lesiones previas de tobillo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO2.jpg'},
          {text:'¿Has incrementado la carga de entrenamiento o aumentado las distancias al andar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
          {text:'¿Presentas sensación de inestabilidad de tobillo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO14.jpg'},
          {text:'¿Al mover el tobillo en diferentes direcciones sientes crepitaciones?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Las crepitaciones son unos crujidos o clicks al mover el tobillo'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO15.jpg'
          },
          {text:'¿El dolor es localizado en un punto concreto y se aumenta al presionar sobre él?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/LATERAL/TOBILLO16.jpg'},
          {text:'¿Se reproduce tu dolor al realizar una flexión plantar?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONPLANTAR.mp4'},
          {text:'¿Y al realizar una flexión dorsal?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONDORSAL.mp4'},
    ],
        trigger:13,
        template:7,
    },
    {
      id:13,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:14,
    },
    {
      id:14,
      respuestas:[],
      template:8,
    }]

export default tobilloExterna