import React, {Text} from 'react';
import './../../css/main.scss'; 

const musloMedial = [
    {
        id:0,
        preg:'¿Me podrías señalar la intensidad del dolor en el momento de la lesión?',
        type:'trauma',
        template:3,
        trigger:1,
    },
    {
      id:1,
      preg:'¿Donde dirías que se localiza mejor tu dolor?',
      respuestas:[{id:1,text:'En la ingle',masInfo:'En la parte superior, a la altura de la cadera y pubis',trigger:2,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/INTERNA/MUSLOMEDIAL1INGLE.jpg'},
                  {id:2,text:'En el muslo',masInfo:'En la parte inferior, a la altura del muslo',trigger:2,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/INTERNA/MUSLOMEDIAL1MUSLO.jpg'}],
      template:4,
  },
    { 
        id:2,
        preg: [
          {text:'¿Realizas alguna actividad física de media-alta intensidad? (3-4 veces a la semana)',
              masInfo:{
                state:true,
                titulo:'Ver ejemplos',
                text:'Deportes como el fútbol o el baloncesto que impliquen sprints, cambios de dirección, saltos, etc.'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/INTERNA/MUSLOMEDIAL2.jpg'
          },
          {text:'¿Llevas más de un mes con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Asocias el inicio del dolor a un gesto específico o movimiento explosivo?',
              masInfo:{
                state:true,
                titulo:'Ver ejemplos',
                text:'Un mal gesto al realizar un sprint, cambio de dirección, salto, etc.'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/ANTERIOR/SPRINTREPETIDO.jpg'
          },
          {text:'¿Has notado un pinchazo en el momento de la lesión?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Un pinchazo es un dolor agudo, como si te hubieran lanzado una piedra'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/INTERNA/MUSLOMEDIAL3.jpg'
          },
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Has aumentado la carga de entrenamiento, las distancias al andar o practicado nuevas actividades justo los días antes de lesionarte?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Aumentar la actividad física o de trabajo los días-semanas antes de lesión'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/ANTERIOR/CADERADEPORTE.jpg'
          },
          {text:'¿Se reproduce tu dolor al realizar una flexión de rodilla contra resistencia?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Realiza fuerza contra tu mano'
              },
              imagen:'x',
              video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/INTERNA/FLEXCADERA.mp4'
          },
          {text:'Por último, prueba si te duele al realizar una aducción contra resistencia',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Realiza fuerza contra tu mano'
              },
              imagen:'x',
              video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/INTERNA/CADERAADD.mp4'
          },
      ], 
        trigger:3,
        template:7,
      },

      {
        id:3,
        preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
        respuestas:[],
        template:9,
        trigger:4,
      },
      {
        id:4,
        respuestas:[],
        template:8,
      },
]

export default musloMedial