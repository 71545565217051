import React, {Text} from 'react';
import './../../css/main.scss';

const pantorrillaPosterior   = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
    respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe o mal gesto'},
                {id:2,text:'Un sobresfuerzo',trigger:6,asociacion:1,masInfo:'Realizar un esfuerzo excesivo o más de lo habitual'},
                {id:3,text:'Ha empezado con el tiempo',trigger:6,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                {id:4,text:'No lo sé',trigger:6,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },
    //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
      id:2,
      preg:'¿Has presentado alguna inflamación o hinchazón?',
      respuestas:[{id:1,text:'No', masInfo:'No he presentado ningún hinchazon',trigger:3,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'},
                  {id:2,text:'Sí, un hinchazón leve',masInfo:'Creo que puede estar un poco hinchado', trigger:3,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                  {id:1,text:'Sí, un hinchazón moderado',masInfo:'Se nota que está hinchado',trigger:3,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/moderado.jpg'},
                  {id:1,text:'Sí, un hinchazón grande',masInfo:'El hinchazón es muy grande ', trigger:3,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'}],
      template:4,
    }, 
    {
        id:3,
        preg: [
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'Antes del dolor, ¿aumentaste tu volumen de actividad física?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
           {text:'¿Has sentido un pinchazo en el momento de la lesión?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'Un pinchazo es un dolor agudo, como si te hubieran lanzado una piedra'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/POSTERIOR/PANTORRILLAPOSTERIOR1.jpg'
            },
           {text:'¿Se reproduce tu dolor al ponerte de puntillas sobre la pierna afectada?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/PUNTILLASREPETIDO.jpg'},
           {text:'¿Has presentado incapacidad para apoyar el peso de tu cuerpo en el momento de la lesión?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'Resulta imposible apoyar tu peso en el pie afectado debido al dolor'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/ANTERIOR/PANTORRILLAANTERIOR2.jpg'
            },
    ], 
        trigger:4,
        template:7,
    },

    {
      id:4,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:5,
    },
    {
      id:5,
      respuestas:[],
      template:8,
      trigger:6,
    },
    
    //TRAUMA NO
    {
        id:6,
        preg:'¿Has presentado hinchazón o inflamación del gemelo?',
        respuestas:[{id:1,text:'No', masInfo:'No he presentado ningún hinchazon',trigger:7,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'},
                    {id:2,text:'Sí, un hinchazón leve',masInfo:'Creo que puede estar un poco hinchado', trigger:7,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                    {id:1,text:'Sí, un hinchazón moderado',masInfo:'Se nota que está hinchado',trigger:7,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/moderado.jpg'},
                    {id:1,text:'Sí, un hinchazón grande',masInfo:'El hinchazón es muy grande ', trigger:7,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'}],
        template:4,
    },
      {
        id:7,
        preg: [
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Has realizado actividad física que implique correr o saltar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/SALTO.jpg'},
          {text:'Perfecto, antes del dolor, ¿aumentaste tu volumen de actividad física?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
          {text:'¿Asocias el inicio del dolor a un gesto específico o movimiento explosivo?',
            masInfo:{
              state:true,
              titulo:'Ver ejemplos',
              text:'Un mal gesto al realizar un sprint, cambio de dirección, salto, etc.'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/SPRINTREPETIDODEFINITVO.jpg'
          },
          {text:'¿Has notado un pinchazo en el momento de la lesión?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Un pinchazo es un dolor agudo, como si te hubieran lanzado una piedra'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/POSTERIOR/PANTORRILLAPOSTERIOR1.jpg'
          },
          {text:'¿Presentas cambio de color de la piel en la pierna afectada?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/POSTERIOR/PANTORRILLAPOSTERIOR4.jpg'},
          {text:'¿Presentas dificultad o aparecen los síntomas al caminar 200m seguidos sin parar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/POSTERIOR/PANTORRILLAPOSTERIOR5.jpg'},
          {text:'Por último, ¿se reproduce tu dolor al ponerse de puntillas sobre la pierna afectada?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/PUNTILLASREPETIDO.jpg'},
    ], 
        trigger:8,
        template:7,
    },

    {
      id:8,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:9,
    },
    {
      id:9,
      respuestas:[],
      template:8,
    }]

export default pantorrillaPosterior