const cadera = [
  {
  id:0,
  preg:'',
  nombre:'cadera',
  respuestas:[{id:1,text:'Parte anterior',trigger:1,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/caderaAnterior.jpg',masInfo:'Dolor en la parte de delante de la cadera'},
              {id:2,text:'Parte posterior',trigger:2,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/caderaPosterior.jpg',masInfo:'Dolor en la parte de atrรกs de la cadera'},
              {id:3,text:'Zona Lateral',trigger:3,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/caderaLateral.jpg',masInfo:'Dolor en la parte de fuera de la cadera'},
              {id:3,text:'Zona Medial',trigger:3,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/caderaInterior.jpg',masInfo:'Dolor en la parte de dentro de la cadera'},
              {id:4,text:'Irradiado',trigger:4,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/caderaIrradiado.jpg',masInfo:'Dolor irradiado desde la cadera a la pierna'}],
  template:2,
}]

export default cadera