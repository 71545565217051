import React, {Text} from 'react';
import './../../css/main.scss';

const rodillaPosterior = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un traumatismo o golpe directo sobre la rodilla'},
                  {id:2,text:'Un mal gesto',trigger:7,asociacion:1,masInfo:'El inicio del dolor se asocia a un mal gesto de la rodilla'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:13,asociacion:2,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:13,asociacion:2,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI

    {
        id:1,
        preg:'¿Me podrías indicar cómo ha sido el traumatismo?',
        respuestas:[{id:1,text:'Golpe directo',masInfo:'Choque o golpe fuerte sobre la rodilla',trigger:2,asociacion:0},
                    {id:2,text:'Una Caída',masInfo:'El inicio del dolor se asocia a una caída',trigger:2,asociacion:1},
                    {id:2,text:'Otro',masInfo:'He recibido otro tipo de traumatismo',trigger:2,asociacion:2}
                ],
        template:10,
    },  
    
    {
        id:2,
        preg:'¿Cuál ha sido su grado de dolor?',
        type:'trauma',
        template:3,
        trigger:3,
    },
        
    {
        id:3,
        preg:'¿Has presentado un hinchazón o inflamación?',
        respuestas:[{id:1,text:'Si, de innmediato grande',masInfo:'Inflamación fuerte en pocos minutos',trigger:4,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'},
                    {id:2,text:'Si, de inmediato pequeño',masInfo:'Inflamación leve en pocos minutos',trigger:4,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                    {id:2,text:'A partir de 12h',masInfo:'la inflamación ha aparecido con el paso del tiempo',trigger:4,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
                    {id:2,text:'No',masInfo:'No he experimentado ninguna inflamación',trigger:4,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'}
                ],
        template:4,
    },  
    {
        id:4,
        preg: [ 
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Y una sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/RODILLAPOSTERIOR1.jpg'},
          {text:'¿Has tenido incapacidad para mover la rodilla en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/RODILLAPOSTERIOR2.jpg'},
          {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?',
              masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'El dolor no te permite apoyar el peso de tu cuerpo en el suelo'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA3.jpg'
          },
          {text:'Por último, ¿presentas inestabilidad de la rodilla?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA4.jpg'},
  ],
        trigger:5,
        template:7,
    },

    {
      id:5,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:6,
    },
    {
      id:6,
      respuestas:[],
      template:8,
    },
 //MAL GESTO SI

    {
        id:7,
        preg:'¿Cómo ha sido el gesto?',
        respuestas:[{id:1,text:'Girar sobre si mismo con el pie apoyado',masInfo:'Un mal gesto al girar con el pie apoyado',trigger:8,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12GIRO.jpg'},
                    {id:2,text:'Desplazamiento inusual de la rodilla',masInfo:'Como si la rodilla se hubiese desplazado',trigger:8,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12RODILLA.jpg'},
                    {id:3,text:'Hiperextensión de rodilla',masInfo:'Un mal gesto con la pierna totalmente extendida',trigger:8,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA9.jpg'},
                    {id:4,text:'Otro',masInfo:'No estoy seguro o ha sido un gesto diferente',trigger:8,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/OTRO.jpg'}
                ],
        template:4,
    },  
    {
        id:8,
        preg:'¿Me podrías indicar la intensidad del dolor en el momento de la lesión?',
        type:'trauma',
        template:3,
        trigger:9,
    },
    {
        id:9,
        preg:'¿Has presentado hinchazón o inflamación?',
        respuestas:[{id:1,text:'Si, de innmediato grande',masInfo:'Una inflamación fuerte en pocos minutos ',trigger:10,asociacion:0,},
                    {id:2,text:'Si, de inmediato pequeño',masInfo:'Una inflamación leve en pocos minutos',trigger:10,asociacion:1},
                    {id:3,text:'A partir de 12h',masInfo:'La inflamación ha aparecido con el tiempo',trigger:10,asociacion:2},
                    {id:4,text:'No',trigger:10,masInfo:'No he tenido ninguna inflamación',asociacion:3}
                ],
        template:10,
    },  
    {
        id:10,
        preg: [ 
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Y una sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/RODILLAPOSTERIOR1.jpg'},
          {text:'¿Has tenido incapacidad para mover la rodilla en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/RODILLAPOSTERIOR2.jpg'},
          {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?',
              masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'El dolor no te permite apoyar el peso de tu cuerpo en el suelo'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA3.jpg'
          },
          {text:'Por último, ¿presentas inestabilidad de la rodilla?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA4.jpg'},
  ],
        trigger:11,
        template:7,
      },

    {
      id:11,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:12,
    },
    {
      id:12,
      respuestas:[],
      template:8,
    },
    
    //TRAUMA NO
    {
        id:13,
        preg:'¿Puedes específicar en que zona de la parte posterior se focaliza tu dolor?',
        respuestas:[{id:1,text:'Parte postero-superior',masInfo:'El dolor se localiza por encima del pliegue de la rodilla',trigger:14,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/RODILLAPOSTERIOR3SUPERIOR.jpg'},
                    {id:2,text:'Parte postero-medial',masInfo:'El dolor se localiza a la altura del pliegue de la rodilla',trigger:14,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/RODILLAPOSTERIOR3MEDIO.jpg'},
                    {id:2,text:'Parte postero-inferior',masInfo:'El dolor se localiza por debajo del pliegue de la rodilla',trigger:14,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/RODILLA3INFERIOR.jpg'},
                    {id:2,text:'Irradiado desde la zona glútea',masInfo:'El dolor se refiere desde la zona glútea a la rodilla',trigger:14,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/RODILLAPOSTERIOR3IRRADIADO.jpg'}],
        template:4,
      },
      {
        id:14,
        preg: [ 
          {text:'¿Realizas ejercicio físico regularmente (3-4 días a la semana)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
          {text:'¿Presentas un bulto palpable en la parte posterior de la rodilla?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Un bulto duro del tamaño de una canica'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/BAKER.jpg'
          },
          {text:'¿Tienes sensación de hormigueos o sensación eléctrica en la pierna?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/POSTERIOR/RODILLAPOSTERIOR2.jpg'},
          {text:'Perfecto, ¿se reproduce tu dolor al ponerte de cuclillas?',
                  masInfo:{
                    state:true,
                    titulo:'¿No puedes ponerte de cuclillas?',
                    text:'Si no puedes ponerte de cuclillas prueba a flexionar al máximo la rodilla'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/CUCLILLAS.jpg'
          },
          {text:'¿Al realizar flexo-extensión de rodilla presentas sensación de bloqueo o chasquidos?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Una sensación de rigidez y clicks al mover la rodilla'
              },
              imagen:'x',
              video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA8.mp4'

          },
          {text:'Para ir terminando, ¿se reproduce tu dolor al realizar una flexión de rodilla contra resistencia?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/FLEXIONRODILLA.mp4'},
          {text:'¿Y al ponerte de puntillas sobre la pierna afectada?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/PUNTILLASREPETIDO.jpg'},

  ],
        trigger:15,
        template:7,
      },

    {
      id:15,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:16,
    },
    {
      id:16,
      respuestas:[],
      template:8,
    }]

export default rodillaPosterior