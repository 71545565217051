import rodillaInterna from './rodilla/rodillaInterna';
import rodillaAnterior from './rodilla/rodillaAnterior';
import rodillaExterna from './rodilla/rodillaExterna';
import rodillaPosterior from './rodilla/rodillaPosterior';
import rodilla from './rodilla/rodilla';
import mandibula from './mandibula/mandibula'
import codo from './codo/codo';
import codoInterna from './codo/codoInterna';
import codoExterna from './codo/codoExterna';
import codoAnterior from './codo/codoAnterior';
import codoPosterior from './codo/codoPosterior';
import muslo from './muslo/muslo';
import musloAnterior from './muslo/musloAnterior';
import musloPosterior from './muslo/musloPosterior';
import musloMedial from './muslo/musloMedial';
import musloLateral from './muslo/musloLateral';
import tobillo from './tobillo/tobillo';
import tobilloExterna from './tobillo/tobilloExterna';
import tobilloAnterior from './tobillo/tobilloAnterior';
import tobilloInterna from './tobillo/tobilloInterna';
import tobilloPosterior from './tobillo/tobilloPosterior';
import hombro from './hombro/hombro';
import hombroAnterior from './hombro/hombroAnterior';
import hombroPosterior from './hombro/hombroPosterior';
import hombroLateral from './hombro/hombroLateral';
import hombroIrradiado from './hombro/hombroIrradiado';
import hombroSuperior from './hombro/hombroSuperior';
import hombroDifuso from './hombro/hombroDifuso';
import muñeca from './muñeca/muñeca';
import muñecaDorsal from './muñeca/muñecaDorsal';
import muñecaPalmar from './muñeca/muñecaPalmar';
import mano from './mano/mano';
import manoDorsal from './mano/manoDorsal';
import manoPalmar from './mano/manoPalmar';
import pulgar from './mano/pulgar';
import dedos123 from './mano/dedos123';
import dedos45 from './mano/dedos45';
import lumbar from './lumbar/lumbar';
import lumbarZonaLumbar from './lumbar/lumbarZonaLumbar';
import lumbarIrradiadoMuslo from './lumbar/lumbarIrradiadoMuslo'
import lumbarIrradiadoPie from './lumbar/lumbarIrradiadoPie';
import pie from './pie/pie';
import dedoGordo from './pie/dedoGordo';
import dedos from './pie/dedos';
import talon from './pie/talon';
import planta from './pie/planta';
import cyc from './cabeza y cervical/cyc';
import cabeza from './cabeza y cervical/cabeza';
import cervical from './cabeza y cervical/cervical';
import cabezaycervical from './cabeza y cervical/cabezaycervical';
import cervicalIrradiado from './cabeza y cervical/cervicalIrradiado';
import dorsal from './dorsal/dorsal';
import dorsalLateral from './dorsal/dorsalLateral';
import dorsalInferior from './dorsal/dorsalInferior';
import dorsalInterescapular from './dorsal/dorsalInterescapular';
import pantorrilla from './pantorrilla/pantorrilla';
import pantorrillaAnterior from './pantorrilla/pantorrillaAnterior';
import pantorrillaPosterior from './pantorrilla/pantorrillaPosterior';
import brazo from './brazo/brazo';
import brazoAnterior from './brazo/brazoAnterior';
import brazoPosterior from './brazo/brazoPosterior';
import cadera from './cadera/cadera';
import caderaAnterior from './cadera/caderaAnterior';
import caderaPosterior from './cadera/caderaPosterior';
import caderaLateral from './cadera/caderaLateral';
import caderaIrradiado from './cadera/caderaIrradiado';
import torax from './torax/torax';


import React, {Text} from 'react';
import './../css/main.scss'    

//[[[0,0,0,0],[0,0]],[[0,0,0],[0,0,0]]

const B = (props) => <Text style={{fontWeight: 'bold'}}>{props.children}</Text>

const data1 = [[[
  {
    id:0,
    template:2,
    trigger:1
  },
    {
    id:1,
    preg:[<p className='text-aurya'>Hola, soy <span style={{fontWeight: 700}}>Aurya</span>, tu fisioterapeuta virtual</p>,<p className='text-aurya'>Voy a <span style={{fontWeight: 700}}>evaluar</span> tus síntomas</p>],
    template:0,
    trigger:2,
  },
    {//Edad y
    id:2,
    template:6,
    trigger:3
  },
  {//Donde te duele?
    id:3,
    template:1,
  }]],
  [
    hombro,hombroAnterior,hombroPosterior,hombroLateral,hombroSuperior,hombroIrradiado,hombroDifuso
  ],
  [
    brazo,brazoAnterior,brazoPosterior
  ],
  [
    codo,codoExterna,codoInterna,codoPosterior,codoAnterior,
  ],
  [
    muñeca,muñecaDorsal,muñecaPalmar
  ],
  [
    mano,manoPalmar,manoDorsal,pulgar,dedos123,dedos45
  ],
  [
    pie,tobilloAnterior,planta,talon,dedoGordo,dedos
  ],
  [
    tobillo,tobilloExterna,tobilloAnterior,tobilloInterna,tobilloPosterior
  ],
  [
    pantorrilla,pantorrillaAnterior,pantorrillaPosterior
  ],
  [
    rodilla,rodillaAnterior,rodillaExterna,rodillaPosterior,rodillaInterna
  ],
  [
    muslo,musloPosterior,musloMedial,musloLateral,musloAnterior
  ],
  [
    cadera,caderaAnterior,caderaPosterior,caderaLateral,caderaIrradiado
  ],
  [
    lumbar,lumbarZonaLumbar,lumbarIrradiadoMuslo,lumbarIrradiadoPie
  ],
  [
    dorsal,dorsalLateral,dorsalInferior,dorsalInterescapular
  ],
  [
    cyc,cervical,cabeza,cabezaycervical,cervicalIrradiado
  ],
  [
    torax
  ],
  [
    mandibula
  ],
]



export default data1