const rodilla = [
  {
    id:0,
    preg:'',
    nombre:'rodilla',
    respuestas:[{id:1,text:'Parte Anterior',trigger:1, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/rodillaAnterior.jpg',masInfo:'Dolor en la parte de delante de la rodilla'},
                {id:2,text:'Parte Externa',trigger:2, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/rodillaExterna.jpg',masInfo:'Dolor en la parte de fuera de la rodilla'},
                {id:3,text:'Parte Posterior',trigger:3, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/rodillaPosterior.jpg',masInfo:'Dolor en la parte de atrás de la rodilla'},
                {id:4,text:'Parte Interna',trigger:4, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/rodillaInterna.jpg',masInfo:'Dolor en la parte de dentro de la rodilla'}],
    template:2,
  }]

export default rodilla