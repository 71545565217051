const codo = [
  {
  id:0,
  preg:'',
  nombre:'codo',
  respuestas:[{id:1,text:'Parte externa',trigger:1, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/codoExterna1.jpg',masInfo:'Dolor en la parte de fuera del codo'},
              {id:2,text:'Parte interna',trigger:2, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/codoInterna1.jpg',masInfo:'Dolor en la parte de dentro del codo'},
              {id:3,text:'Parte posterior',trigger:3, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/codoPosterior1.jpg',masInfo:'Dolor en la parte de atrás del codo'},
              {id:4,text:'Parte anterior',trigger:4, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/CodoAnterior.jpg',masInfo:'Dolor en la parte de delante del codo'}],
  template:2,
}]

export default codo