import React, {Text} from 'react';
import './../../css/main.scss';

const dedos123 = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída con mal apoyo o mal gesto de los dedos'},
                  {id:2,text:'Sobreuso',trigger:7,asociacion:1,masInfo:'Realizar movimientos repetitivos con los dedos durante largos periodos de tiempo'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:7,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:7,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI

    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Cuál es el grado de inflamación?',
        type:'inflamacion',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Y la intensidad del dolor al mover los dedos después de la lesión?',
        type:'trauma',
        template:3,
        trigger:4,
    },
    {
        id:4,
        preg: [ {text:'¿Se reproduce tu dolor al flexionar los dedos?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/FLEXDEDOS.mp4'},
                {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
                {text:'¿Sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/MANO+PALMAR/1%2C2%2C3DEDOS1.jpg'},
                {text:'Perfecto, ¿se reproduce tu dolor al presionar en la base del pulgar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MU%C3%91ECA3.jpg'},
                {text:'Por último, ¿has presentado sensación de hormigueo o parestesias en los 1º,2º y 3º dedos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MU%C3%91ECA/PALMAR/MU%C3%91ECA11.jpg'},
], 
        trigger:5,
        template:7,
    },
    {
        id:5,
        preg:[<p className='text-aurya-small'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
        respuestas:[],
        template:9,
        trigger:6,
    },
    {
        id:6,
        respuestas:[],
        template:8,
    },
    
    //TRAUMA NO
    {
        id:7,
        preg: [ {text:'¿Se reproduce tu dolor al realizar agarres en pinza?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/MANO+PALMAR/MU%C3%91ECA7.jpg'},
                {text:'¿Y al realizar el test de Finkelstein?',
                    masInfo:{
                        state:true,
                        titulo:'Ver test',
                        text:'Realizar un movimiento de muñeca hacia fuera con el puño cerrado y con el pulgar metido entre los dedos'
                    },
                    imagen:'x',
                    video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/Finkelstein.mp4'
                },
                {text:'¿Los síntomas mejoran al sacudir las manos?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/MANO+PALMAR/SACUDIR+MANOS.mp4'},
                {text:'¿Se te queda bloqueado el dedo en flexión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/MANO+PALMAR/4%2C5DEDOS3.jpg'},
                {text:'¿Sientes dificultad para extender los dedos de la mano?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/MANO+DORSAL/ABRIRDEDOS.mp4'},
                {text:'Perfecto, ¿presentas nódulos palpables en la palma de la mano o en los dedos?',
                    masInfo:{
                        state:true,
                        titulo:'Más información',
                        text:'Bulto duro del tamaño de una canica'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/MANO+PALMAR/4%2C5DEDOS2.jpg'
                },
                {text:'¿Y disminución de la sensibilidad en el dorso de la mano?',
                    masInfo:{
                        state:true,
                        titulo:'Más información',
                        text:'Falta de capacidad para sentir presión, toque, calor o frío'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MANODORSAL1.jpg'
                },
                {text:'Por último, ¿has presentado sensación de hormigueo o parestesias en los 1º,2º y 3º dedos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MU%C3%91ECA11.jpg'},

], 
        template:7,
        trigger:8,
    },

    {
      id:8,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:9,
    },
    {
      id:9,
      respuestas:[],
      template:8,
    }]

export default dedos123