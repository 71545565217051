import React, {Text} from 'react';
import './../../css/main.scss';

const lumbarIrradiadoMuslo = [
    {
        id:0,
        preg:'¿Me podrías indicarme en cuál de las siguientes imágenes se refleja mejor tu dolor?',
        respuestas:[{id:1,text:'Irradiado hacia el glúteo',masInfo:'El dolor se irradia hasta el glúteo',trigger:1,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+MUSLO/LUMBARIRRADIADO3.jpg'},
                    {id:2,text:'Irradiado hacia la ingle',masInfo:'El dolor se irradia hacia la ingle',trigger:1,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+MUSLO/LUMBARIRRADIADO3.3.jpg'},
                    {id:3,text:'Irradiado hacia la rodilla',masInfo:'El dolor se irradia hasta la rodilla',trigger:1,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+MUSLO/LUMBARIRRADIADO3.2.jpg'}],
        template:4,
      },  
    { 
        id:1,
        preg: [
                {text:'¿Habías aumentado la actividad de la columna lumbar antes de que empezara el dolor?',
                    masInfo:{
                      state:true,
                      titulo:'Ver ejemplos',
                      text:'Actividades que impliquen agachar, levantar objetos con peso, o simplemente realizar más deporte'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+MUSLO/LUMBAR3.jpg'
                },
                {text:'¿Asocias el principio del dolor a un gesto específico con inicio repentino?',
                    masInfo:{
                      state:true,
                      titulo:'Más información',
                      text:'El dolor ha comenzado en un movimiento específico como al levantarte, al realizar un giro, etc.'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+MUSLO/LUMBAR4.jpg'
                },
                {text:'Perfecto, ¿se reproduce tu dolor al realizar el test de Slump?',
                    masInfo:{
                      state:true,
                      titulo:'Ver test',
                      text:'· Sentarse en la esquina de una mesa o silla · Dejar el cuerpo relajado con una inclinación anterior · Agachar la cabeza llevando la barbilla al pecho · Extender la rodilla de la pierna afectada · Llevar la punta del pie hacia nosotros?'
                    },
                    imagen:'x',
                    video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+MUSLO/TESTSLUMP.mp4'
                },
                {text:'¿Presentas rigidez de la columna lumbar en las primeras horas de la mañana?',
                    masInfo:{
                      state:true,
                      titulo:'Ver test',
                      text:'Dificultad para mover la columna en las primeras horas de la mañana'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+MUSLO/LUMBARIRRADIADO2.jpg'
                },
                {text:'¿Y adormecimiento o sensación de hormigueo en la pierna?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+MUSLO/LUMBAR6.jpg'},
                {text:'Por último, ¿se reproduce tu dolor al presionar en un punto de la zona lumbar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+MUSLO/LUMBAR7.jpg'},
        ],
      trigger:2,
      template:7,
    },

    {
      id:2,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:3,
    },
    {
      id:3,
      respuestas:[],
      template:8,
    },
]

export default lumbarIrradiadoMuslo