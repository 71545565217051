import React, {Text} from 'react';
import './../../css/main.scss';

const rodillaAnterior = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un traumatismo o golpe directo sobre la rodilla'},
                  {id:2,text:'Un mal gesto',trigger:7,asociacion:1,masInfo:'El inicio del dolor se asocia a un mal gesto de la rodilla'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:13,asociacion:2,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:13,asociacion:2,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI

    {
        id:1,
        preg:'¿Me podrías indicar cómo ha sido el traumatismo?',
        respuestas:[{id:1,text:'Un golpe directo',masInfo:'Un choque o golpe fuerte sobre la rodilla',trigger:2,asociacion:0},
                    {id:2,text:'Una caída',masInfo:'El inicio del dolor se asocia con una caída',trigger:2,asociacion:1},
                    {id:3,text:'Otro',masInfo:'Ninguna de las anteriores',trigger:2,asociacion:2},

                ],
        template:10,
    },  
    
    {
      id:2,
      preg:'¿Y su grado de dolor?',
      type:'trauma',
      template:3,
      trigger:3,
  },       
  {
      id:3,
      preg:'¿Has presentado hinchazón o inflamación de la rodilla?',
      respuestas:[{id:1,text:'Si, de innmediato grande',masInfo:'Inflamación fuerte en pocos minutos ',trigger:4,asociacion:0},
                  {id:2,text:'Si, de inmediato pequeño',masInfo:'nflamación leve en pocos minutos',trigger:4,asociacion:1},
                  {id:3,text:'A partir de 12h',masInfo:'La inflamación ha aparecido con el paso del tiempo',trigger:4,asociacion:2},
                  {id:4,text:'No',masInfo:'No se ha presentado ninguna inflamación',trigger:4,asociacion:3}
              ],
      template:10,
  },  

    {
      id:4,
      preg: [
        {text:'¿Llevas más de 3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
        {text:'¿Has presentado un hematoma?', masInfo:{state:false,},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
        {text:'¿Y una sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA1.jpg'},
        {text:'¿Has sentido un desplazamiento inusual de la rótula?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12ROTULA.jpg'},
        {text:'¿Has tenido incapacidad para mover la rodilla en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA2.jpg'},
        {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?',
            masInfo:{
                state:true,
                titulo:'Más información',
                text:'El dolor no te permite apoyar el peso de tu cuerpo en el suelo'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA3.jpg'
        },
        {text:'Por último, ¿presentas inestabilidad de la rodilla?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA4.jpg'},
],
      trigger:5,
      template:7,
    },

    {
      id:5,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:6,
    },
    {
      id:6,
      respuestas:[],
      template:8,
    },
            //MAL GESTO SI

      {
        id:7,
        preg:'¿Cómo ha sido el gesto?',
        respuestas:[{id:1,text:'Girar sobre si mismo con el pie apoyado',masInfo:'Un mal gesto al girar con el pie apoyado',trigger:8,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12GIRO.jpg'},
                    {id:2,text:'Desplazamiento inusual de la rodilla',masInfo:'Como si la rodilla se hubiese desplazado',trigger:8,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12RODILLA.jpg'},
                    {id:3,text:'Desplazamiento inusual de la rótula',masInfo:'Como si la rótula se hubiese desplazado hacia fuera',trigger:8,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12ROTULA.jpg'},
                    {id:4,text:'Otro',masInfo:'No estoy seguro o ha sido un gesto diferente',trigger:8,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/OTRO.jpg'}
                ],
        template:4,
    },  
    {
        id:8,
        preg:'¿Me podrías indicar la intensidad del dolor en el momento de la lesión?',
        type:'trauma',
        template:3,
        trigger:9,
    },
    {
        id:9,
        preg:'¿Has presentado hinchazón o inflamación?',
        respuestas:[{id:1,text:'Si, de innmediato grande',masInfo:'Una inflamación fuerte en pocos minutos ',trigger:10,asociacion:0},
                    {id:2,text:'Si, de inmediato pequeño',masInfo:'Una inflamación leve en pocos minutos',trigger:10,asociacion:1},
                    {id:2,text:'A partir de 12h',masInfo:'La inflamación ha aparecido con el tiempo',trigger:10,asociacion:2},
                    {id:2,text:'No',trigger:10,masInfo:'No he tenido ninguna inflamación',asociacion:3}
                ],
        template:10,
    },  
    {
        id:10,
        preg: [
          {text:'¿Llevas más de 3 meses con dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Y una sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA1.jpg'},
          {text:'¿Has tenido incapacidad para mover la rodilla en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA2.jpg'},
          {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?',
              masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'El dolor no te permite apoyar el peso de tu cuerpo en el suelo'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA3.jpg'
          },
          {text:'Por último, ¿presentas inestabilidad de la rodilla?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA4.jpg'},
  ],
        trigger:11,
        template:7,
    },

    {
      id:11,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:12,
    },
    {
      id:12,
      respuestas:[],
      template:8,
    },   
    //TRAUMA NO
    {
        id:13,
        preg:'¿En qué parte de la cara anterior son más intensos los síntomas?',
        respuestas:[{id:1,text:'Parte antero-inferior',masInfo:'El dolor se localiza por debajo de la rótula',trigger:14,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA5INFERIOR.jpg'},
                    {id:2,text:'Parte antero-medial',masInfo:'El dolor se localiza a la altura de la rótula',trigger:14,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA5MEDIO.jpg'},
                    {id:3,text:'Parte antero-superior',masInfo:'El dolor se localiza por encima de la rótula',trigger:14,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA5SUPERIOR.jpg'},
                    {id:4,text:'Difuso', masInfo:'El dolor es difícil de localizar en un punto concreto',trigger:14,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA5DIFUSO.jpg'}],
        template:4,
      },
      {
        id:14,
        preg: [ 
          {text:'¿Realizas actividad física regularmente (3-4 veces a la semana)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/ANTERIOR/CADERADEPORTE.jpg'},
          {text:'¿Has presentado inflamación en la parte anterior de la rodilla?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA6.jpg'},
          {text:'¿Has tenido lesiones graves previas o cirugías en la rodilla afectada?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA3.jpg'},
          {text:'¿Notas crepitaciones o chasquidos al mover la rodilla?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'Las crepitaciones o chasquidos se refieren a unos crujidos o clicks al mover la rodilla'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA7.jpg'
          },
          {text:'¿Y sensación de bloqueo al flexionar y extender la rodilla?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Sensación de rigidez al doblar las rodillas'
              },
              imagen:'x',
              video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA8.mp4'
          },
          {text:'¿Se reproduce tu dolor al apoyar la rodilla sobre el suelo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA2.jpg'},
          {text:'¿Y al extender la rodilla completamente?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA9.jpg'},
          {text:'¿Se reproduce tu dolor al realizar una sentadilla?',
              masInfo:{
                state:true,
                titulo:'¿No puedes hacer una sentadilla?',
                text:'Si no puedes hacer una sentadilla, ¿sientes dolor al agacharte?'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/SALTO.jpg'
          },
          {text:'¿El dolor se puede localizar mediante la palpación (tocando con la mano)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA11.jpg'},

  ],
        trigger:15,
        template:7,
    },
    {
      id:15,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:16,
    },
    {
      id:16,
      respuestas:[],
      template:8,
    }]

export default rodillaAnterior