import React, {Component} from 'react';
import '../css/text-template.scss';
import '../css/main.scss';
import Fade from 'react-reveal/Fade';
import logo from './../img/logoAzulOscuro.png';
import './../css/animate.scss';
import aurya from './../img/aurya.png';

class TextTemplate extends Component{
    constructor(props){
        super(props)
        this.state = {
            numeroTexto:0,
            cssText:'line',         
        }
    }
    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({numeroTexto: this.state.numeroTexto +1});
          
        }, 3000);
      }
      
      componentWillUnmount() {
        clearInterval(this.interval);
      }

      componentDidUpdate(){

        const longitudArrayPreguntas = this.props.preguntas.preg.length;
        console.log('state',this.state.numeroTexto)
        console.log('length',longitudArrayPreguntas)

        if(longitudArrayPreguntas < this.state.numeroTexto + 1){

            this.props.handleclick(this.props.state.articulacion,this.props.state.zonaDolor,this.props.preguntas.trigger)
        }
      }
      
    render(){

        return(     
            <div className='primaryContainer'>                
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div> 
                     
            <div className='textBlock fade-in-right-short'>        
                <div className={this.state.cssText}>      
                            {this.props.preguntas.preg[this.state.numeroTexto]}

                            
                </div>  
            </div>
           
            <div className='emptyBlock'>
                <p></p>
            </div>
            </div>
        )
    }

}
//<Flip spy={this.state.numeroTexto} opposite='true' appear='true' top cascade></Flip>
//<div class="loadingio-spinner-eclipse-dhj14gaj5gi"><div class="ldio-mods6jvarp">
//<div></div>
//</div></div>

export default TextTemplate;