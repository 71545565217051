import React,{Component} from 'react';
import '../css/articulacion.scss';
import ImageMapper from 'react-image-mapper';
import bodyEspalda from '../img/backMaleFinal.png';
import bodyFront from '../img/bodyFinal.png';
import bodyEspaldaMujer from '../img/backFemaleFinal.png';
import bodyFrontMujer from '../img/frontFemaleFinal.png';
import arrow from '../img/arrow-izq.png'
import data1 from '../data/data';
import Fade from 'react-reveal/Fade';
import logo from './../img/logoAzulOscuro.png'
import zoom from './../img/zoom.png'
import Tooltip from './Tooltip'
import flecha from './../img/arrow-color.png'
import flechaIzq from './../img/flechaIzq.png'
import flechaDer from './../img/flechaDer.png'

class Zona extends Component {
  render() {
    return (
      <div className='zona-articulacion'>
          <div className="container-titulo-zona"  onClick={() => this.props.handleclick(this.props.articulacion,this.props.resp.trigger,0)}>
            <img src={this.props.resp.img} className='imagen-zona' onClick={() => this.props.handleclick(this.props.articulacion,this.props.resp.trigger,0)} width='90%' height='80%'></img>
            <p className='titulo-parte'>{this.props.resp.text}</p>
            <p className='texto-parte'>{this.props.resp.masInfo}</p>
            <p className='elegir'>Elegir</p>
            <img src={flecha} className='flecha' />
          </div>
      </div>
    );
  }
}

class Articulacion extends Component{
  constructor(props){
    super(props)
    this.state={
      hoveredArea:'',
      articulacion:1,
      articulacionNombre:'',
      show: false,
      position:0,
      tooltip:false,
      tooltipPosition:[0,0],
      cssTriggerText:'line',
      cssTriggerBody:'display-none',
      cssTriggerArrow:'arrow-container',
      cssMobileZoom:'mobile zoom',
      screen:0,
      textTrigger:0,
      bodyPosition:true,
      bodyFront:bodyFrontMujer,
      bodyEspalda:bodyEspaldaMujer,
      map:0,
    }
    this.enterArea = this.enterArea.bind(this);
    this.handleBodyPosition = this.handleBodyPosition.bind(this);
  }
    enterArea(area) {
      console.log(area)
       this.setState({ hoveredArea: area.name,cssTriggerArrow:'display-none', tooltip:true, tooltipPosition:area.tooltip});
      }

    clicked= (area) => {
      if(area.id > 14){
        this.props.handleclick(area.id,0,0)
      }
      else{
        this.setState({ screen:1,position:1,articulacion:area.id, articulacionNombre:area.nameText});
      }      
  }

  volver= () => {
    this.setState({show:false,position:0});
  }

  zoomOutMobile = () => {
    const viewport = document.querySelector('meta[name="viewport"]');

    if ( viewport ) {
      viewport.content = 'initial-scale=1';
      viewport.content = 'width=device-width';
    }
  }

  leaveArea(area) {
    if (!this.state.show){
      this.setState({ hoveredArea: '',cssTriggerText:'lineActive',cssTriggerArrow:'arrow-container',tooltip:false});
    }
  }

  handleBodyPosition(){
    this.setState(prevState => ({
      bodyPosition: !prevState.bodyPosition
    }));
  }

  componentDidMount(){
    if (this.props.state.genero===0){
      this.setState({bodyFront:bodyFront,bodyEspalda:bodyEspalda,map:1})
    }
  
    let timer = setTimeout(this.setState.bind(this, {cssTriggerText:'lineActive'}), 6000);
    let timer2 = setTimeout(this.setState.bind(this, {cssTriggerBody:'cuerpo-container'}), 8000);
    let timer3 = setTimeout(this.setState.bind(this, {cssMobileZoom:'zoomActive mobile'}), 8600);
    let timer4 = setTimeout(this.setState.bind(this, {cssMobileZoom:'zoom mobile'}), 11300);

  }

  componentWillUnmount(){
    clearTimeout(this.timer);
    clearTimeout(this.timer2);
    clearTimeout(this.timer3);
    clearTimeout(this.timer4);
  }

  render(){ 
      const MAPMALE ={
        name:'body-areas',
        areas: [
          { id:3, name:'CODO',nameText: "el codo",tooltip:[-130,-28] ,shape: "poly", coords: [43,171,38,173,33,182,30,192,29,201,34,207,44,208,52,203,54,189,57,181,53,175], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:3, name:'CODO',nameText: "el codo",tooltip:[17,-28],shape: "poly", coords: [166,161,161,166,153,169,143,172,150,182,150,195,153,207,164,202,172,194,170,183,169,178,167,172], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:16, name:'MANDIBULA',nameText: "la mandibula",tooltip:[-150,-32], shape: "poly", coords: [80,48,85,55,92,58,105,58,111,55,116,48,116,58,114,65,104,77,93,77,84,66,81,59], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:10, name:'MUSLO',nameText: "el muslo",tooltip:[-130,-28], shape: "poly", coords: [93,264,85,260,73,256,58,248,55,273,54,288,56,305,59,316,62,323,70,325,80,328,89,327,95,319,95,305,95,288], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:10, name:'MUSLO',nameText: "el muslo",tooltip:[17,-28], shape: "poly", coords: [97,260,96,276,97,292,98,306,100,319,117,322,127,327,133,326,134,316,136,308,138,299,140,286,139,268,138,255,134,243,125,249,108,258], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:7, name:'TOBILLO',nameText: "el tobillo",tooltip:[-130,-28], shape: "poly", coords: [69,434,68,448,66,454,72,458,80,461,84,458,84,435,77,433], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:7, name:'TOBILLO',nameText: "el tobillo",tooltip:[17,-28], shape: "poly", coords: [123,433,121,445,121,457,130,461,137,458,136,446,137,434,131,432], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:1, name:'HOMBRO',nameText: "el hombro",tooltip:[-130,-28], shape: "poly", coords: [61,98,50,104,43,114,40,127,45,143,57,132,60,120,71,102],strokeColor:'transparent'},
          { id:1, name:'HOMBRO',nameText: "el hombro",tooltip:[17,-28], shape: "poly", coords: [126,103,128,108,132,120,136,128,141,135,149,139,155,137,160,133,160,119,158,110,153,103,143,97,134,95,127,98],strokeColor:'transparent'},      
          { id:4, name:'MUÑECA',nameText: "la muñeca",tooltip:[-130,-28], shape: "poly", coords: [27,225,26,240,28,244,35,244,37,240,38,233,40,227,35,224,31,224], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:4, name:'MUÑECA',nameText: "la muñeca",tooltip:[17,-28], shape: "poly", coords: [154,225,154,237,152,241,160,243,167,242,168,231,168,226,162,224,158,224], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},         
          { id:5, name:'MANO',nameText: "la mano",tooltip:[-130,-28], shape: "poly", coords: [25,247,24,254,23,262,25,269,26,276,30,284,35,289,38,288,43,287,42,282,40,279,43,276,39,274,34,272,32,279,34,259,35,254,38,260,44,267,46,265,42,254,41,246,36,240,35,244,28,244,27,240,25,247], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:5, name:'MANO',nameText: "la mano",tooltip:[17,-28], shape: "poly", coords: [167,242,160,243,153,240,147,246,145,251,143,257,139,263,139,267,144,267,147,262,150,254,153,255,152,268,151,273,147,281,143,287,146,287,156,281,163,275,167,270,170,261,169,248], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:15, name:'TÓRAX',nameText: "el tórax",tooltip:[10,10], shape: "poly", coords: [70,102,63,110,59,121,57,133,63,142,64,159,71,175,82,181,97,181,112,180,126,176,132,171,136,161,134,140,134,120,129,108,127,103,127,98,119,99,105,99,100,101,94,104,88,101,79,100], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:2, name:'BRAZO',nameText: "el brazo",tooltip:[17,-28], shape: "poly", coords: [135,133,137,150,140,165,143,172,153,169,161,166,166,161,165,144,160,133,150,139,141,135], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:2, name:'BRAZO',nameText: "el brazo",tooltip:[-130,-28], shape: "poly", coords: [45,145,42,152,40,161,43,171,52,176,61,169,62,156,60,141,56,133,51,139], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:11, name:'CADERA',nameText: "la cadera",tooltip:[-130,-28], shape: "poly", coords: [64,203,78,210,89,214,101,213,114,210,128,202,128,206,132,215,135,223,135,237,135,243,125,249,108,258,96,260,86,260,73,256,59,248,60,235,62,223,66,212], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:9, name:'RODILLA',nameText: "la rodilla",tooltip:[-130,-28], shape: "poly", coords: [95,319,89,327,80,328,70,325,62,323,65,328,67,334,66,343,66,365,79,371,89,360,92,346,93,337], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:9, name:'RODILLA',nameText: "la rodilla",tooltip:[17,-28], shape: "poly", coords: [100,319,118,322,127,327,133,326,134,338,134,355,126,369,120,372,113,362,106,345,101,328], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:8, name:'PANTORRILLA',nameText: "la pantorrilla",tooltip:[-160,-28], shape: "poly", coords: [66,365,65,377,65,395,68,434,77,433,84,434,88,413,95,390,94,378,89,360,79,371], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:8, name:'PANTORRILLA',nameText: "la pantorrilla",tooltip:[17,-28], shape: "poly", coords: [113,362,112,387,119,412,123,432,131,432,137,434,139,421,143,402,143,382,138,367,135,355,120,372], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:6, name:'PIE',nameText: "el pie",tooltip:[-130,-28], shape: "poly", coords: [66,453,72,458,80,461,84,457,87,467,83,472,74,473,62,480,47,480,42,476,43,472,52,468], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:6, name:'PIE',nameText: "el pie",tooltip:[17,-28], shape: "poly", coords: [121,456,130,461,137,457,139,468,144,478,143,483,133,485,129,486,118,485,117,476,120,468], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:14, name:'CERVICAL',nameText: "la zona cervical",tooltip:[-130,-28], shape: "poly", coords: [84,78,86,88,88,96,85,100,79,100,70,101,61,98,75,88], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:14, name:'CERVICAL',nameText: "la zona cervical",tooltip:[17,-28], shape: "poly", coords: [136,94,129,89,113,79,113,87,110,94,111,99,127,98], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          { id:14, name:'CABEZA',nameText: "la zona de cabeza y cuello",tooltip:[-130,-28], shape: "poly", coords: [77,33,83,36,91,34,98,37,107,34,113,37,119,34,117,24,105,12,92,12,82,22], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
        ]}

        const MAPMALEBACK ={
          name:'body-areas',
          areas: [
            { id:3, name:'CODO',nameText: "el codo",tooltip:[-130,-28],shape: "poly", coords: [42,161,39,171,33,182,31,194,30,208,43,207,54,206,57,193,58,181,63,171,49,171], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:3, name:'CODO',nameText: "el codo",tooltip:[17,-28],shape: "poly", coords: [156,183,159,189,159,198,161,209,173,215,183,211,182,199,180,191,178,183,175,174,167,176], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:10, name:'MUSLO',nameText: "el muslo",tooltip:[-130,-28], shape: "poly", coords: [62,250,58,264,55,283,55,305,60,323,62,332,68,331,75,327,81,330,90,334,92,322,98,301,100,292,101,269,82,268,70,257], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:10, name:'MUSLO',nameText: "el muslo",tooltip:[17,-28], shape: "poly", coords: [143,255,146,267,147,279,146,296,143,310,141,323,141,339,138,338,129,334,121,337,112,337,109,327,106,301,103,294,103,280,105,269,107,265,125,268,136,265], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:7, name:'TOBILLO',nameText: "el tobillo",tooltip:[-130,-28], shape: "poly", coords: [65,433,65,443,63,450,64,456,70,458,81,451,81,444,81,434,72,432], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:7, name:'TOBILLO',nameText: "el tobillo",tooltip:[17,-28], shape: "poly", coords: [131,438,129,451,129,456,134,462,139,464,146,462,146,453,146,438,138,434], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:1, name:'HOMBRO',nameText: "el hombro",tooltip:[-130,-28], shape: "poly", coords: [82,101,71,97,56,103,48,112,44,123,45,137,57,125,72,118,80,110],strokeColor:'transparent'},
            { id:1, name:'HOMBRO',nameText: "el hombro",tooltip:[17,-28], shape: "poly", coords: [144,107,148,113,155,123,161,127,168,137,169,129,168,121,163,114,156,106,146,102],strokeColor:'transparent'},      
            { id:4, name:'MUÑECA',nameText: "la muñeca",tooltip:[-130,-28], shape: "poly", coords: [37,228,30,231,29,243,32,247,39,247,43,244,41,239,44,231], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:4, name:'MUÑECA',nameText: "la muñeca",tooltip:[17,-28], shape: "poly", coords: [172,232,165,233,164,241,161,244,168,248,176,246,179,234], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},         
            { id:5, name:'MANO',nameText: "la mano",tooltip:[-130,-28], shape: "poly", coords: [29,243,27,252,26,264,31,277,41,286,49,286,49,280,48,272,48,268,49,264,46,261,45,249,43,244,39,247,32,247], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:5, name:'MANO',nameText: "la mano",tooltip:[17,-28], shape: "poly", coords: [161,244,155,249,152,255,145,263,146,267,149,267,158,257,161,260,160,274,158,280,153,288,157,290,173,278,179,268,179,246,168,248], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:12, name:'LUMBAR',nameText: "la lumbar",tooltip:[17,-28], shape: "poly", coords: [71,175,71,184,69,192,68,202,71,210,87,218,100,222,108,227,116,223,128,221,136,215,134,206,136,197,137,186,141,175,128,176,107,176,88,176], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:13, name:'DORSAL',nameText: "la dorsal",tooltip:[17,-28], shape: "poly", coords: [82,101,96,103,112,104,129,103,143,107,148,113,154,123,161,127,158,134,153,148,147,164,141,175,128,176,107,176,88,176,71,175,70,164,70,143,66,129,72,118,80,110], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:2, name:'BRAZO',nameText: "el brazo",tooltip:[-130,-28], shape: "poly", coords: [57,125,45,137,42,151,42,161,49,171,63,171,68,161,70,143,66,129], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:2, name:'BRAZO',nameText: "el brazo",tooltip:[17,-28], shape: "poly", coords: [155,123,161,127,168,136,173,151,175,174,167,176,156,183,150,173,147,164,153,148,158,134], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:11, name:'CADERA',nameText: "la cadera",tooltip:[-130,-28], shape: "poly", coords: [71,210,68,220,65,232,62,250,70,257,82,268,101,269,101,265,104,262,106,264,105,269,126,268,136,265,143,255,141,244,141,234,141,225,136,215,128,221,116,223,108,227,100,222,87,218], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:8, name:'RODILLA',nameText: "la rodilla",tooltip:[-130,-28], shape: "poly", coords: [62,332,61,341,61,357,69,361,78,363,84,359,87,347,90,334,80,330,75,327,68,331], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:8, name:'RODILLA',nameText: "la rodilla",tooltip:[-130,-28], shape: "poly", coords: [112,337,118,354,122,364,135,368,145,363,142,352,141,339,137,338,129,334,121,337], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:9, name:'PANTORRILLA',nameText: "la pantorrilla",tooltip:[-160,-28], shape: "poly", coords: [61,357,59,384,62,411,65,433,72,432,81,434,84,414,90,394,91,382,84,359,78,364,69,361], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:9, name:'PANTORRILLA',nameText: "la pantorrilla",tooltip:[17,-28], shape: "poly", coords: [122,364,121,380,121,396,128,419,131,428,138,434,146,438,149,419,152,396,152,387,150,375,145,363,135,368], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:6, name:'PIE',nameText: "el pie",tooltip:[-130,-28], shape: "poly", coords: [63,450,64,456,70,458,76,454,81,451,85,463,85,467,81,471,58,471,50,474,44,474,41,471,41,469,47,463,53,462], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:6, name:'PIE',nameText: "el pie",tooltip:[17,-28], shape: "poly", coords: [129,456,134,462,139,465,146,462,150,468,150,471,144,476,136,476,129,471,124,471,123,466,126,461], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:14, name:'CERVICAL',nameText: "la zona cervical",tooltip:[17,-28], shape: "poly", coords: [115,69,105,65,99,62,94,66,89,68,89,76,89,88,83,90,71,97,82,101,96,103,112,104,129,103,144,107,146,102,136,94,122,82], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:14, name:'CABEZA',nameText: "la zona de cabeza y cuello",tooltip:[-130,-28], shape: "poly", coords: [115,69,121,59,123,49,123,37,116,27,106,21,93,21,82,26,79,33,76,46,81,53,82,47,86,48,88,56,94,66,99,62,105,65], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          ]
        }

        const MAPFEMALE ={
          name:'body-areas',
          areas: [
            { id:3, name:'CODO',nameText: "el codo",tooltip:[-130,-28],shape: "poly", coords: [37,181,31,193,39,199,51,199,55,190,60,174,49,175,45,168], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:3, name:'CODO',nameText: "el codo",tooltip:[17,-28],shape: "poly", coords: [157,190,161,202,172,201,181,197,179,191,175,182,168,170,161,174,151,174], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:16, name:'MANDIBULA',nameText: "la mandibula",tooltip:[-150,-32], shape: "poly", coords: [84,70,86,84,94,93,98,94,105,94,116,88,117,82,118,67,112,74,105,78,94,78], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:10, name:'MUSLO',nameText: "el muslo",tooltip:[-130,-28], shape: "poly", coords: [66,255,59,262,58,278,59,299,63,322,71,327,80,331,87,329,92,321,93,313,97,296,100,281,100,269,98,269,78,260], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:10, name:'MUSLO',nameText: "el muslo",tooltip:[17,-28], shape: "poly", coords: [146,249,148,264,149,288,148,307,146,323,136,327,128,330,119,331,118,325,115,305,109,288,104,269,129,260], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:7, name:'TOBILLO',nameText: "el tobillo",tooltip:[-130,-28], shape: "poly", coords: [61,426,61,442,57,448,63,449,68,449,71,446,73,439,74,426,68,427], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:7, name:'TOBILLO',nameText: "el tobillo",tooltip:[17,-28], shape: "poly", coords: [139,428,140,421,141,450,147,452,153,449,152,442,151,428,146,429], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:1, name:'HOMBRO',nameText: "el hombro",tooltip:[-130,-28], shape: "poly", coords: [68,117,61,122,56,129,54,134,53,142,65,139,72,129,76,120],strokeColor:'transparent'},
            { id:1, name:'HOMBRO',nameText: "el hombro",tooltip:[17,-28], shape: "poly", coords: [133,119,140,116,147,119,151,123,155,128,157,133,159,144,154,144,144,139,137,129],strokeColor:'transparent'},      
            { id:4, name:'MUÑECA',nameText: "la muñeca",tooltip:[17,-28], shape: "poly", coords: [177,233,180,241,178,246,186,248,191,247,191,240,188,232,182,230], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:4, name:'MUÑECA',nameText: "la muñeca",tooltip:[17,-28], shape: "poly", coords: [31,228,27,225,21,226,17,236,15,246,21,246,25,242,24,240], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},         
            { id:5, name:'MANO',nameText: "la mano",tooltip:[-130,-28], shape: "poly", coords: [25,242,22,246,15,246,13,253,12,261,13,266,14,271,22,284,21,278,19,274,23,274,23,272,19,269,18,262,20,261,21,252,23,252,29,264,32,264,28,249], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:5, name:'MANO',nameText: "la mano",tooltip:[17,-28], shape: "poly", coords: [172,255,167,265,167,267,169,267,177,259,180,260,182,273,178,284,181,288,188,280,193,269,194,263,192,251,191,247,186,248,178,246], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:2, name:'BRAZO',nameText: "el brazo",tooltip:[-130,-28], shape: "poly", coords: [51,151,45,168,49,175,60,174,64,166,62,156,65,148,65,139,53,142], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:2, name:'BRAZO',nameText: "el brazo",tooltip:[17,-28], shape: "poly", coords: [151,174,161,174,168,170,166,163,164,157,160,144,155,144,144,139,141,139,141,152], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:11, name:'CADERA',nameText: "la cadera",tooltip:[-130,-28], shape: "poly", coords: [68,220,66,233,62,245,61,248,78,260,100,269,98,262,103,262,104,269,129,260,146,249,141,230,137,217,115,226,98,228,84,226], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:9, name:'RODILLA',nameText: "la rodilla",tooltip:[-130,-28], shape: "poly", coords: [63,322,64,339,64,353,62,365,74,370,85,365,85,354,87,349,90,338,92,321,87,329,80,331,71,327], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:9, name:'RODILLA',nameText: "la rodilla",tooltip:[17,-28], shape: "poly", coords: [119,331,122,345,127,358,128,366,128,371,140,373,151,368,147,355,147,344,146,335,146,323,136,327,128,330], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:8, name:'PANTORRIILLA',nameText: "la pantorrilla",tooltip:[17,-28], shape: "poly", coords: [128,370,129,390,134,406,138,419,139,428,146,429,151,428,153,417,154,407,155,390,154,378,151,368,140,373], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:8, name:'PANTORRILLA',nameText: "la pantorrilla",tooltip:[-160,-28], shape: "poly", coords: [61,365,59,383,60,401,61,426,68,427,74,426,77,414,81,403,85,389,86,380,86,365,74,370], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:6, name:'PIE',nameText: "el pie",tooltip:[17,-28], shape: "poly", coords: [141,450,141,470,144,474,158,474,162,472,162,468,161,466,155,458,153,449,147,452], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:6, name:'PIE',nameText: "el pie",tooltip:[-130,-28], shape: "poly", coords: [57,448,50,456,43,461,40,465,40,468,45,468,45,469,52,469,54,467,60,467,65,460,72,460,73,458,74,454,71,446,68,449,63,449], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:14, name:'CERVICAL',nameText: "la zona cervical",tooltip:[-130,-28], shape: "poly", coords: [92,94,98,94,98,106,97,119,76,119,68,117,86,107,92,101], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:14, name:'CERVICAL',nameText: "la zona cervical",tooltip:[17,-28], shape: "poly", coords: [116,88,116,107,117,111,120,110,125,106,140,116,133,119,106,119,105,106,105,94], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:15, name:'TÓRAX',nameText: "el tórax",tooltip:[10,10], shape: "poly", coords: [76,120,72,129,65,139,65,148,62,156,69,165,72,177,78,186,84,195,98,197,114,195,124,186,133,179,138,164,141,152,141,139,137,129,133,119,106,119,102,122,97,119,88,119], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:14, name:'CABEZA',nameText: "la zona de cabeza y cuello",tooltip:[-130,-28], shape: "poly", coords: [82,55,90,57,98,58,107,57,125,54,118,40,105,35,97,36,88,42], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          ]
        }
        const MAPFEMALEBACK ={
          name:'body-areas',
          areas: [
            { id:3, name:'CODO',nameText: "el codo",tooltip:[-130,-28],shape: "poly", coords: [43,170,37,179,33,185,30,191,28,198,33,204,42,204,51,199,54,192,57,182,58,178,50,177], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:3, name:'CODO',nameText: "el codo",tooltip:[17,-28],shape: "poly", coords: [168,170,175,181,178,190,181,203,172,204,162,203,156,189,152,177,163,175], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:16, name:'MANDIBULA',nameText: "la mandibula",tooltip:[-100,130], shape: "poly", coords: [], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:10, name:'MUSLO',nameText: "el muslo",tooltip:[-130,-28], shape: "poly", coords: [58,268,58,288,60,307,62,318,63,325,72,326,84,326,91,325,92,318,94,311,96,301,99,286,101,274,78,274], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:10, name:'MUSLO',nameText: "el muslo",tooltip:[17,-28], shape: "poly", coords: [148,273,148,290,147,305,146,316,145,325,137,328,126,328,118,325,116,309,111,291,107,274,130,274,147,270], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:7, name:'TOBILLO',nameText: "el tobillo",tooltip:[17,-28], shape: "poly", coords: [139,427,139,432,140,440,140,446,146,448,150,447,152,442,151,433,152,427,145,427], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:7, name:'TOBILLO',nameText: "el tobillo",tooltip:[-130,-28], shape: "poly", coords: [60,428,60,440,57,447,58,451,64,454,71,452,72,448,72,441,74,432,74,438,67,430], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:1, name:'HOMBRO',nameText: "el hombro",tooltip:[-130,-28], shape: "poly", coords: [66,118,60,122,56,127,53,134,52,138,51,148,57,147,64,141,67,140,68,128,67,124],strokeColor:'transparent'},
            { id:1, name:'HOMBRO',nameText: "el hombro",tooltip:[17,-28], shape: "poly", coords: [146,117,145,126,146,133,149,141,153,144,159,145,158,138,157,132,155,128,152,123],strokeColor:'transparent'},      
            { id:4, name:'MUÑECA',nameText: "la muñeca",tooltip:[17,-28], shape: "poly", coords: [184,223,185,228,187,234,187,239,181,239,175,236,175,232,173,225,178,223], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:4, name:'MUÑECA',nameText: "la muñeca",tooltip:[-130,-28], shape: "poly", coords: [30,230,25,228,20,226,17,235,15,241,14,244,21,244,26,241,26,237], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},         
            { id:5, name:'MANO',nameText: "la mano",tooltip:[-130,-28], shape: "poly", coords: [14,244,13,249,12,254,11,260,12,264,14,272,22,282,24,282,24,272,20,268,20,259,23,254,27,254,29,261,33,261,33,257,30,249,29,245,26,241,21,244], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:5, name:'MANO',nameText: "la mano",tooltip:[17,-28], shape: "poly", coords: [187,239,181,239,175,236,171,240,167,246,163,253,163,257,165,257,170,250,174,250,176,252,176,262,175,266,173,270,173,274,175,277,179,277,179,274,182,274,184,270,190,261,191,252,189,246], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:12, name:'LUMBAR',nameText: "la lumbar",tooltip:[17,-28], shape: "poly", coords: [73,178,74,192,73,202,70,212,68,221,82,226,108,230,127,227,139,221,135,210,131,198,131,195,133,175,121,181,108,183,93,181], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:13, name:'DORSAL',nameText: "la dorsal",tooltip:[-130,-28], shape: "poly", coords: [108,129,102,126,94,125,84,125,74,126,69,128,67,136,67,140,70,148,70,165,73,178,93,181,108,183,121,181,133,175,135,169,135,162,143,151,149,141,146,133,145,126,137,125,127,125,116,126], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:2, name:'BRAZO',nameText: "el brazo",tooltip:[17,-28], shape: "poly", coords: [160,145,164,156,167,165,168,170,162,175,152,177,147,165,143,151,149,141,153,144], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:2, name:'BRAZO',nameText: "el brazo",tooltip:[-130,-28], shape: "poly", coords: [51,148,47,159,43,170,50,177,58,178,64,167,69,157,70,148,69,144,67,140,62,143,57,147], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:11, name:'CADERA',nameText: "la cadera",tooltip:[-130,-28], shape: "poly", coords: [68,221,65,234,60,251,59,268,78,274,100,274,100,266,107,259,106,274,130,274,147,270,147,254,144,240,139,221,127,227,108,230,82,226], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:9, name:'RODILLA',nameText: "la rodilla",tooltip:[-130,-28], shape: "poly", coords: [63,325,63,354,62,361,69,364,77,364,85,360,85,354,88,349,90,340,91,334,91,325,84,326,72,326], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:9, name:'RODILLA',nameText: "la rodilla",tooltip:[17,-28], shape: "poly", coords: [145,326,146,331,147,340,147,354,149,360,144,365,136,366,128,366,127,360,126,357,121,342,118,325,127,328,137,328], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:8, name:'PANTORRIILLA',nameText: "la pantorrilla",tooltip:[-160,-28], shape: "poly", coords: [62,361,58,379,58,401,59,412,60,428,67,430,74,428,79,413,84,398,85,391,85,360,77,364,69,364], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:8, name:'PANTORRILLA',nameText: "la pantorrilla",tooltip:[17,-28], shape: "poly", coords: [128,366,128,387,131,399,135,410,138,424,139,427,145,427,152,427,153,414,154,406,155,394,155,381,153,373,149,360,144,365,136,366], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:6, name:'PIE',nameText: "el pie",tooltip:[-130,-28], shape: "poly", coords: [58,447,48,450,41,455,41,458,44,461,50,463,59,466,63,467,70,467,72,465,72,457,71,452,64,454,58,451], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:6, name:'PIE',nameText: "el pie",tooltip:[17,-28], shape: "poly", coords: [139,446,139,453,141,460,143,463,151,463,158,456,159,456,159,451,156,448,153,448,152,442,150,447,146,448], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:14, name:'CERVICAL',nameText: "la zona cervical",tooltip:[-130,-28], shape: "poly", coords: [93,93,92,101,77,112,66,118,66,124,74,126,102,126,108,128,116,126,148,126,146,118,140,114,135,112,123,104,117,98,117,93,114,88,99,89], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
            { id:14, name:'CABEZA',nameText: "la zona de cabeza y cuello",tooltip:[-130,-28], shape: "poly", coords: [77,86,80,59,88,41,101,34,117,39,126,58,130,77,132,85,127,91,117,92,114,87,99,87,92,93,82,91], fillColor:'rgb(206, 230, 238,0.2);',strokeColor:'transparent'},
          ]
        }
        const ARTICULACIONES = [
          { name:'Hombro',id:1},
          { name:'Brazo',id:2},
          { name:'Codo',id:3},
          { name:'Muñeca',id:4},
          { name:'Mano',id:5},
          { name:'Pie',id:6},        
          { name:'Tobillo',id:7},
          { name:'Pantorrilla',id:8},
          { name:'Rodilla',id:9},
          { name:'Muslo',id:10},
          { name:'Cadera',id:11},
          { name:'Lumbar',id:12},
          { name:'Dorsal',id:13},
          { name:'Cervical',id:14},
        ]
        const MAP=[[MAPFEMALE,MAPFEMALEBACK],[MAPMALE,MAPMALEBACK]]

        //Related articulations      
        const articulacion1 = this.state.articulacion < 3?
          ARTICULACIONES[ARTICULACIONES.length - Math.abs(this.state.articulacion -3)]
          :
          ARTICULACIONES[(this.state.articulacion -3) % ARTICULACIONES.length]
        
        const articulacion2 = this.state.articulacion < 2?
          ARTICULACIONES[ARTICULACIONES.length - Math.abs(this.state.articulacion -2)]
          :
          ARTICULACIONES[(this.state.articulacion -2)%ARTICULACIONES.length]
        
        const articulacion3 = ARTICULACIONES[(this.state.articulacion -1) % ARTICULACIONES.length]
        const articulacion4 = ARTICULACIONES[this.state.articulacion % ARTICULACIONES.length]
        const articulacion5 = ARTICULACIONES[(this.state.articulacion +1) % ARTICULACIONES.length]


    const respuestas = data1[this.state.articulacion][0][0].respuestas.map((resp) =>
      <Zona resp={resp} articulacion={this.state.articulacion} handleclick= {this.props.handleclick}
        key={resp.id} 
          />)

      if(this.state.screen === 0){
        let timer3 = setTimeout(this.setState.bind(this,{textTrigger:1}), 3000);
        if(this.state.textTrigger == 0){
          return(
            <div className='primaryContainer'>
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div> 
              <div className='textBlock fade-in-right-short'>
                      <div className={this.state.cssTriggerText}>
                            <p className='text-aurya'>Muchas gracias <span style={{fontWeight:'700'}}>{this.props.state.nombre}</span></p>
                      </div>
              </div>
              <div className='emptyBlock'>
                <p></p>               
            </div>            
            </div> 
      )

        }
        if(this.state.textTrigger===1){
          return(
            <div className='primaryContainer'>
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div> 
              <div className='textBlock fade-in-short'>
                       <div className={this.state.cssTriggerText}>
                              <p className='text-aurya'>Ahora quiero que me indiques donde tienes el <span style={{fontWeight:'700'}}>foco</span> del dolor</p>
                      </div>
                </div>
                {/*} <Flip left cascade sky={this.state.hoveredArea}>
                <h2 className='texto-grande-fondo' style={{fontWeight:700}}>{this.state.hoveredArea}</h2>
          </Flip>*/}
          <div className={this.state.cssMobileZoom}>
              <img src={zoom} />
              <p className='text-aurya'>Haz zoom y elige la zona de dolor</p>
          </div>
                      <div className={this.state.cssTriggerBody} >
                      <div className={this.state.cssTriggerArrow}>
                            <div className='arrow-izq fade-in-left-short' onClick={()=>this.handleBodyPosition()}>
                            </div>
                            <p className='arrow-text fade-in-left-short'>GIRAR</p>
                          </div>
                          
                          {this.state.bodyPosition?
                                  <Fade spy={this.state.bodyPosition} duration='0.8s' appear>
                                      <div className='body-container' style={{zIndex:'20'}} >
                                          <ImageMapper 
                                                src={this.state.bodyFront}
                                                map={MAP[this.state.map][0]}
                                                width={200} 
                                                fillColor={'rgba(255, 0, 0, 0.15)'}  /* Azul rgba(194, 192, 244, 0.35); Rojo rgba(255, 0, 0, 0.15)*/
                                                height={500}
                                                onMouseEnter={area => this.enterArea(area)}
                                                onMouseLeave={area => this.leaveArea(area)}
                                                onClick={area => this.clicked(area)}/>         
                                      </div>
                                  </Fade>:
                                  <Fade spy={this.state.bodyPosition} duration='0.8s' appear>
                                      <div className='body-container' style={{zIndex:'20'}} >
                                          <ImageMapper 
                                                src={this.state.bodyEspalda} 
                                                map={MAP[this.state.map][1]}
                                                fillColor={'rgba(255, 0, 0, 0.15)'}
                                                width={200} 
                                                height={500}
                                                onMouseEnter={area => this.enterArea(area)}
                                                onMouseLeave={area => this.leaveArea(area)}
                                                onClick={area => this.clicked(area)}/>         
                                      </div>
                                  </Fade>}
                                  <Tooltip 
                                    visibility={this.state.tooltip} 
                                    area={this.state.hoveredArea}
                                    position={this.state.tooltipPosition}
                                  />
                          <div className={this.state.cssTriggerArrow}>
                            <div className='arrow-der fade-in-right-short' onClick={()=>this.handleBodyPosition()}>
                            </div>
                            <p className='arrow-text fade-in-right-short'>GIRAR</p>
                          </div>
                      </div>
              <div className='emptyBlock'>    
              </div>
              <div className='atras-container' onClick = {()=> this.props.handleclick(0,0,2)}>
                <img src={arrow} />
                <p>Volver</p>
              </div>            
            </div>
      )
        }
        }
    if(this.state.screen === 1){
      this.zoomOutMobile()
      let timer = setTimeout(this.setState.bind(this, {screen:2}), 3000);
      return(
        <div className='primaryContainer'>
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div> 
        <div className='textBlock fade-in-right-short'>
            <div className='line'>       
                <p className='text-aurya '>Indícame por favor donde específicamente te duele en <span style={{fontWeight:'700'}}>{this.state.articulacionNombre}</span></p>
            </div>                                              
        </div>
        <div className='emptyBlock'>
            <p></p>
        </div>
        </div>
    )


    }
    console.log('props',this.props)
    if(this.state.screen === 2){
      return(
          <div className='container-zona-dolor'>
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div>    
              <div className='articulaciones-relacionadas'>
                <img src={flechaIzq} onClick = {()=>this.setState({articulacion:articulacion4.id})}/>
                <div className='articulacion-extremo' onClick = {()=>this.setState({articulacion:articulacion1.id})}>
                    {articulacion1.name}
                </div>
                <div className='articulacion' onClick = {()=>this.setState({articulacion:articulacion2.id})}>
                    {articulacion2.name}
                </div>
                <div className='articulacion-active' onClick = {()=>this.setState({articulacion:articulacion3.id})}>
                    {articulacion3.name}
                </div>
                <div className='articulacion' onClick = {()=>this.setState({articulacion:articulacion4.id})}>
                    {articulacion4.name}
                </div>
                <div className='articulacion-extremo' onClick = {()=>this.setState({articulacion:articulacion5.id})}>
                    {articulacion5.name}
                </div>
                <img src={flechaDer} onClick = {()=>this.setState({articulacion:articulacion2.id})} />
              </div>
              {respuestas.length < 5?
              <div className='zonas2 fade-in-left-slow'>
                <Fade left spy={this.state.articulacion} distance={'40px'} appear>
                   {respuestas}
                </Fade>
              </div>
              :
              <div className='zonas fade-in-left-slow'>
                <Fade left spy={this.state.articulacion} distance={'40px'} appear>
                  {respuestas}
                </Fade>
              </div>}
              <div className='atras-container' onClick={()=>this.setState({screen:0,textTrigger:0,tooltip:false})}>
                <img src={arrow} width='20px' />
                <p>Volver</p>
              </div>
          </div>
      )
      }     
    }
}

export default Articulacion