const dorsal = [
  {
  id:0,
  preg:'',
  nombre:'dorsal',
  respuestas:[{id:1,text:'Zona Lateral',trigger:1,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/dorsalLateral.jpg',masInfo:'Dolor en la parte costal'},
              {id:2,text:'Zona Inferior',trigger:2,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/dorsalInferior.jpg',masInfo:'Dolor en la parte baja de la espalda'},
              {id:3,text:'Zona interescapular',trigger:3,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/dorsalInterescapular.jpg',masInfo:'Dolor entre las escápulas/omóplatos'}],
  template:2,
}]

export default dorsal