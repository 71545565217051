import React, {Text} from 'react';
import './../../css/main.scss';

const lumbarZonaLumbar = [
    {
        id:0,
        preg:'¿Me podrías indicar en cuál de las siguientes imágenes se refleja mejor tu dolor?',
        respuestas:[{id:1,text:'Columna vertebral',masInfo:'El dolor se localiza en el recorrido de la columna',trigger:1,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/LUMBAR1.jpg'},
                    {id:2,text:'A los laterales de la columna',masInfo:'El dolor se localiza a los lados de la columna',trigger:1,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/LUMBAR1.2.jpg'},
                    {id:3,text:'Glúteos y sacro',masInfo:'El dolor se localiza inferior a la columna',trigger:1,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/LUMBAR1.3.jpg'}],
        template:4,
      },  
    { 
        id:1,
        preg: [ {text:'¿El dolor ha comenzado después de una caída o un traumatismo directo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/LUMBAR2.jpg'},
                {text:'¿Habías aumentado la actividad de la columna lumbar antes de que empezara el dolor?',
                    masInfo:{
                      state:true,
                      titulo:'Ver ejemplos',
                      text:'Actividades que impliquen agachar, levantar objetos con peso, o simplemente realizar más deporte'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/LUMBAR3.jpg'
                },
                {text:'Perfecto, ¿asocias el inicio del dolor a un gesto específico con inicio repentino?',
                    masInfo:{
                      state:true,
                      titulo:'Más información',
                      text:'El dolor ha comenzado en un movimiento específico como al levantarte, al realizar un giro, etc.'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/LUMBAR4.jpg'
                },
                {text:'¿Se reproduce tu dolor al realizar una hiperextensión de la columna lumbar?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/EXTENSIONLUMBAR.mp4'},
                {text:'¿Presentas rigidez de la columna lumbar en las primeras horas de la mañana o al levantarte de la silla?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Dificultad para mover la columna en las primeras horas de la mañana?'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/LUMBAR5.jpg'
                },
                {text:'¿Presentas sensación de hormigueo o adormecimiento en la pierna?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/LUMBAR6.jpg'},
                {text:'¿Se reproduce tu dolor al presionar en un punto de la zona lumbar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/LUMBAR/LUMBAR7.jpg'},
        ],  
        trigger:2,
        template:7,
      },

    {
      id:2,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:3,
    },
    {
      id:3,
      respuestas:[],
      template:8,
    },
]

export default lumbarZonaLumbar