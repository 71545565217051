import React,{Component} from 'react';
import '../css/preguntaConRespuestas.scss' 
import '../css/main.scss';
import scroll from './../img/scrollDown.gif'
import logo from './../img/logoAzulOscuro.png';
import Fade from 'react-reveal/Fade';
import arrow from '../img/arrow-izq.png'
import flecha from './../img/arrow-color.png'


class PreguntaConRespuestas extends Component{
    constructor(props){
        super(props)
        this.state={
            preguntaCss:'preguntaBlock',
            props:this.props
        }

    }
    componentDidMount(){
        let timer = setTimeout(this.setState.bind(this, {preguntaCss:'preguntaBlock-active'}), 3000);
  
      }
    componentWillUnmount(){
        clearTimeout(this.timer);
  
    }
    componentWillReceiveProps(){
        console.log('update')
        this.setState({preguntaCss:'preguntaBlock'})
        let timer = setTimeout(this.setState.bind(this, {preguntaCss:'preguntaBlock-active'}), 3000);
    }
    handleVolver = () => {
        this.props.handleclick(0,0,3, () => {
          this.props.handlearrayempty() 
      })}
      
    render(){
        console.log(this.props)
        const respuestas = this.props.preguntas.respuestas.map((resp) =>
            <div className='respuesta-block'>
                <div className="container-titulo-zona"  key={resp.id} onClick={()=> this.props.creararray(resp.asociacion,this.props.state.articulacion,this.props.state.zonaDolor,resp.trigger)}>
                    {resp.imagen == 'x'?
                        <video autoplay='autoplay' muted loop src={resp.video} className='imagen-zona' onClick={()=> this.props.creararray(resp.asociacion,this.props.state.articulacion,this.props.state.zonaDolor,resp.trigger)}  width='90%' height='80%' />
                        :
                        <img src={resp.imagen} className='imagen-zona' onClick={()=> this.props.creararray(resp.asociacion,this.props.state.articulacion,this.props.state.zonaDolor,resp.trigger)}  width='90%' height='80%'/>
                    }
                    <p className='titulo-parte'>{resp.text}</p>
                    <p className='texto-parte'>{resp.masInfo}</p>
                    <p className='elegir-2'>Elegir</p>
                    <img src={flecha} className='flecha' />
                </div>
            </div>)        
        return(
            <div className='primaryContainer'>
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div> 
                <img src={scroll} className='scroll-icon' />
          
            <div className={this.state.preguntaCss}>
                <div className='pregunta-container-fixed'>
                <Fade right duration={1000} delay={100} spy={this.props} appear distance={'17px'}>
                     <div className='line-preguntas' >                    
                        <p className='text-aurya-small'>{this.props.preguntas.preg}</p>
                      </div>
                      </Fade>
                </div>
            </div>
           
            
            <div className='imageBlock-asociacion fade-in-left'>
            <Fade bottom duration={1000} distance={'25px'} delay={1300} spy={this.props} appear>
                <div className='respuestas-container'>
                    {respuestas}
                </div>
            </Fade>           
            </div>
            <div className='atras-container' onClick={()=>this.handleVolver()}>
                <img src={arrow} width='20px' />
                <p>Volver</p>
            </div>
            </div>
        )
    }
}

export default PreguntaConRespuestas