import React, {Text} from 'react';
import './../../css/main.scss';

const pulgar = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída con mal apoyo o mal gesto del pulgar'},
                  {id:2,text:'Sobreuso',trigger:7,asociacion:1,masInfo:'Realizar movimientos repetitivos con el pulgar durante largos periodos de tiempo'},

                  {id:3,text:'Ha empezado con el tiempo',trigger:7,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:7,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  

    //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Cuál ha sido el grado de inflamación?',
        type:'inflamacion',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Y la intensidad del dolor al mover el pulgar después de la lesión?',
        type:'trauma',
        template:3,
        trigger:4,
    },
    {
        id:4,
        preg: [ 
            {text:'¿Se reproduce tu dolor al realizar agarres en pinza?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MU%C3%91ECA7.jpg'},
            {text:'¿Sientes un clic al mover la muñeca que causa dolor?',
                masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Un click es un crujido o crepitación al mover la muñeca'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MU%C3%91ECA1.jpg'
            },
            {text:'¿Y se reproduce tu dolor al presionar en la base del pulgar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MU%C3%91ECA3.jpg'},
            {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
            {text:'Por último, ¿has presentado sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MU%C3%91ECA5.jpg'},
], 
        trigger:5,
        template:7,
    },
    {
        id:5,
        preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
        respuestas:[],
        template:9,
        trigger:6,
    },
    {
        id:6,
        respuestas:[],
        template:8,
    },
    
    //TRAUMA NO
    {
        id:7,
        preg:[ 
            {text:'¿Presentas sensación de hormigueo o parestesias en los 1º,2º y 3º dedos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MU%C3%91ECA11.jpg'},
            {text:'¿Y disminución de la sensibilidad en el dorso de la mano?',
                masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Falta de capacidad para sentir presión, toque, calor o frío'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MANODORSAL1.jpg'
            },
            {text:'¿Presentas rigidez del pulgar después de periodos de descanso o inactividad: por ejemplo, por la mañana al levantarse?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/PULGAR1.jpg'},
            {text:'¿Se reproduce tu dolor realizar agarres en pinza?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MU%C3%91ECA7.jpg'},
            {text:'¿Y al separar el pulgar respecto a los otros dedos contra resistencia?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/PULGAR/MU%C3%91ECA9.mp4'},
            {text:'Por último, ¿se reproduce tu dolor al realizar el test de Finkelstein?',
                masInfo:{
                    state:true,
                    titulo:'Ver test',
                    text:'Realiza un movimiento de muñeca hacia fuera con el puño cerrado y con el pulgar metido entre los dedos'
                },
                imagen:'x',
                video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MANO/Finkelstein.mp4'
            }
        ], 
        template:7,
        trigger:8,
    },
    {
      id:8,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:9,
    },
    {
      id:9,
      respuestas:[],
      template:8,
    }]

export default pulgar