import React,{Component} from 'react';
import Slider, { Range } from 'rc-slider'; 
import 'rc-slider/assets/index.css';
import '../css/cuantoDuele.scss';
import traumaLeve from '../img/leve.png';
import traumaModerado from '../img/moderado.png';
import traumaIntenso from '../img/intenso.png';
import logo from './../img/logoAzulOscuro.png';
import iconArrow from './../img/arrow-icon.png';
import Fade from 'react-reveal/Fade';
import arrow from '../img/arrow-izq.png'



class CuantoDuele extends Component{

    constructor(props) {
        super(props);
        this.state = {
          foto: traumaLeve,
          min: 1,
          max: 10,
          value: 1,
          intensidad:0,
          intensidadTitulo:'Leve',
          intensidadTexto:0,
          cssIntensidad:'dolor-titulo',
          reRender:1,
          preguntaCss:'preguntaBlock-cuanto-duele'
        };
      }

    onSliderChange = (value) => {
      console.log('iuju')
        this.setState({value});
        {this.state.value < 40 ? this.handledolor(0): this.state.value <70 ? this.handledolor(1):this.state.value < 92 ? this.handledolor(2):this.handledolor(3)}
    }

    componentDidMount(){
      let timer = setTimeout(this.setState.bind(this, {preguntaCss:'preguntaBlock-cuanto-duele-active'}), 3000);
    }

    componentWillUnmount(){
      clearTimeout(this.timer);
    }

    componentWillReceiveProps(){
      console.log('update')
      this.setState({preguntaCss:'preguntaBlock-cuanto-duele'})
      let timer = setTimeout(this.setState.bind(this, {preguntaCss:'preguntaBlock-cuanto-duele-active'}), 3000);
    }

    handledolor=(intensity)=>{
      this.setState({intensidad:intensity});
      console.log('intensidad',this.state.intensidad, 'instensity',this.state.intensity)
      if(intensity===0){
        console.log('in0')
        this.setState({intensidadTitulo:'Leve ',cssIntensidad:'dolor-titulo', intensidadTexto:0,foto:traumaLeve});
        console.log('moderado',this.state.Leve)
      }
      if(intensity===1){
        console.log('in1')
        this.setState({intensidadTitulo:'Moderado ',cssIntensidad:'dolor-titulo moderado',intensidadTexto:1,foto:traumaModerado});
        console.log('moderado',this.state.intensidadTitulo)
      }
      if(intensity===2){
        console.log('in2')
        this.setState({intensidadTitulo:'Intenso ',cssIntensidad:'dolor-titulo intenso',intensidadTexto:2,foto:traumaIntenso});
        console.log('intenso',this.state.intensidadTitulo)
      }
      if(intensity===3){
        this.setState({intensidad:2,intensidadTitulo:'Muy Intenso',cssIntensidad:'dolor-titulo intenso',intensidadTexto:3,foto:traumaIntenso});
        console.log('intenso',this.state.intensidadTitulo)
      }
    }

    handleSiguiente = () =>{
      this.setState({
        value:1,
    }, ()=>this.onSliderChange(1),
      this.props.creararray(this.state.intensidad,this.props.state.articulacion,this.props.state.zonaDolor,this.props.preguntas.trigger)
    )
    }
    
    handleclick = () => {
        this.props.handleclick(this.props.state.articulacion,this.props.state.zonaDolor,this.props.preguntas.trigger)
    }

    handleVolver = () => {
      this.props.handleclick(0,0,3, () => {
        this.props.handlearrayempty() 
    })}

    render(){
      const textoEjemplosTrauma =['Un dolor leve y aguantable','Un dolor fuerte pero que puedes aguantar','Un dolor fuerte que cuesta aguantar','El dolor más fuerte que hayas sentido jamas']
      const textoEjemplosInflamacion =['Una leve inflamación que apenas se nota','Una inflamación moderada que se observa facilmente','Una gran inflamación','Una inflamación extremadamente grande']
      const textoEjemplos = [textoEjemplosTrauma,textoEjemplosInflamacion]
      var textoEjemplosSelector = 0;

      this.props.preguntas.type === 'inflamacion' ?  textoEjemplosSelector = 1 : textoEjemplosSelector = 0
      
      const textoEjemplosElegido = textoEjemplos[textoEjemplosSelector]

        return(
          <div className='primaryContainer'>
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div>  
            <div className={this.state.preguntaCss}>
                <div className='pregunta-container-cuanto-duele fade-in-right-short'>
                <Fade right duration={1000} distance={'20px'} spy={this.props} appear>
                    <div className='line-preguntas'>                   
                        <p className='text-aurya-small'>{this.props.preguntas.preg}</p>
                    </div>
                </Fade>         
                </div>
            </div>
            <Fade duration={500} delay={1200} spy={this.props.preguntas.trigger} appear>
            <div className='imageBlock-cuanto-duele'>
                  <div className='foto-dolor-container'>
                    <p className='intensidad'>Intensidad:</p>
                      <img src={this.state.foto} className='imagen-cuanto-duele'></img>
                      <p className={this.state.cssIntensidad}>{this.state.intensidadTitulo}</p>
                      <p className={this.state.cssIntensidad}>({this.state.value})</p>                                    
                  </div>
                  <p className='dolor-text'>{textoEjemplosElegido[this.state.intensidadTexto]}</p> 
                  <div className='slider-container'>
                    {console.log(this.state.value)}
                           <Slider
                              value={this.state.value}
                              marks={{0: '0',100:'100'}}                             
                              onChange={this.onSliderChange}
                              onAfterChange={this.onSliderChange}
                              className='slider'                           
                            />

                  </div>
                  <div className='boton-container'>
                        <div className="boton-continuar" onClick={()=>this.handleSiguiente()}>
                          <p className='continuar-text'>Aceptar</p>
                          <img src={iconArrow} width='20px' height='20px'/>
                        </div>
                  </div>           
            </div>
            </Fade>
            <div className='atras-container' onClick={()=>this.handleVolver()}>
                  <img src={arrow} width='20px' />
                  <p>Volver</p>
            </div>
          </div>
         
        )
    }
}

export default CuantoDuele