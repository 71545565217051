import React from 'react';
import MouseTooltip from 'react-sticky-mouse-tooltip';

class Tooltip extends React.Component {
  state = {
    isMouseTooltipVisible: false,
  };

  toggleMouseTooltip = () => {
    this.setState(prevState => ({ isMouseTooltipVisible: !prevState.isMouseTooltipVisible }));
  };

  render() {
      console.log(this.props)
    return (
      <div>
        <MouseTooltip
          visible={this.props.visibility}
          offsetX={this.props.position[0]}
          offsetY={this.props.position[1]}
          className={'tooltip'}
        >
            {this.props.area}
        </MouseTooltip>
      </div>
    );
  }
}

export default Tooltip

