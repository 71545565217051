const tobillo = [
  {
  id:0,
  preg:'',
  nombre:'Tobillo',
  respuestas:[{id:1,text:'Parte Externa',trigger:1, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/tobilloExterna.jpg',masInfo:'Dolor en la parte de fuera del tobillo'},
              {id:2,text:'Parte Anterior',trigger:2, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/tobilloAnterior.jpg',masInfo:'Dolor en la parte de delante del tobillo'},
              {id:3,text:'Parte Interna',trigger:3, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/tobilloInterna.jpg',masInfo:'Dolor en la parte de dentro del tobillo'},
              {id:4,text:'Parte Posterior',trigger:4, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/tobilloPosterior.jpg',masInfo:'Dolor en la parte de atrás del tobillo'}],
  template:2,
}]

export default tobillo