import React, {Text} from 'react';
import './../../css/main.scss';

const hombroPosterior = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe o mal gesto del hombro'},
                  {id:2,text:'Una caída',trigger:7,asociacion:1,masInfo:'El inicio del dolor se asocia con una caída'},
                  {id:3,text:'Un sobresfuerzo',trigger:14,asociacion:2,masInfo:'Realizar un esfuerzo excesivo o más de lo habitual con el hombro'},                   
                  {id:4,text:'Ha empezado con el tiempo',trigger:14,asociacion:2,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:5,text:'No lo sé',trigger:14,asociacion:2,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
             //TRAUMA SI

    {
      id:1,
      preg:'¿Me podrías indicar el grado de traumatismo recibido?',
      type:'trauma',
      template:3,
      trigger:2,
  },   
  {
      id:2,
      preg:'¿Me podrías señalar el grado de inflamación que has presentado?',
      type:'inflamacion',
      template:3,
      trigger:3,
  },       
  {
      id:3,
      preg:'¿Y la intensidad del dolor al mover el hombro después del traumatismo?',
      type:'trauma',
      template:3,
      trigger:4,
  },
    {
      id:4,
      preg: [ 
              {text:'¿Asocias el inicio del dolor a un gesto específico o movimiento explosivo?',
                masInfo:{
                  state:true,
                  titulo:'Ver ejemplos',
                  text:'Un mal gesto al realizar un lanzamiento, levantar peso, etc.'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/POSTERIOR/LANZAMIENTOREPETIDO.jpg'
              },
              {text:'¿Llevas más de un mes con tu dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
              {text:'¿Se reproduce tu dolor al presionar sobre la clavícula?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'Realiza una presión en la clavícula hacia el suelo'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBROCLAVICULA.jpg'
              },   
              {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
              {text:'¿Has sentido como si el brazo ha salido y ha entrado de su sitio?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Sensación de desplazamiento inusual del hombro (subluxación del hombro)'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBRO1.jpg'
              },   
              {text:'Por último, ¿después del traumatismo has tenido inmovilizado el hombro durante un periodo largo de tiempo?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'¿Has usado cabestrillo de hombro durante 30 días?'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBROINMOVILIZADO.jpg'
              },    
    ],
      trigger:5,
      template:7,
    },

  {
    id:5,
    preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
    respuestas:[],
    template:9,
    trigger:6,
  },
  {
    id:6,
    respuestas:[],
    template:8,
  },
            //CAIDA SI

      {
        id:7,
        preg:'¿Me podrías indicar el grado de traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:8,
    },
    
    {
        id:8,
        preg:'¿Cuál ha sido el grado de inflamación?',
        type:'inflamacion',
        template:3,
        trigger:9,
    },
        
    {
        id:9,
        preg:'¿Y la intensidad del dolor al mover el hombro?',
        type:'trauma',
        template:3,
        trigger:10,
    },
    {
        id:10,
        preg:'¿Cómo ha sido la caída que asocias con el inicio del dolor?',
        respuestas:[{id:1,text:'Con el brazo extendido',masInfo:'Caída con el brazo separado del cuerpo',trigger:11,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/CAIDAEXTENSION.jpg'},
                    {id:2,text:'Con el brazo pegado al cuerpo (aducción)',masInfo:'Caída con el brazo pegado al cuerpo',trigger:11,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/CAIDAADD.jpg'},
                    {id:3,text:'Ninguna de las anteriores',masInfo:'La caída ha sido de otra manera',trigger:11,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/CAIDANINGUNA.jpg'}],
        template:4,
      },  

      {
        id:11,
        preg: [ 
          {text:'¿Llevas más de un mes con tu dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Se reproduce tu dolor al presionar sobre la clavícula?',
            masInfo:{
              state:true,
              titulo:'Más información',
              text:'Realiza una presión en la clavícula hacia el suelo'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBROCLAVICULA.jpg'
          },   
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Has sentido como si el brazo ha salido y ha entrado de su sitio?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Sensación de desplazamiento inusual del hombro (subluxación del hombro)'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBRO1.jpg'
          },   
          {text:'Por último, ¿después del traumatismo has tenido inmovilizado el hombro durante un periodo largo de tiempo?',
            masInfo:{
              state:true,
              titulo:'Más información',
              text:'¿Has usado cabestrillo de hombro durante 30 días?'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBROINMOVILIZADO.jpg'
          },    
    ],
      trigger:12,
      template:7,
    },

    {
      id:12,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:6,
    },
    {
      id:13,
      respuestas:[],
      template:8,
    },
    
    //TRAUMA NO
    {
      id:14,
      preg:'¿Me podrías indicar si se reproduce tu dolor al realizar alguno de los siguientes movimientos?',
      respuestas:[{id:1,text:'Abducción',masInfo:'Separar el brazo lateralmente del cuerpo',trigger:15,asociacion:0,imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ABDHOMBRO.mp4'},
                  {id:2,text:'Flexión',masInfo:'Elevar el brazo anteriormente al cuerpo',trigger:15,asociacion:1,imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/FLEXHOMBRO.mp4'},
                  {id:2,text:'En ambos',masInfo:'Dolor al realizar los dos movimientos', trigger:15,asociacion:2,imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/AMBOS.mp4'},
                  {id:2,text:'No se reproduce mi dolor',masInfo:'No hay presencia del dolor en ninguno de los movimientos',trigger:15,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'}],
      template:4,
    },
    {
      id:15,
      preg:'¿Asocias el inicio del dolor a un movimiento repentino o ha ido aumentando progresivamente?',
      respuestas:[{id:1,text:'Inicio repentino',masInfo:'El dolor ha empezado al realizar un movimiento o gesto',trigger:16,asociacion:0},
                  {id:2,text:'Inicio progresivo',masInfo:'El inicio del dolor no se asocia a ningún gesto en específico',trigger:16,asociacion:1}],
      template:10,
    },
    {
      id:16,
      preg: [ 
        {text:'¿Se reproduce tu dolor al realizar el Empty can test?',
          masInfo:{
            state:true,
            titulo:'Ver test',
            text:'-Levanta el brazo a la altura del hombro -Abre el brazo 60º hacia fuera -Coloca el pulgar mirando hacia el suelo -Mantén el brazo recto -Realiza presión en dirección al suelo con la otra mano e intenta mantener la posición'
          },
          imagen:'x',
          video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/EMPTYTEST.mp4'
        },
        {text:'¿Realizas actividades repetitivas con el hombro o con los brazos por encima de la cabeza?',
            masInfo:{
              state:true,
              titulo:'Ver ejemplos',
              text:'Oficios como carpinteros, pintores, cadenas de montaje, etc. o deportes como natación, béisbol, gimnasio, escalada, etc.'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CODO/ANTERIOR/CODOANTERIOR4.jpg'
        },
        {text:'¿Presentas sensación de rigidez al mover el hombro?',
            masInfo:{
              state:true,
              titulo:'Más información',
              text:'La sensación de rigidez de hombro se relaciona con la percepción de falta de movilidad en el hombro'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBRORIGIDEZ(PHOTOSHOP).jpg'
        }, 
        {text:'¿Y hormigueos o entumecimiento en el brazo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBROHORMIGUEO.jpg'}, 
        {text:'¿El dolor se irradia hacia la mano?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/IRRADIADOMANO.jpg'},
        {text:'¿Presentas cambios de color o palidez en la mano?',
            masInfo:{
              state:true,
              titulo:'Más información',
              text:'La palidez se refiere a la pérdida del color de la piel normal'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/BRAZOANTERIOR6.jpg'
        },
       {text:'¿Has sentido un pop notable o audible en el momento de la lesión?',
          masInfo:{
            state:true,
            titulo:'¿Qué significa pop?',
            text:'Pop significa un chasquido o una sensación de desgarro'
          },
          imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBROPOP.jpg'
       },
       {text:'Por último, ¿Has tenido inmovilizado el hombro durante un periodo largo de tiempo?',
          masInfo:{
            state:true,
            titulo:'Más información',
            text:'¿Has usado cabestrillo de hombro durante 30 días?'
          },
          imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/ANTERIOR/HOMBROINMOVILIZADO.jpg'
       },    
  ],
      trigger:17,
      template:7,
  },

  {
    id:17,
    preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
    respuestas:[],
    template:9,
    trigger:18,
  },
  {
    id:18,
    respuestas:[],
    template:8,
  }]

export default hombroPosterior