const muslo = [
  {
  id:0,
  preg:'',
  nombre:'muslo',
  respuestas:[{id:1,text:'Parte posterior',trigger:1,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/musloPosterior.jpg',masInfo:'Dolor en la parte de atrás del muslo'},
              {id:2,text:'Parte medial',trigger:2,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/musloInterna.jpg',masInfo:'Dolor en la parte de dentro del muslo'},
              {id:3,text:'Parte lateral',trigger:3,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/musloLateral.jpg',masInfo:'Dolor en la parte de fuera del muslo'},
              {id:4,text:'Parte anterior',trigger:4, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/musloAnterior.jpg',masInfo:'Dolor en la parte de delante del muslo'}],
  template:2,
}]

export default muslo