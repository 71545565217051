import React,{Component} from 'react';
import '../css/edad.scss';
import '../css/main.scss';
import iconFemale from './../img/iconFemale.png'
import iconMale from './../img/iconMale.png'
import iconArrow from './../img/arrow-icon.png';
import logo from './../img/logoAzulOscuro.png'


class Input extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        active: (props.locked && props.active) || false,
        value: props.value || "",
        error: "",
        label: props.label || "Label"
      };
    }
  
    changeValue(event) {
      const value = event.target.value;
      this.setState({ value,error:''});
      if (value>120 || value<0){
        
        this.setState({ value, error: this.props.errortext });
      }
      
      this.props.handleChange(value,this.state.error)
    }
  
    handleKeyPress(event) {
      if (event.which === 13) {
        if(this.state.error === ''){
          this.props.handleEnter()
        }
    
      }
    }
  
    render() {
      const { active, value, error, label } = this.state;
      const { locked } = this.props;
      const fieldClassName = `field ${(locked ? active : active || value) &&
        "active"} ${locked && !active && "locked"}`;
  
      return (
        <div className={fieldClassName} spellCheck="false">
          <input
            id={1}
            autoComplete="off"
            type={this.props.inputType}
            placeholder={label}
            value={value}
            error='true'
            onChange={this.changeValue.bind(this)}
            onKeyPress={this.handleKeyPress.bind(this)}
            onFocus={() => !locked && this.setState({ active: true })}
            onBlur={() => !locked && this.setState({ active: false })}
          />
          <label htmlFor={1} className={error && "error"}>
            {error || label}
          </label>
        </div>
      );
    }
  }


class EdadSexo extends Component{
    constructor(props){
        super(props)
        this.state={
            nombre:'',
            edad:0,
            genero:0,
            error:'',
            numeroPregunta:0,
            cssTriggerText:'line',
            cssTriggerInput:'edadNormal',
            cssBotonContinuar:'display-none',
            cssExplain:true,
            cssText:true,

        }
        this.handleChangeNombre = this.handleChangeNombre.bind(this);
        this.handleChangeEdad = this.handleChangeEdad.bind(this);
        this.handleGenero = this.handleGenero.bind(this);
    }

    
    
    handleChangeNombre = (value,error)=> {
        this.setState({
        nombre:value,
        error:error
    })
console.log(this.state.nombre)}

    handleChangeEdad = (value,error)=> {
        this.setState({
        edad:value,
        error:error
    })
    console.log(this.state.edad)
    }
    handlePreguntas(){
        if (this.state.numeroPregunta < 2){
            this.setState({
                numeroPregunta:this.state.numeroPregunta +1
            })
        }
        else{
            this.props.handleEpidemiologia(0,0,this.props.preguntas.trigger,this.state.edad,this.state.genero,this.state.nombre)
        }

    }
    handleEnter = () =>{
      if(this.state.nombre != '' && this.state.edad != ''){
          this.setState({
          numeroPregunta:1
      })
    }


    }
    handleCirculos(numero){
      this.props.handleEpidemiologia(0,0,this.props.preguntas.trigger,this.state.edad,this.state.genero)

    }
    handleNext =()=>{     
      this.props.handleEpidemiologia(0,0,this.props.preguntas.trigger,this.state.edad,this.state.genero,this.state.nombre)

    }
    handleGenero = () =>{
      this.setState({
        genero: 1,
    }, () => {
        this.handleNext()
    })
      this.setState({genero:1});
      if (this.state.genero ==1){
        this.handleNext()
      }
    }
    componentDidMount(){
 
      let timer = setTimeout(this.setState.bind(this, {cssTriggerText:'lineActive'}), 3000);
      let timer2 = setTimeout(this.setState.bind(this, {cssTriggerInput:'edadActive'}), 5000);

    }

    componentDidUpdate(){
      
      if(this.state.nombre != '' && this.state.edad != '' &&  this.state.edad<120 && this.state.edad > 0 && this.state.cssBotonContinuar !='boton-continuar fade-in-fast'){
        this.setState({cssBotonContinuar:'boton-continuar fade-in-fast'})
    }
      if(this.state.nombre == '' || this.state.edad == '' || this.state.edad>120 || this.state.edad<0){
        if(this.state.cssBotonContinuar != 'display-none'){
          this.setState({cssBotonContinuar:'display-none'})
        }      
  }

    }

    componentWillUnmount(){
      clearTimeout(this.timer);

    }
    render(){
      console.log(this.state)
      var visible=''
      var cssText=''
      this.state.cssText? cssText = 'textExplain' : cssText = 'textExplain-active'
      this.state.cssExplain? visible = 'none-explanation' : visible='visible-explanation'
      const preguntas =[<div className='edad-template'> 
                
                
      <div className='input-container fade-in-left'>
          <p className='titulo-input nombre'>Nombre</p>
          <Input
                id={1}
                errortext=''
                handleEnter = {this.handleEnter}
                inputType='text'
                label="¿Cómo te llamas?"
                predicted=""
                locked={false}
                active={false}
                handleChange={this.handleChangeNombre}
            />
      </div>
      <div className='input-container fade-in-left'>
              <p className='titulo-input edad'>Edad</p>    
              <Input
                id={1}
                label="¿Qué edad tienes?"
                errortext='Edad no válida'
                inputType='number'
                predicted=""
                handleEnter = {this.handleEnter}
                locked={false}
                active={false}
                handleChange={this.handleChangeEdad}
              /> 
            <div className='container-boton'>
              <div className={this.state.cssBotonContinuar} onClick={() => this.handlePreguntas() }>
                    <p className='continuar-text'>Continuar</p>
                    <img src={iconArrow} width='20px' height='20px'/>
              </div>
            </div>
      </div>
            </div>,
      <div className='edad-template fade-in-left'>
          <div className='generoContainer'>
                <p className='titulo-input genero'>Género</p>
                    <div className='button-mujer' onClick={()=>this.handleNext()}>
                      <img src={iconMale} className='iconGenero'></img>
                      Hombre
                    </div>
                    <div className='button-hombre' onClick={()=>this.handleGenero()}>
                      <img src={iconFemale} className='iconGenero'></img>
                      Mujer
                    </div>
                    <p className={cssText} onClick={()=>this.setState({cssExplain:!this.state.cssExplain, cssText:!this.state.cssText})}>¿Por qué solo estas dos opciones?</p>
                    <p className={visible}>Sé que existen más de dos identidades de género, pero por ahora solo puedo diferenciar entre estas dos. Desconozco el impacto de otras identidades de género en las patologias.<br></br><br></br> Introduce el género que te asignarón al nacer para que podamos continuar.</p>
          </div> 
      </div>

                               ]
        return(
          
            <div className='primaryContainer'>
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div> 
                <div className='textBlock'>
                         <div className={this.state.cssTriggerText}>
                                <p className='text-aurya'>Primero necesito <span style={{fontWeight:'700'}}>saber</span> un poco sobre ti</p>
                        </div>                                                
                        <div className={this.state.cssTriggerInput}>{preguntas[this.state.numeroPregunta]}</div>


                    

                </div>
                <div className='emptyBlock'>
                </div>
                
             
          
        
        </div>

          
   
    
 
              


               


               


            
        )
    }
}

export default EdadSexo