import React, {Text} from 'react';
import './../../css/main.scss';

const pantorrillaAnterior = [

  {
    id:0,
    preg:'¿Con qué asocias tu lesión?',
    respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe o mal gesto'},
                {id:2,text:'Un sobresfuerzo',trigger:6,asociacion:1,masInfo:'Realizar un esfuerzo excesivo o más de lo habitual'},
                {id:3,text:'Ha empezado con el tiempo',trigger:6,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                {id:4,text:'No lo sé',trigger:6,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
    template:10,
  },
      //TRAUMA SI
    {
      id:1,
      preg:'¿Me podrías indicar la intensidad del traumatismo?',
      respuestas:[{id:1,text:'Sí, un traumatismo leve',masInfo:'He recibido un golpe débil',trigger:2,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                  {id:2,text:'Sí, un traumatismo moderado',masInfo:'He tenido un golpe importante',trigger:2,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/moderado.jpg'},
                  {id:3,text:'Sí, un traumatismo intenso',masInfo:'He tenido uno de los peores golpes que haya tenido nunca',trigger:2,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'}],
      template:4,
    },
    {
      id:2,
      preg:'¿Has presentado hinchazón o inflamación de la pantorrilla?',
      respuestas:[{id:1,text:'No', masInfo:'No he presentado ningún hinchazon',trigger:3,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'},
                  {id:2,text:'Sí, un hinchazón leve',masInfo:'Creo que puede estar un poco hinchada', trigger:3,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                  {id:1,text:'Sí, un hinchazón moderado',masInfo:'Se nota que está hinchada',trigger:3,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/moderado.jpg'},
                  {id:1,text:'Sí, un hinchazón grande',masInfo:'El hinchazón es muy grande ', trigger:3,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'}],
      template:4,
  }, 
    {
        id:3,
        preg: [
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'Antes del dolor, ¿realizabas actividad física que implicaba correr o saltar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/SPRINTREPETIDODEFINITVO.jpg'},
          {text:'¿Habías aumentado tu volumen de entrenamiento antes de lesionarte?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'Habías aumentado la actividad física o de trabajo los días-semanas antes de la lesión'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'
          },
          {text:'¿El dolor se extiende al menos 5 centímetros por la pantorrilla?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'El dolor se refiere 5 centímetros hacia abajo'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/ANTERIOR/PANTORRILLAANTERIOR1.jpg'
          },
          {text:'¿Has presentado incapacidad para apoyar el peso de tu cuerpo en el momento de la lesión?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Resulta imposible apoyar tu peso en el pie afectado debido al dolor'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/ANTERIOR/PANTORRILLAANTERIOR2.jpg'
          },
], 
        trigger:4,
        template:7,
      },

    {
      id:4,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:5,
    },
    {
      id:5,
      respuestas:[],
      template:8,
    },
    //TRAUMA NO
    {
        id:6,
        preg: [
          {text:'¿Llevas más de un mes con los síntomas?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'Antes del dolor, ¿realizabas actividad física que implicaba correr o saltar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/SALTO.jpg'},
          {text:'¿Habías aumentado tu volumen de actividad física antes de lesionarte?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'Habías aumentado la actividad física o las distancias al caminar en los días/semanas previos a la lesión'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/SPRINTREPETIDODEFINITVO.jpg'
          },
          {text:'¿Y has realizado alguna actividad nueva o poco frecuente que pueda ser la causa del dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
          {text:'¿Has presentado una inflamación en la pierna?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/ANTERIOR/PANTORRILLAANTERIOR4.jpg'},
          {text:'Por último, ¿el dolor se extiende al menos 5 centímetros por la pantorrilla?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'El dolor se refiere 5 centímetros en la pantorrilla'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PANTORRILLA/ANTERIOR/PANTORRILLAANTERIOR1.jpg'
          },
      ], 
        trigger:7,
        template:7,
      },

      {
        id:7,
        preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
        respuestas:[],
        template:9,
        trigger:8,
      },
      {
        id:8,
        respuestas:[],
        template:8,
      }
  
  ]

export default pantorrillaAnterior