import React,{Component} from 'react';
import './../css/main.scss';
import intrologo from './../img/intrologo.png';
import aurya from './../img/aurya.png';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade'; 
import logo from './../img/logoAzulOscuro.png';
import './../css/animate.scss';

class Introduccion extends Component{
    constructor(props){
        super(props);
        this.state = {
            cssLogo:'logo-introduccion',
            cssLogoText:'aurya-introduccion',
            cssNombre:'fisify-diagnosis',
            cssSubtitle:'intro-sub',
            cssBG:'introBG',
            cssContainer:'nombre-introduccion'
        }
    }

    componentDidMount(){
        let timer = setTimeout(this.props.handleclick.bind(this,0,0,1), 5500);
        let timer2 = setTimeout(this.setState.bind(this,{cssContainer:'nombre-introduccion-no-display'}), 4300);
        let timer3 = setTimeout(this.setState.bind(this,{cssBG:'BGNoDisplay'}), 5000);
    }
    render(){
        return(
           
            <div className='primaryContainer'>
                 <Fade delay={4600}>
                    <div className='logo'>
                        <img src={logo} width='115px' height='40px'/>
                    </div>
                </Fade>
                <div className={this.state.cssBG}>         
                    <div className={this.state.cssContainer}>
                        <Fade top distance={'10px'}>  
                        <img src={intrologo} className={this.state.cssLogo} /><br></br>
                        <img src={aurya} className={this.state.cssLogoText} />                            
                            <p className={this.state.cssSubtitle}>
                                <Flip left cascade delay={1700}>
                                · FISIFY TECHNOLOGY ·
                                </Flip>
                            </p>
                        </Fade>
                    </div>             
                </div>
            </div>
        )
    }
}

export default Introduccion