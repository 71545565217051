import React from 'react';

class SubmitTemplate extends React.Component{

    render(){
        return(
            <button onClick={this.props.handleclick(this.props.state.articulacion,this.props.state.zonaDolor,this.props.preguntas.trigger)}>Ver Resultados</button>
        )
    }

}

export default SubmitTemplate