import React, {Text} from 'react';
import './../../css/main.scss';

const lumbarIrradiadoPie = [
    {
        id:0,
        preg:'¿Me podrías indicar desde que zona se irradia el dolor?',
        respuestas:[{id:1,text:'Lumbar',masInfo:'El dolor se origina desde la columna',trigger:1,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/LUMBARIRRADIADOPIE1.jpg'},
                    {id:2,text:'Glúteo',masInfo:'El dolor se origina desde la zona glútea',trigger:1,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/LUMBARIRRADIADOPIE1.2.jpg'}
                  ],
        template:4,
      },
      {
        id:1,
        preg:'¿Desde el lumbar hasta donde se te irradia?',
        respuestas:[{id:1,text:'Gemelo',masInfo:'Se irradia hasta la pantorrilla',trigger:3,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/LUMBARIRRADIADOPIE2.2.jpg'},
                    {id:2,text:'Pie',masInfo:'Se irradia hasta el pie',trigger:3,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/LUMBARIRRADIADOPIE2.jpg'}],
        template:4,
      },
      {
        id:2,
        preg:'¿Desde el glúteo hasta donde se te irradia?',
        respuestas:[{id:1,text:'Gemelo',masInfo:'Se irradia hasta la pantorrilla',trigger:3,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/LUMBARIRRADIADOPIE3.2.jpg'},
                    {id:2,text:'Pie',masInfo:'Se irradia hasta el pie',trigger:3,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/LUMBARIRRADIADOPIE3.jpg'}
                  ],
        template:4,
      },    
    { 
        id:3,
        preg: [
          {text:'Perfecto, ¿se reproduce tu dolor al realizar el test de Slump?',
            masInfo:{
              state:true,
              titulo:'Ver test',
              text:'· Sentarse en la esquina de una mesa o silla · Dejar el cuerpo relajado con una inclinación anterior · Agachar la cabeza llevando la barbilla al pecho · Extender la rodilla de la pierna afectada · Llevar la punta del pie hacia nosotros'
            },
            imagen:'x',
            video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/TESTSLUMP.mp4'
          },
          {text:'¿Has presentado adormecimiento o sensación de hormigueo en la pierna?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/LUMBAR6.jpg'},
          {text:'¿Y sensación de debilidad en la pierna?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/MUSLODEBILIDAD.jpg'},
          {text:'¿Has presentado sensación de dolor eléctrico?',
            masInfo:{
              state:true,
              titulo:'Más información',
              text:'Un dolor eléctrico es de un dolor punzante (como si tuvieses agujas)'
            },
            imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/MUSLOELECTRICO.jpg'
          },
          {text:'Por último, ¿los síntomas se agravan al estornudar o al toser?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/LUMBAR/IRRADIADO+AL+PIE/LUMBARIRRADIADO5.jpg'},
    ],
      trigger:4,
      template:7,
    },

    {
      id:4,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:5,
    },
    {
      id:5,
      respuestas:[],
      template:8,
    },
]

export default lumbarIrradiadoPie