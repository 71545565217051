const hombro = [
  {
  id:0,
  preg:'',
  nombre:'Hombro',
  respuestas:[{id:1,text:'Parte Anterior',trigger:1, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/hombroAnterior.jpg',masInfo:'Dolor en la parte de delante del hombro'},
              {id:2,text:'Parte Posterior',trigger:2, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/posteriorHombro.jpg',masInfo:'Dolor en la parte de atrás del hombro'},
              {id:3,text:'Parte Lateral',trigger:3, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/lateralHombro.jpg',masInfo:'Dolor en la parte de fuera del hombro'},
              {id:4,text:'Parte Superior',trigger:4, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/hombroSuperior.jpg',masInfo:'Dolor en la parte de arriba del hombro'},
              {id:5,text:'Irradiado al brazo',trigger:5, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/hombroIrradiado.jpg',masInfo:'Dolor de hombro que se irradia al brazo'},
              {id:6,text:'Difuso',trigger:6, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/HOMBRO/hombroDifuso.jpg',masInfo:'Dolor más general y sin localizar'}],
  template:2,
}]

export default hombro