import React, {Text} from 'react';
import './../../css/main.scss';

const dorsalLateral = [
    {
      id:0,
      preg:'¿Me podrías indicar si has recibido un traumatismo directo sobre las costillas?',
      respuestas:[{id:1,text:'No',masInfo:'No he recibido ningún golpe',trigger:1,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'},
                  {id:2,text:'Si, un traumatismo leve',masInfo:'He recibido un golpe débil',trigger:1,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                  {id:2,text:'Si, un traumatismo moderado',masInfo:'He tenido un golpe importante',trigger:1,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/moderado.jpg'},
                  {id:2,text:'Si, un traumatismo intenso',masInfo:'He tenido uno de los peores golpes que haya tenido nunca',trigger:1,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'}],
      template:4,
    },  
    {
        id:1,
        preg:'¿Y me podrías señalar la intensidad del dolor al presionar sobre la zona?',
        respuestas:[{id:1,text:'Soportable',masInfo:'Un dolor tolerable o que puedes aguantar',trigger:2,asociacion:0},
                    {id:2,text:'Insoportable',masInfo:'Un dolor inaguantable',trigger:2,asociacion:1}],
        template:10,
      },  
    { 
        id:2,
        preg: [ {text:'¿Llevas más de dos meses con los síntomas?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/TIEMPOREPETIDO.jpg'},
                {text:'¿Realizas actividades físicas que implican movimientos de los miembros superiores?',
                  masInfo:{
                    state:true,
                    titulo:'Ver ejemplos',
                    text:'Actividades deportivas que impliquen levantamiento de pesas, escalada, natación, etc.'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL3.jpg'
                },   
                {text:'¿Presentas dolor al respirar profundamente?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL7.jpg'},
                {text:'¿El dolor es agudo y localizado en las costillas?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL8.jpg'},
                {text:'Por último, ¿el dolor se irradia hacia el brazo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL4.jpg'},
],
        trigger:3,
        template:7,
      },

    {
      id:3,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:4,
    },
    {
      id:4,
      respuestas:[],
      template:8,
    },
]

export default dorsalLateral