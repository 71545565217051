import React, {Text} from 'react';
import './../../css/main.scss';

const caderaAnterior = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída o mal gesto de la cadera'},
                  {id:2,text:'Un sobresfuerzo',trigger:9,asociacion:1,masInfo:'Realizar un esfuerzo excesivo o más de lo habitual'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:9,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:9,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar cómo ha sido el traumatismo?',
        respuestas:[{id:1,text:'Golpe directo en la cadera',masInfo:'Golpe o choque en la cadera',trigger:2,asociacion:0},
                    {id:2,text:'Caída sobre la cadera',masInfo:'Una caída con impacto sobre la cadera',trigger:2,asociacion:1},
                    {id:3,text:'Mal gesto',masInfo:'Al realizar un Sprint, cambios de dirección, golpeo de balón, etc.',trigger:2,asociacion:2},
                    {id:3,text:'Otro',masInfo:'El inicio del dolor no se asocia con ninguna de las acciones anteriores',trigger:2,asociacion:3}
                ],
        template:10,
    },  
    
    {
        id:2,
        preg:'¿Cuál ha sido el grado del traumatismo?',
        type:'trauma',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Y el grado de inflamación que has presentado?',
        type:'inflamacion',
        template:3,
        trigger:4,
    },
    {
        id:4,
        preg:'¿Cuál es la intensidad del dolor al mover la cadera después de la lesión?',
        type:'trauma',
        template:3,
        trigger:5,
    },
    {
        id:5,
        preg:'¿Se reproduce tu dolor al presionar en alguno de los siguientes puntos?',
        respuestas:[{id:1,text:'Trocánter mayor',masInfo:'Hueso prominente en el lateral de la cadera',trigger:6,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR4.2.jpg'},
                    {id:2,text:'Recto anterior',masInfo:'Parte anterior de la cadera',trigger:6,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR.jpg'},
                    {id:3,text:'Es un dolor difuso',masInfo:'Cuesta localizar el dolor en un punto concreto',trigger:6,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR5.jpg'},
                    {id:3,text:'No me duele al tocar',masInfo:'El dolor no se reproduce en ninguno de los puntos',trigger:6,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/NO.jpg'}
                ],
        template:4,
    },  
        
      {
        id:6,
        preg:[{text:'¿Llevas más de 2-3 meses con el dolor?', masInfo:{state:false,},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
              {text:'¿Has presentado un hematoma?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},   
              {text:'Por último, ¿has presentado bloqueos o chasquidos al mover la pierna?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'El bloqueo es la imposibilidad de mover la cadera en momentos puntuales y los chasquidos se relacionan con crujidos al mover'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR2.jpg'
              },
              {text:'¿Has sentido un pinchazo en el momento de la lesión?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR3.jpg'},   

      ],
        trigger:7,
        template:7,
      },

    {
      id:7,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:8,
    },
    {
      id:8,
      respuestas:[],
      template:8,
    },
//TRAUMA NO
    {
        id:9,
        preg:'¿Me podrías indicar dónde son más intensos los síntomas?',
        respuestas:[{id:1,text:'Anterior localizado',masInfo:'El dolor es localizado en un punto',trigger:10,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR.jpg'},
                    {id:2,text:'Antero-interna',masInfo:'El dolor se irradia desde la parte anterior a la interna',trigger:10,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR4.3.jpg'},
                    {id:3,text:'Antero-lateral',masInfo:'El dolor se irradia desde la parte anterior al lateral',trigger:10,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR4.2.jpg'},
                ],
        template:4,
    },  
    {
        id:10,
        preg:'¿Se reproduce tu dolor al presionar sobre alguno de los siguientes puntos?',
        respuestas:[{id:1,text:'Parte anterior',masInfo:'Dolor al realizar presión en la parte anterior de la cadera',trigger:11,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR.jpg'},
                    {id:2,text:'Músculo tensor de la fascia lata',masInfo:'Dolor al presionar sobre este músculo',trigger:11,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR4.2.jpg'},
                    {id:3,text:'Parte interna',masInfo:'Dolor al presionar en la parte interna de la cadera',trigger:11,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR4.3.jpg'},
                    {id:4,text:'No',masInfo:'No se reproduce mi dolor al tocar en ninguno de los puntos',trigger:11,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/NO.jpg'}
                ],
        template:4,
    },
    {
        id:11,
        preg:'¿Me podrías decir cuál de estos movimientos reproduce mejor tu dolor?',
        respuestas:[{id:1,text:'Flexión de cadera',masInfo:'Dolor al levantar la rodilla',trigger:12,asociacion:0,imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/FLEXCADERA.mp4'},
                    {id:2,text:'Aducción de cadera',masInfo:'Dolor al intentar juntar las rodillas',trigger:12,asociacion:1,imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAADD.mp4'},
                    {id:3,text:'Rotacion interna o externa de cadera',masInfo:'Dolor al rotar la cadera con el movimiento del pie',trigger:12,asociacion:2,imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAROTINT.mp4'},
                ],
        template:4,
    },  
      {
        id:12,
        preg: [ {text:'¿Realizas o realizabas antes de lesionarte actividad física de media-alta intensidad? (3-4 veces a la semana)', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERADEPORTE.jpg'},
                {text:'¿Llevas más de tres meses con los síntomas?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},   
                {text:'¿Al mover la pierna se produce un chasquido?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Los chasquidos se relacionan con crujidos al mover la cadera'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR2.jpg'
                },
                {text:'Por último, ¿Sientes un bloqueo al mover la cadera?',
                  masInfo:{
                    state:true,
                    titulo:'¿Qué es un bloqueo?',
                    text:'El bloqueo es la imposibilidad de mover la cadera en momentos puntuales'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR3.jpg'},
              ],
        trigger:13,
        template:7,
      },

    {
      id:13,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:14,
    },
    {
      id:14,
      respuestas:[],
      template:8,
    }
]

export default caderaAnterior