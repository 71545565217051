import React, {Text} from 'react';
import './../../css/main.scss';

const musloPosterior = [
    {
        id:0,
        preg:'Indícame la intensidad del dolor en el momento de la lesión',
        type:'trauma',
        template:3,
        trigger:1,
    },
    { 
        id:1,
        preg:[
          {text:'¿Llevas más de un mes con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Has aumentado la carga de entrenamiento, las distancias al andar o practicado nuevas actividades justo los días antes de lesionarte?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Aumentar la actividad física o de trabajo los días-semanas antes de lesión'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/INTERNA/MUSLOMEDIAL2.jpg'
          },
          {text:'¿Asocias el inicio del dolor a un gesto específico o movimiento explosivo?',
              masInfo:{
                state:true,
                titulo:'Ver ejemplos',
                text:'Un mal gesto al realizar un sprint, cambio de dirección, salto, etc.'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/ANTERIOR/SPRINTREPETIDO.jpg'
          },
          {text:'¿Has notado un pinchazo en el momento de la lesión?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Un pinchazo es un dolor agudo, como si te hubieran lanzado una piedra'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/POSTERIOR/MUSLOPOSTERIOR2.jpg'
          },
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿El dolor se irradia desde los glúteos hacia el pie?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/POSTERIOR/MUSLOPOSTERIOR3.jpg'},
          {text:'¿Notas una sensación de hormigueo en el muslo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/POSTERIOR/MUSLOPOSTERIOR4.jpg'},
          {text:'¿El dolor se localiza justo en la tuberosidad isquiática?',
                masInfo:{
                  state:true,
                  titulo:'¿Dónde está este punto?',
                  text:'Este punto se encuentra justo por debajo del pliegue glúteo'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MUSLO/POSTERIOR/MUSLOPOSTERIOR5.jpg'
            },


], 
        trigger:2,
        template:7,
      },

    {
      id:2,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:3,
    },
    {
      id:3,
      respuestas:[],
      template:8,
    },
]

export default musloPosterior