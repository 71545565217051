import React, {Text} from 'react';
import './../../css/main.scss';

const tobilloPosterior = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída con mal apoyo o mal gesto del tobillo'},
                  {id:2,text:'Ha empezado con el tiempo',trigger:10,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:3,text:'No lo sé',trigger:10,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
        {
          id:1,
          preg:'¿Cómo ha sido el traumatismo que asocias al dolor?',
          respuestas:[{id:1,text:'Golpe directo',masInfo:'Un golpe o choque sobre el tobillo',trigger:2,asociacion:0},
                      {id:2,text:'Movimiento forzado',masInfo:'Un mal gesto al realizar algún movimiento',trigger:3,asociacion:1},
                      {id:2,text:'Mal apoyo',masInfo:'Un mal gesto al apoyar',trigger:3,asociacion:2},
                      {id:3,text:'No lo sé',masInfo:'No estoy seguro o el traumatismo ha sido diferente',trigger:4,asociacion:3},],
          template:10,
    },   

    {
        id:2,
        preg:'¿Cuál ha sido la intensidad del golpe?',
        type:'trauma',
        template:3,
        trigger:5,
    },
    {
        id:3,
        preg:'Perfecto, ¿cuál ha sido la intensidad del dolor en el momento de la lesión?',
        type:'trauma',
        template:3,
        trigger:5,
    },
    {
      id:4,
      preg:'Perfecto, ¿cuál ha sido la intensidad del traumatismo?',
      type:'trauma',
      template:3,
      trigger:5,
  },

    {
        id:5,
        preg:'¿Has presentado una inflamación? ',
        respuestas:[{id:1,text:'Si, una pequeña inflamación',masInfo:'El tobillo se ha hinchado un poquito',trigger:6,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                    {id:2,text:'Sí, está bastante hinchado',masInfo:'El tobillo se ha hinchado notablemente',trigger:6,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/moderado.jpg'},
                    {id:3,text:'Sí, la inflamación es grande',masInfo:'El tobillo ha experimentado una gran inflamación',trigger:6,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'},
                    {id:4,text:'No',masInfo:'El tobillo no ha experimentado ninguna inflamación',trigger:6,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'}],
        template:4,
    },
    {
      id:6,
      preg:'¿En qué parte es el dolor más intenso?',
      respuestas:[{id:1,text:'Superior',masInfo:'En la parte alta del tobillo',trigger:7,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO20SUPERIOR.jpg'},
                  {id:2,text:'Inferior',masInfo:'En la parte baja del tobillo',trigger:7,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO20INFERIOR.jpg'},
                 ],
      template:4,
    },
      {
        id:7,
        preg:[
          {text:'¿Llevas más de tres meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Y una sensación de crack o chasquido en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO22.jpg'},
          {text:'¿Has sentido como un golpe o pedrada en la parte posterior del tobillo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO21.jpg'},
          {text:'¿Presentas dolor al mover el pie hacia abajo (flexión plantar)?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONPLANTAR.mp4'},
          {text:'¿Y al llevar hacia arriba (flexión dorsal)?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONDORSAL.mp4'},
          {text:'¿Has presentado incapacidad para mover el tobillo en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO2.jpg'},
          {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Imposibilidad de apoyar tu peso sobre la pierna afectada'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO3.jpg'
          },
  ],
        trigger:8,
        template:7,
      },

    {
      id:8,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:9,
    },
    {
      id:9,
      respuestas:[],
      template:8,
    },
    
    //TRAUMA NO
    {
      id:10,
      preg:'¿En qué parte es el dolor más intenso?',
      respuestas:[{id:1,text:'Superior',masInfo:'En la parte alta del tobillo',trigger:11,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO20SUPERIOR.jpg'},
                  {id:2,text:'Inferior',masInfo:'En la parte baja del tobillo',trigger:11,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO20INFERIOR.jpg'},
                 ],
      template:4,
    },
      {
        id:11,
        preg:'¿Al presionar sobre la zona el dolor empeora? ',
        respuestas:[{id:1,text:'Si, posterior al tobillo',masInfo:'Dolor al presionar a la altura del tendón de Aquiles',trigger:12,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLOPOSTERIOR.jpg'},
                    {id:2,text:'Si, posterior al talón',masInfo:'Dolor al presionar en la inserción del tendón',trigger:12,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLOTALON.jpg'},
                    {id:3,text:'No',masInfo:'No se reproduce el dolor al presionar',trigger:12,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'}],
        template:4,
    },
      {
        id:12,
        preg: [
          {text:'¿Has incrementado la carga de entrenamiento o aumentado las distancias al andar?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
          {text:'¿Presentas sensación de rigidez por la mañana?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Al levantar de la cama los primeros pasos duelen pero al rato disminuye el dolor'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO22.jpg'
          },
          {text:'¿Presentas enrojecida o hinchada la zona de dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TOBILLO24.jpg'},
          {text:'¿Se reproduce tu dolor al ponerte de puntillas sobre el pie lesionado?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/PUNTILLASREPETIDO.jpg'},
          {text:'¿Y al ponerte de talones?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/TOBILLO/POSTERIOR/TALONESDEFINITIVO.jpg'},
          {text:'¿Se reproduce tu dolor al realizar una flexión plantar?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONPLANTAR.mp4'},
          {text:'¿Y al realizar una flexión dorsal?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/FLEXIONDORSAL.mp4'},
  ],
      trigger:13,
      template:7,
    },

    {
      id:13,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:14,
    },
    {
      id:14,
      respuestas:[],
      template:8,
    }]

export default tobilloPosterior