import React, {Component} from 'react';
import Fade from 'react-reveal/Fade';
import '../css/preguntasRapidas.scss'
import '../css/main.scss';
import logo from './../img/logoAzulOscuro.png'
import flecha from './../img/arrow-color.png'
import arrow from '../img/arrow-izq.png'


class PreguntasRapidas extends Component{
    constructor(props){
        super(props)
        this.state = {
            numeroPregunta:0,
            imagenPregunta:'',
            textoPregunta:'',
            respuestasPreguntasRapidas:[],
            TextTrigger:0,
            masInfo:false,
        }       
    }
    
    handleClickSI = () => {
        this.setState(prevState => {
           return {numeroPregunta: prevState.numeroPregunta + 1,masInfo:false}
        })
        {this.props.creararray(0,this.props.state.articulacion,this.props.state.zonaDolor,this.props.state.asociacion)}
    }
    
    handleClickNO = () => {
        this.setState(prevState => {
           return {numeroPregunta: prevState.numeroPregunta + 1,masInfo:false}
        })
        {this.props.creararray(1,this.props.state.articulacion,this.props.state.zonaDolor,this.props.state.asociacion)}
    }

    handleVolver = () => {
        if (this.state.numeroPregunta === 0){
            this.props.handleclick(0,0,3, () => {
                this.props.handlearrayempty() 
            })
        }
        else{
            this.setState({
                numeroPregunta: this.state.numeroPregunta - 1,
            }, () => {
                this.props.handlevolver()
            })
        }
}

    render(){
        const longitudArrayPreguntas = this.props.preguntas.preg.length;
        let timer = setTimeout(this.setState.bind(this, {TextTrigger:1}), 2500);
        if(this.state.TextTrigger ===0){
            return(
                <div className='primaryContainer'>
                    <div className='logo'>
                        <img src={logo} width='115px' height='40px'/>
                    </div> 
                    <div className='preguntaBlock'>
                        <div className='pregunta-container fade-in-right-short'>
                            <div className='line-preguntas'>   
                                <p className='text-aurya-small'>Voy a hacerte algunas <span style={{fontWeight:'700'}}>preguntas</span> rápidas</p>
                            </div>
                        </div>
                    </div>
                    <div className='imageBlock-rapidas'>
                    </div>                
                </div>        
            )
        }
        
        if(this.state.TextTrigger===1){
            console.log(this.props)
            return(
                <div className='primaryContainer'>
                <div className='logo'>
                    <a href='https://diagnosis.fisify.com'>
                      <img src={logo} width='115px' height='40px'/>
                    </a>
                </div>                    
                    {longitudArrayPreguntas > this.state.numeroPregunta?
                    <>
                        <div className='imageBlock-rapidas'>
                                 <div className='circulo-container'>
                                    {this.props.preguntas.preg.map((preguntas,index)=>
                                        <div key={index} className={index < this.state.numeroPregunta ?'circulo-active':'circulo'}></div>
                                    )}
                                </div>
                                <div className='imagen-container'>
                                    {this.props.preguntas.preg[this.state.numeroPregunta].imagen == 'x'?
                                        <video autoplay='autoplay' muted loop src={this.props.preguntas.preg[this.state.numeroPregunta].video} className='imagen-pregunta-rapida' />
                                        :
                                        <img src={this.props.preguntas.preg[this.state.numeroPregunta].imagen} className='imagen-pregunta-rapida' />
                                    }
                                    {this.props.preguntas.preg[this.state.numeroPregunta].masInfo.state?
                                        <div className='mas-info' onClick = {()=>this.setState({masInfo:!this.state.masInfo})}>
                                            <p className='mas-info-titulo'>{this.props.preguntas.preg[this.state.numeroPregunta].masInfo.titulo}</p>
                                            {this.state.masInfo?
                                                <p className='mas-info-text-active'>{this.props.preguntas.preg[this.state.numeroPregunta].masInfo.text}</p>
                                                :
                                                <p className='mas-info-text'>Aquí podras obtener más información o ejemplos sobre la pregunta</p>
                                            }
                                            <div className='action-container'>
                                                <p className='text-action'>{this.state.masInfo?'Cerrar':'¿Tienes dudas?'}</p>
                                                <img src={flecha} className='flecha' />
                                            </div>
                                      </div>:''}
                                </div>
    
                        </div>
                    <div className='preguntaBlock-rapidas'>
                        <div className='pregunta-container'>
                        <Fade spy={this.state.numeroPregunta} appear>
                            <div className='line-preguntas'>
                                <p className='text-aurya-small'>{this.props.preguntas.preg[this.state.numeroPregunta].text}</p>                 
                            </div>
                            </Fade>
                            <div className='respuestas-si-no-container' style={{justifyContent:'start'}}>
                                <div className='caja-botones'>
                                        <div className='button-si' onClick ={() => this.handleClickSI()}>
                                            <p className='texto-si'>SI</p>
                                        </div>
                                        <div className='button-no' onClick ={() => this.handleClickNO()}>
                                            <p className='texto-no'>NO</p>
                                        </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='atras-container' onClick={()=>this.handleVolver()}>
                        <img src={arrow} width='20px' />
                        <p>Volver</p>
                    </div>
                    </>
                    :
                    this.props.handlesubmit(this.props.state.articulacion,this.props.state.zonaDolor,this.props.preguntas.trigger)}

                </div>
            )

        }      
    }
}

export default PreguntasRapidas