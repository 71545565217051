import React, {Text} from 'react';
import './../../css/main.scss';

const cabeza= [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe o mal gesto de la cabeza'},
                  {id:2,text:'Época de estrés y/o de ansiedad',trigger:7,asociacion:1,masInfo:'El inicio del dolor está asociado a una época de estrés y/o de ansiedad ' },
                  {id:3,text:'Ha empezado con el tiempo',trigger:7,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:7,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Y cuál ha sido la intensidad del dolor?',
        type:'trauma',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿El dolor se ha presentado en un lado o en ambos lados?',
        respuestas:[{id:1,text:'Unilateral',masInfo:'El dolor se presenta en un lado de la cabeza y cuello',trigger:4,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZA1UNILATERALREP.jpg'},
                    {id:2,text:'Bilateral',masInfo:'El dolor se presenta en ambos lados de la cabeza y cuello',trigger:4,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZA1BILATERALREPREP.jpg'},
                    {id:3,text:'Indiferente',masInfo:'El dolor es más general y no se identifica tan fácilmente',trigger:4,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NOLOSEREPETIDO.jpg'}],
        template:4,
    },
    {
        id:4,
        preg: [ {text:'¿Has sufrido un accidente automovilístico relacionado con el inicio del dolor?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA2REP.jpg'},   
                {text:'¿Llevas más de un mes con los síntomas?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
                {text:'¿Has presentado sensación de náuseas o vómitos?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA4REP.jpg'},      
                {text:'¿Y una sensación de mareo?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'La sensación de mareo se refiere a la sensación de que vas a perder el equilibrio'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA5REP.jpg'},
                {text:'¿Has experimentado una sensación de vértigo al cambiar de posición la cabeza?',
                masInfo:{
                  state:true,
                  titulo:'¿Qué es el vértigo?',
                  text:'La sensación de vértigo se refiere a la sensación de que la habitación donde se encuentra gira a su alrededor.'
                  },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA6REP.jpg'
                },
                {text:'Perfecto ¿Y una rigidez al mover el cuello?',
                    masInfo:{
                      state:true,
                      titulo:'Más información',
                      text:'La rigidez de cuello es la sensación de falta de movilidad al rotar o inclinar el cuello'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA7REP.jpg'
                },
                {text:'¿Por último, ¿has presentado sensación de pesadez o sobrecarga en la parte posterior de la cabeza?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'La sensación de pesadez del cuello se refiere a la sensación de rigidez al tocar los músculos de la nuca'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA8REP.jpg'
                },
],
        trigger:5,
        template:7,
    },
    {
      id:5,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:5,
    },
    {
      id:6,
      respuestas:[],
      template:8,
    },
    
    //TRAUMA NO
    {
        id:7,
        preg:'¿Me podrías indicar la intensidad del dolor?',
        type:'trauma',
        template:3,
        trigger:8,
    },
    {
        id:8,
        preg:'¿El dolor se ha presentado en un lado o en ambos lados?',
        respuestas:[{id:1,text:'Unilateral',masInfo:'El dolor se presenta en un lado de la cabeza y cuello',trigger:9,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZA1UNILATERALREP.jpg'},
                    {id:2,text:'Bilateral',masInfo:'El dolor se presenta en ambos lados de la cabeza y cuello',trigger:9,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZA1BILATERALREPREP.jpg'},
                    {id:3,text:'Indiferente',masInfo:'El dolor es más general y no se identifica tan fácilmente',trigger:9,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NOLOSEREPETIDO.jpg'}],
        template:4,
    },
      {
        id:9,
        preg: [ {text:'¿La presencia de los síntomas te incapacita para realizar las actividades de la vida diaria?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA2REP.jpg'},   
                {text:'¿Has presentado sensación de náuseas o vómitos?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA4REP.jpg'},
                {text:'¿Llevas más de un mes con los síntomas?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},      
                {text:'¿Tienes sensaciones de mareo?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'La sensación de mareo se refiere a la sensación de que vas a perder el equilibrio'
                    },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA5REP.jpg'
                },
                {text:'¿Has experimentado una sensación de vértigo al cambiar de posición la cabeza?',
                    masInfo:{
                      state:true,
                      titulo:'¿Qué es el vértigo?',
                      text:'La sensación de vértigo se refiere a la sensación de que la habitación donde se encuentra gira a tu alrededor.'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA6REP.jpg'
                },
                {text:'Perfecto ¿Y una rigidez al mover el cuello?',
                    masInfo:{
                      state:true,
                      titulo:'Más información',
                      text:'La rigidez de cuello es la sensación de falta de movilidad al rotar o inclinar el cuello'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA7REP.jpg'
                },
                {text:'¿Presentas sensación de pesadez o sobrecarga en la parte posterior de la cabeza?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'La sensación de pesadez del cuello se refiere a la sensación de rigidez al tocar los músculos de la nuca'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA8REP.jpg'
                },
                {text:'¿Por último, ¿permaneces mucho tiempo en posturas mantenidas?',
                  masInfo:{
                    state:true,
                    titulo:'Ver ejemplos',
                    text:'Por ejemplo, permanecer sentado estudiando o delante del ordenador'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA/CABEZA9.jpg'
                },
        ],
        trigger:10,
        template:7,
      },

    {
      id:10,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:11,
    },
    {
      id:11,
      respuestas:[],
      template:8,
    }]

export default cabeza