import React, {Component} from 'react';
import EdadSexo from './EdadSexo';
import Articulacion from './Articulacion';
import CuantoDuele from './CuantoDuele';
import PreguntaConRespuestas from './PreguntaConRespuestas';
import Asociacion from './PreguntaAsociacion';
import SubmitTemplate from './SubmitTemplate';
import PreguntasRapidas from './PreguntasRapidas';
import TextTemplate from './TextTemplate';
import MainResultados from './pantallaResultados/MainResultados';
import Introduccion from './Introduccion';
import Loading from './Loading'
import '../css/main.scss'

class MainForm extends Component{
    render(){
        if(this.props.preguntas.template === 0){
            return(
               
                    <TextTemplate
                        preguntas={this.props.preguntas} 
                        handleclick = {this.props.handleclick}
                        state={this.props.state}
                    
                        /> 
            )
        }
        if(this.props.preguntas.template === 1){
            return(
                
                  <Articulacion 
                    preguntas={this.props.preguntas} 
                    handleclick = {this.props.handleclick}
                    state={this.props.state}/>
                
            )
        }
        if(this.props.preguntas.template === 2){
            return(               
                  <Introduccion 
                        handleclick = {this.props.handleclick}
                  />
                
            )
        }
        if(this.props.preguntas.template === 3){
            return(
                <div className='pregunta-block'>
                  <CuantoDuele 
                        preguntas={this.props.preguntas} 
                        handleclick = {this.props.handleclick}
                        state={this.props.state}
                        creararray={this.props.creararray}
                        handlearrayempty = {this.props.handlearrayempty}
                        />
                        
                </div>
            )
        }
        if(this.props.preguntas.template === 4){
            return(
                
                  <PreguntaConRespuestas
                        preguntas={this.props.preguntas} 
                        handleclick = {this.props.handleclick}
                        state={this.props.state}
                        creararray={this.props.creararray}
                        handlearrayempty = {this.props.handlearrayempty}
                        />        
            )
        }
        if(this.props.preguntas.template === 5){
            return(
                <div className='pregunta-block'>
                  <SubmitTemplate
                        preguntas={this.props.preguntas} 
                        handleclick = {this.props.handleclick}
                        state={this.props.state}                   
                    />
                        
                </div>
            )
        }
        if(this.props.preguntas.template === 6){
            return(
                    <div className='pregunta-block'>
                     <EdadSexo 
                        handleclick = {this.props.handleclick}
                        preguntas={this.props.preguntas}
                        handlesubmit = {this.props.handlesubmit}
                        handleEpidemiologia ={this.props.handleEpidemiologia}
                    />
                    </div>  
            )
        }

        if(this.props.preguntas.template === 7){
            return(
                <div className='pregunta-block'>
                  <PreguntasRapidas
                        preguntas={this.props.preguntas} 
                        handleclick = {this.props.handleclick}
                        state={this.props.state}
                        creararray={this.props.creararray}
                        handlesubmit ={this.props.handlesubmit}
                        handlevolver ={this.props.handlevolver}                
                        />                        
                
                </div>
            )
        }

        if(this.props.preguntas.template === 8){
            return(
                <div className='pregunta-block'>
                  <MainResultados
                        preguntas={this.props.preguntas} 
                        handleclick = {this.props.handleclick}
                        state={this.props.state}
                    
                        />
                        
                </div>
            )
        }
        if(this.props.preguntas.template === 9){
            return(
                  <Loading
                        preguntas={this.props.preguntas} 
                        handleclick = {this.props.handleclick}
                        state={this.props.state}
                    
                        />
                        
            )
        }
        if(this.props.preguntas.template === 10){
            return(
                  <Asociacion
                        preguntas={this.props.preguntas} 
                        handleclick = {this.props.handleclick}
                        state={this.props.state}
                        creararray={this.props.creararray}                   
                        />                       
            )
        }
 
    }
}

export default MainForm