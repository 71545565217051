import React, {Text} from 'react';
import './../../css/main.scss';

const caderaPosterior = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída o mal gesto de la cadera'},
                  {id:2,text:'Un sobresfuerzo',trigger:8,asociacion:1,masInfo:'Realizar un esfuerzo excesivo o más de lo habitual'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:8,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:8,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
    
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Cuál es el grado de inflamación que has presentado?',
        type:'inflamacion',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'Perfecto, y ¿la intensidad del dolor al mover la cadera después de la lesión?',
        type:'trauma',
        template:3,
        trigger:4,
    },
    {
        id:4,
        preg:'¿Se reproduce tu dolor al presionar en alguno de los siguientes puntos?',
        respuestas:[{id:1,text:'Glúteo',masInfo:'Dolor al presionar sobre el glúteo',trigger:5,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/POSTERIOR/GLUTEOPOSTERIOR12.jpg'},
                    {id:2,text:'Trocánter mayor',masInfo:'Dolor al presionar sobre el hueso prominente en el lateral de la cadera',trigger:5,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERAFASCIA.jpg'},
                    {id:3,text:'No me duele al tocar',masInfo:'No se reproduce el dolor en ninguno de los puntos',trigger:5,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'},
                ],
        template:4,
    },  
        
      {
        id:5,
        preg:  [{text:'¿Llevas más de 3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
                {text:'¿Has presentado un hematoma?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},   
                {text:'¿Has presentado bloqueos o chasquidos al mover la pierna?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Los chasquidos se relacionan con crujidos al mover la cadera y los bloqueos con las restricciones de movilidad'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR2.jpg'
                },
                {text:'Por último, ¿tienes una sensación de hormigueo que se irradia a la rodilla?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/POSTERIOR/CADERAIRRADIADO.jpg'},     

        ],
        trigger:6,
        template:7,
      },

    {
      id:6,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:7,
    },
    {
      id:7,
      respuestas:[],
      template:8,
    },
//TRAUMA NO

    {
        id:8,
        preg:'¿Me podrías indicar dónde son más intensos los síntomas?',
        respuestas:[{id:1,text:'Parte alta-media', masInfo:'El dolor se ubica en la parte alta y media de los glúteos', trigger:9,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/POSTERIOR/GLUTEOPOSTERIOR12.jpg'},
                    {id:2,text:'Parte baja', masInfo:'El dolor se ubica en la parte inferior de los glúteos', trigger:9,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/POSTERIOR/MUSLOPOSTERIOR5.jpg'},
                    {id:3,text:'Irradiado', masInfo:'El dolor se irradia hacia el muslo', trigger:9,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO1.2.jpg'},
                    {id:4,text:'Disfuso', masInfo:'El dolor es difícil de localizar en un punto', trigger:9,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/IRRADIADO/CADERAIRRADIADO5.jpg'}
                ],
        template:4,
    },  
    {
        id:9,
        preg:'¿En cuál de estos puntos se reproduce mejor tu dolor?',
        respuestas:[{id:1,text:'Glúteo', masInfo:'Dolor en la zona glútea', trigger:10,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/POSTERIOR/GLUTEOPOSTERIOR12.jpg'},
                    {id:2,text:'Trocánter mayor', masInfo:'Dolor en la zona del hueso prominente en el lateral de la cadera', trigger:10,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERAFASCIA.jpg'},
                    {id:3,text:'En ambos', masInfo:'El dolor se reproduce en ambas zonas', trigger:10,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/POSTERIOR/AMBOSCADERA.jpg'},
                    {id:4,text:'En ninguno de estos puntos', masInfo:'No se reproduce el dolor en ninguno de los puntos', trigger:10,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'},
                ],
        template:4,
    },
    {
        id:10,
        preg:'¿Cuál ha sido la intensidad del dolor?',
        type:'trauma',
        template:3,
        trigger:11,
    },
      {
        id:11,
        preg: [ {text:'¿Llevas más de 3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
                {text:'¿Has presentado bloqueos o chasquidos al mover la pierna?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Los chasquidos se relacionan con crujidos al mover la cadera y los bloqueos con las restricciones de movilidad'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR2.jpg'
                },
                {text:'¿Sientes adormecimiento o sensación de hormigueo en la pierna?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/POSTERIOR/CADERAIRRADIADO.jpg'},   
                {text:'Por último, ¿se reproduce tu dolor al realizar una abducción contra resistencia?',masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/POSTERIOR/CADERAABD.mp4'},     

        ],
        trigger:12,
        template:7,
      },

    {
      id:12,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:13,
    },
    {
      id:13,
      respuestas:[],
      template:8,
    }
]

export default caderaPosterior