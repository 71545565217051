const brazo = [
  {
  id:0,
  preg:'',
  nombre:'Brazo',
  respuestas:[{id:1,text:'Parte anterior',trigger:1,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/BRAZO/brazoAnteriorParte.jpg',masInfo:'Dolor en la parte de delante del brazo'},
              {id:2,text:'Parte posterior',trigger:2,img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/BRAZO/brazoPosteriorParte.jpg',masInfo:'Dolor en la parte de atrás del brazo'}],
  template:2,
}]

export default brazo