const cyc = [
  {
  id:0,
  preg:'',
  nombre:'Cabeza y cervical',
  respuestas:[{id:1,text:'Cervical',trigger:1, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/cervical.jpg',masInfo:'Dolor localizado en el cuello'},
              {id:2,text:'Cabeza',trigger:2, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/cabeza.jpg',masInfo:'Dolor localizado en la cabeza'},
              {id:3,text:'Cabeza y cervical',trigger:3, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/cabezaYCervical.jpg',masInfo:'Dolor en el cuello y cabeza'},
              {id:4,text:'Irradiado al brazo',trigger:4, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/cervicalIrradiado.jpg',masInfo:'Dolor de cuello que se irradia al brazo'}],
  template:2,
}]

export default cyc