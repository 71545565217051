import React from 'react';
import MainForm from './components/MainForm';
import data1 from './data/data';
import axios from 'axios';
import './css/main.scss'

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      nombre:'',
      edad:0,
      genero:0,
      articulacion: 0,
      zonaDolor:0,
      asociacion:0,
      array:[],
      patologia:[],
      probabilidades:[],
      loading:true,
  }

  this.handleClick = this.handleClick.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.crearArray = this.crearArray.bind(this);
  this.handleEpidemiologia = this.handleEpidemiologia.bind(this);

  }
  crearArray(resp,artic,zonadolor,trigger) {
    console.log('respuesta',resp)
    console.log('array',[...this.state.array,resp])
    
    this.setState({ articulacion: artic, zonaDolor:zonadolor,asociacion:trigger,array:[...this.state.array,resp]});
    window.scrollTo(0, 0);

    console.log(this.state)   
  }

  handleVolverArray(){
    this.state.array.pop()
    window.scrollTo(0, 0);
  }

  handleArrayEmpty(){
    this.setState({array:[]})
    window.scrollTo(0, 0);
  }

  handleClick(artic,zonadolor,trigger) {
    this.setState({ articulacion: artic, zonaDolor:zonadolor,asociacion:trigger});
    window.scrollTo(0, 0);
    console.log(this.state)
    
  }
  handleEpidemiologia(artic,zonadolor,trigger,edad,genero,nombre){
    this.setState({ articulacion: artic, zonaDolor:zonadolor,asociacion:trigger,edad:edad,genero:genero,nombre:nombre})
    window.scrollTo(0, 0);

  }

  handleSubmit(artic,zonadolor,trigger){
    this.setState({ articulacion: artic, zonaDolor:zonadolor,asociacion:trigger});
    var array = this.state.array
    const NotAsociacion = [2,10,12,13]
    if(NotAsociacion.includes(this.state.articulacion)){
      console.log('NotAsociacion')
      var asociaciontrauma = array
      var aso=0
    }
    else{
      console.log('Asociacion')
      var asociaciontrauma = array.splice(0,1)
      var aso=asociaciontrauma[0]
    }

    const LOCALHOST_URI = 'http://localhost:3000/diagnosys'
    const HEROKU_URI = 'https://api-diagnostico.herokuapp.com/diagnosys'

    var self = this;
    console.log(array)
    console.log('backend llamada')
    axios.post(HEROKU_URI, {
        "nombre":this.state.nombre,
        "edad":this.state.edad,
        "genero":this.state.genero,
        "articulacion":this.state.articulacion,
        "zonadolor":this.state.zonaDolor,
        "asociacion":aso,
        "respuestas":array,
      }
    )
    .then(function (response) {
      console.log('response',response.data);
      self.setState({patologia:response.data.patologias,probabilidades:response.data.probabilidades,loading:false})
         
    })
    .catch(function (error) {
      console.log('ERROR',error);
    })
    
  }
  
  render() 
  {
    const preguntas = data1[this.state.articulacion][this.state.zonaDolor][this.state.asociacion]
    return (
          <div className='backgroundContainer'>   
              <MainForm 
                  preguntas={preguntas}
                  handleclick={this.handleClick}
                  creararray={this.crearArray}
                  handlesubmit={this.handleSubmit}
                  handleEpidemiologia={this.handleEpidemiologia}
                  handlevolver={this.handleVolverArray}
                  handlearrayempty ={this.handleArrayEmpty}
                  state={this.state}/>    
          </div>
);
}
  
}

export default App;
