import React, {Text} from 'react';
import './../../css/main.scss';



const mandibula = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias el inicio de tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un traumatismo o golpe directo' },
                  {id:2,text:'Ha empezado con el tiempo',trigger:7,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo' },
                  {id:3,text:'Época de estrés y/o de ansiedad',trigger:7,asociacion:1,masInfo:'El inicio del dolor está asociado a una época de estrés y/o de ansiedad ' },
                  {id:4,text:'No lo sé',trigger:7,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores' }],
      template:10,
    },
    //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'Cuál es el grado de inflamación que presentas',
        type:'inflamacion',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Y la intensidad del dolor al abrir y cerrar la boca?',
        type:'trauma',
        template:3,
        trigger:4,
    },
      {
        id:4,
        preg:  [
                {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/HEMATOMAREPETIDO.jpg'},
                {text:'¿Has sentido dificultad para abrir la boca completamente?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA1.jpg'},
                {text:'¿Y presentas bloqueos o la mandíbula se atasca al abrir la boca?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Presentar bloqueos significa imposibilidad para abrir la boca'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA2.jpg'
                },
                {text:'¿Presentas chasquidos o clics al abrir y cerrar la boca o masticar alimentos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA4.jpg'},
                {text:'Por último, ¿presentas una deformidad en la mandíbula?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Una deformidad se refiere a que la mandíbula se encuentra desplazada'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA5.jpg'
                },
        ],
        trigger:5,
        template:7,
    },
    {
      id:5,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:6,
    },
    {
      id:6,
      respuestas:[],
      template:8,
      trigger:5,
    },
    
    //TRAUMA NO

    {
      id:7,
      preg: [
              {text:'¿Presentas dolor al abrir y cerrar la boca o al masticar alimentos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA4.jpg'},
              {text:'¿Has sentido dificultad para abrir la boca completamente?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA1.jpg'},
              {text:'¿Y presentas bloqueos o la mandíbula se atasca al abrir la boca?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'Presentar bloqueos significa imposibilidad para abrir la boca'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA8.jpg'
              },
              {text:'¿Presentas chasquidos o clics al abrir y cerrar la boca o masticar alimentos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA5.jpg'},
              {text:'¿Presentas dolor, tensión o rigidez muscular masticatoria?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'Una sensación de rigidez al tocar los músculos de la mandíbula'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA6.jpg'
              },
              {text:'¿Presentas frecuentes dolores de cabeza, cuello o cara?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA7.jpg'},
              {text:'¿Y dolor en los dientes o en las encías al despertarse?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA9.jpg'},
              {text:'Por último, ¿alguna vez eres consciente de apretar o rechinar los dientes durante el día?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/ATM/MANDIBULA2.jpg'},

      ],
      trigger:8,
      template:7,
    },

    {
      id:8,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:0,
      trigger:9,
    },
    {
      id:9,
      respuestas:[],
      template:8,
      trigger:5,
    }]

export default mandibula