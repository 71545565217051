import React, {Text} from 'react';
import './../../css/main.scss';

const caderaLateral = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída o mal gesto de la cadera'},
                  {id:2,text:'Un sobresfuerzo',trigger:8,asociacion:1,masInfo:'Realizar un esfuerzo excesivo o más de lo habitual'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:8,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:8,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI
    
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Cuál ha sido el grado de inflamación?',
        type:'inflamacion',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Y la intensidad del dolor al mover la cadera después de la lesión?',
        type:'trauma',
        template:3,
        trigger:4,
    },
    {
        id:4,
        preg:'¿Se reproduce tu dolor al presionar en alguno de los siguientes puntos?',
        respuestas:[{id:1,text:'Trocánter mayor', masInfo:'Hueso prominente en el lateral de la cadera',trigger:5,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERAFASCIA.jpg'},
                    {id:2,text:'Posterior al trocanter', masInfo:'Dolor al presionar detrás del hueso prominente',trigger:5,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERATROCANTER.jpg'},
                    {id:3,text:'No me duele al tocar', masInfo:'No se reproduce el dolor en ninguno de los puntos',trigger:5,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'},
                    {id:3,text:'Es un dolor difuso', masInfo:'El dolor no es fácil de localizar en un punto',trigger:5,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERALATERAL3.jpg'}
                ],
        template:4,
    },  
        
      {
        id:5,
        preg: [ {text:'¿Llevas más de 3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
                {text:'¿Has presentado un hematoma?',masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},   
                {text:'Por último, ¿has tenido la sensación de como si la cadera ha salido y ha vuelto a entrar?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Sensación de desplazamiento inusual de cadera (subluxación de cadera)'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERALATERAL2.jpg'
                },
                {text:'¿Has presentado bloqueos o chasquidos al mover la pierna?',
                    masInfo:{
                      state:true,
                      titulo:'Más información',
                      text:'Los chasquidos se relaci onan con crujidos al mover la cadera y los bloqueos con las restricciones de movilidad'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR2.jpg'
                },    
      ],
        trigger:6,
        template:7,
      },

    {
      id:6,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:7,
    },
    {
      id:7,
      respuestas:[],
      template:8,
    },
//TRAUMA NO

    {
        id:8,
        preg:'¿Me podrías indicar dónde son más intensos los síntomas?',
        respuestas:[{id:1,text:'Lateral localizado',masInfo:'El dolor es localizado en un punto',trigger:9,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR4.2.jpg'},
                    {id:2,text:'Postero-lateral',masInfo:'El dolor se irradia desde la parte lateral a la posterior',trigger:9,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERATROCANTER.jpg'},
                    {id:3,text:'Antero-lateral',masInfo:'El dolor se irradia desde la parte lateral a anterior',trigger:9,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERAFASCIA.jpg'},
                    {id:4,text:'Difuso',masInfo:'El dolor no es fácil de localizar en un punto',trigger:9,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERALATERAL3.jpg'},
                  ],
        template:4,
    },  
    {
        id:9,
        preg:'¿Se reproduce tu dolor al presionar en alguno de los siguientes puntos?',
        respuestas:[{id:1,text:'Músculo tensor de la fascia lata', masInfo:'Dolor al presionar sobre este músculo',trigger:10,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERAFASCIA.jpg'},
                    {id:2,text:'Posterior al trocánter', masInfo:'Dolor al presionar detrás del hueso prominente',trigger:10,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERATROCANTER.jpg'},
                    {id:3,text:'Sí, en ambos', masInfo:'Dolor al presionar los dos puntos',trigger:10,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/AMBOSCADERA.jpg'},
                    {id:4,text:'No se reproduce al presionar', masInfo:'No se reproduce el dolor al tocar en ninguno de los puntos',trigger:10,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'}
                ],
        template:4,
    },
    {
        id:10,
        preg:'¿Me podrías señalar la intensidad del dolor?',
        type:'trauma',
        template:3,
        trigger:11,
    },
      {
        id:11,
        preg:[          
              {text:'¿Llevas más de 3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
              {text:'¿Presentas bloqueos al mover la pierna?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'El bloqueo de la cadera es la imposibilidad de mover la cadera en momentos puntuales'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR2.jpg'},
                {text:'¿Y se producen chasquidos?',
                  masInfo:{
                    state:true,
                    titulo:'Más información',
                    text:'Los chasquidos se relacionan con crujidos al mover la cadera'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAANTERIOR3.jpg'
                },    

              {text:'¿Se reproduce tu dolor al abrir la pierna hacia un lado?',masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/ABRIRPIERNA.mp4'},   
              {text:'¿Y al realizar una rotación interna o rotación externa de cadera?',masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/ANTERIOR/CADERAROTINT.mp4'}, 
              {text:'Por último, ¿has tenido la sensación de como si la cadera ha salido y ha vuelto a entrar?',
                masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'Sensación de desplazamiento inusual de cadera (subluxación de cadera)'
                },
                imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CADERA/LATERAL/CADERALATERAL2.jpg'
              },
],
        trigger:12,
        template:7,
      },

    {
      id:12,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:13,
    },
    {
      id:13,
      respuestas:[],
      template:8,
    }
]

export default caderaLateral