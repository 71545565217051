import React, {Text} from 'react';
import './../../css/main.scss';

const dedoGordo = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
    respuestas:[{id:1,text:'Un traumatismo o mal gesto',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un golpe, caída o mal gesto del 1º dedo'},
                {id:2,text:'Ha empezado con el tiempo',trigger:7,asociacion:1,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                {id:3,text:'No lo sé',trigger:7,asociacion:1,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },
    //TRAUMATISMO SI
    {
        id:1,
        preg:'¿Me podrías indicar el grado del traumatismo recibido?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'¿Y la inflamación que has presentado en el 1º dedo?',
        type:'inflamacion',
        template:3,
        trigger:3,
    },
    {
        id:3,
        preg:'¿Cuál es el grado de dolor al mover el 1º dedo en el momento de la lesión (flexión-extensión)?',
        type:'trauma',
        template:3,
        trigger:4,
    },
      {
        id:4,
        preg:[ 
          {text:'¿Llevas más de 3 meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/TIEMPOREPETIDO.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'Por último, ¿has presentado imposibilidad para apoyar el peso del cuerpo en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/1%C2%BA+DEDO/PANTORRILLAANTERIOR2.jpg'},
],
        trigger:5,
        template:7,
      },

    {
      id:5,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:6,
    },
    {
      id:6,
      respuestas:[],
      template:8,
      trigger:5,
    },
    
    //TRAUMA NO
    {
        id:7,
        preg:'¿En qué parte son más intensos los síntomas?',
        respuestas:[{id:1,text:'En la base del dedo gordo',masInfo:'En la unión del primer dedo con el antepie',trigger:8,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/1%C2%BA+DEDO/DEDO2BASE.jpg'},
                    {id:2,text:'Medial al dedo',masInfo:'En la prominencia del juanete',trigger:8,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/1%C2%BA+DEDO/DEDO2MEDIAL.jpg'},
                    {id:3,text:'Difuso',masInfo:'El dolor es difícil de localizar en un punto concreto',trigger:8,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/1%C2%BA+DEDO/DEDO2DIFUSO.jpg'}],
        template:4,
      },
      {
        id:8,
        preg: 
        [ 
          {text:'¿Realizas actividad física de media/alta intensidad (3-4 veces por semana)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/SPRINTREPETIDODEFINITVO.jpg'},
          {text:'¿Has aumentado el volumen de actividad física?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
          {text:'¿Presentas una desviación del primer dedo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/1%C2%BA+DEDO/JUANETE.jpg'},
          {text:'¿Y rigidez en las primeras horas de la mañana?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/1%C2%BA+DEDO/DEDO3.jpg'},
          {text:'¿El dolor empeora al calzar zapatos estrechos?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/1%C2%BA+DEDO/DEDO4.jpg'},
          {text:'Por último, ¿aumenta el dolor al llevar o mover el dedo hacia arriba (extensión)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/1%C2%BA+DEDO/DEDO5.jpg'},
],
        trigger:9,
        template:7,
      },

    {
      id:9,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:10,
    },
    {
      id:10,
      respuestas:[],
      template:8,
    }]

export default dedoGordo