import React, {Text} from 'react';
import './../../css/main.scss';

const planta = [
    //DEFAULT//
    {
        id:0,
        preg:'¿Me podrías indicar en qué parte son más intensos los síntomas?',
        respuestas:[{id:1,text:'Justo en el talón', masInfo:'En la planta del pie a la altura del talón', trigger:1,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/PLANTA+PIE/PLANTA1TALON.jpg'},
                    {id:2,text:'En el centro de la planta', masInfo:'Dolor localizado en la planta del pie', trigger:1,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/PLANTA+PIE/PLANTA1PLANTA.jpg'},
                    {id:3,text:'Difuso', masInfo:'El dolor es general y difícil de localizar en un punto concreto', trigger:1,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/PLANTA+PIE/PLANTA1DIFUSO.jpg'}],
        template:4,
      },
    {
        id:1,
        preg:'¿Cuál es la intensidad del dolor?',
        type:'trauma',
        template:3,
        trigger:2,
    },
    {
        id:2,
        preg:'En las primeras horas de la mañana mi dolor...',
        respuestas:[{id:1,text:'Empeora', masInfo:'El dolor es más intenso', trigger:3,asociacion:0},
                    {id:2,text:'Mejora', masInfo:'El dolor es más suave', trigger:3,asociacion:1},
                    {id:3,text:'Indiferente', masInfo:'El dolor es muy similar', trigger:3,asociacion:2}],
        template:10,
    },
    {
        id:3,
        preg: [ 
          {text:'¿Habías aumentado el volumen de actividad física antes de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/DEPORTEREPETIDO.jpg'},
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Se reproducen tus síntomas al realizar una compresión del arco transversal?',
              masInfo:{
                state:true,
                titulo:'Ver test',
                text:'-Realizar una presión desde ambos lados del pie a la altura de la base de los dedos.'
              },
              imagen:'x',
              video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/PLANTA+PIE/ARCOTRANSVERSAL.mp4'
          },
          {text:'¿El dolor empeora al llevar los dedos hacia arriba (hiperextensión pasiva) con la mano?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Sobretodo el dolor se reproduce al elevar el dedo gordo'
              },
              imagen:'x',
              video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/PLANTA+PIE/EXTENSIONDEDOS.mp4'
          },
          {text:'¿Presentas sensación de hormigueo o adormecimiento en la planta del pie?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/PLANTA+PIE/PLANTA3.jpg'},
          {text:'¿Has sentido sensación de chasquido o rotura en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/PLANTA+PIE/PLANTA4.jpg'},
    ],
        trigger:4,
        template:7,
    },
    {
      id:4,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:5,
    },
    {
      id:5,
      respuestas:[],
      template:8,
    },
]
export default planta