import React, {Text} from 'react';
import './../../css/main.scss';

const rodillaExterna = [
    //DEFAULT//
    {
      id:0,
      preg:'¿Con qué asocias tu lesión?',
      respuestas:[{id:1,text:'Un traumatismo',trigger:1,asociacion:0,masInfo:'El inicio del dolor se asocia a un traumatismo o golpe directo sobre la rodilla'},
                  {id:2,text:'Un mal gesto',trigger:7,asociacion:1,masInfo:'El inicio del dolor se asocia a un mal gesto de la rodilla'},
                  {id:3,text:'Ha empezado con el tiempo',trigger:13,asociacion:2,masInfo:'El dolor ha empezado de forma progresiva y ha ido empeorando con el tiempo'},
                  {id:4,text:'No lo sé',trigger:13,asociacion:2,masInfo:'Desconozco la causa del inicio del dolor o no es ninguna de las anteriores'}],
      template:10,
    },  
        //TRAUMATISMO SI

    {
        id:1,
        preg:'¿Me podrías indicar cómo ha sido el traumatismo?',
        respuestas:[{id:1,text:'Produciendo un valgo',masInfo:'Un golpe que ha producido llevar la rodilla hacia dentro',trigger:2,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLAVALGO.jpg'},
                    {id:2,text:'Produciendo un varo',masInfo:'Un golpe que ha producido llevar la rodilla hacia fuera',trigger:2,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLAVARO.jpg'},
                    {id:3,text:'Caída fuerte', masInfo:'Una caída con choque o mal gesto de la rodilla',trigger:2,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLACAIDA.jpg'},
                    {id:4,text:'Otro',masInfo:'Ninguna de las anteriores',trigger:2,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/OTRO.jpg'},

                ],
        template:4,
    },    
    {
        id:2,
        preg:'¿Y su grado de dolor?',
        type:'trauma',
        template:3,
        trigger:3,
    },       
    {
        id:3,
        preg:'¿Has presentado hinchazón o inflamación de la rodilla?',
        respuestas:[{id:1,text:'Si, de innmediato grande',masInfo:'Inflamación fuerte en pocos minutos ',trigger:4,asociacion:0},
                    {id:2,text:'Si, de inmediato pequeño',masInfo:'nflamación leve en pocos minutos',trigger:4,asociacion:1},
                    {id:2,text:'A partir de 12h',masInfo:'La inflamación ha aparecido con el paso del tiempo',trigger:4,asociacion:2},
                    {id:2,text:'No',masInfo:'No se ha presentado ninguna inflamación',trigger:4,asociacion:3}
                ],
        template:10,
    },  

      {
        id:4,
        preg: [ 
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Y una sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLALATERAL1.jpg'},
          {text:'¿Has sentido un desplazamiento inusual de la rótula?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12ROTULA.jpg'},
          {text:'¿Has tenido incapacidad para mover la rodilla en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA2.jpg'},
          {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?',
              masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'El dolor no te permite apoyar el peso de tu cuerpo en el suelo'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA3.jpg'
          },
          {text:'Por último, ¿presentas inestabilidad de la rodilla?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA4.jpg'},
  ],
        trigger:5,
        template:7,
    },
    {
      id:5,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:6,
    },
    {
      id:6,
      respuestas:[],
      template:8,
    },
            //MAL GESTO SI

    {
        id:7,
        preg:'¿Cómo ha sido el gesto?',
        respuestas:[{id:1,text:'Girar sobre si mismo con el pie apoyado',masInfo:'Un mal gesto al girar con el pie apoyado',trigger:8,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12GIRO.jpg'},
                    {id:2,text:'Varo de rodilla',masInfo:'Un mal gesto de la rodilla hacia fuera',trigger:8,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLAVARO.jpg'},
                    {id:3,text:'Desplazamiento inusual de la rodilla',masInfo:'Como si la rodilla se hubiese desplazado',trigger:8,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12RODILLA.jpg'},
                    {id:3,text:'Desplazamiento inusual de la rótula',masInfo:'Como si la rótula se hubiese desplazado',trigger:8,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA12ROTULA.jpg'},
                    {id:4,text:'Otro',masInfo:'No estoy seguro o ha sido un gesto diferente',trigger:8,asociacion:4,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/OTRO.jpg'}
                ],
        template:4,
    },  
    {
        id:8,
        preg:'¿Me podrías indicar la intensidad del dolor en el momento de la lesión?',
        type:'trauma',
        template:3,
        trigger:9,
    },
    {
        id:9,
        preg:'¿Has presentado hinchazón o inflamación?',
        respuestas:[{id:1,text:'Si, de innmediato y grande',masInfo:'Una inflamación fuerte en pocos minutos ',trigger:10,asociacion:0},
                    {id:2,text:'Si, de inmediato y pequeño',masInfo:'Una inflamación leve en pocos minutos',trigger:10,asociacion:1},
                    {id:3,text:'A partir de 12h',masInfo:'La inflamación ha aparecido con el tiempo',trigger:10,asociacion:2},
                    {id:4,text:'No',trigger:10,masInfo:'No he tenido ninguna inflamación',asociacion:3}
                ],
        template:10,
    },  
    {
        id:10,
        preg: [ 
          {text:'¿Has presentado un hematoma?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/HEMATOMA.jpg'},
          {text:'¿Y una sensación de crack, chasquido o pop en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLALATERAL1.jpg'},
          {text:'¿Has tenido incapacidad para mover la rodilla en el momento de la lesión?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA2.jpg'},
          {text:'¿Y para apoyar el peso del cuerpo en el momento de la lesión?',
              masInfo:{
                  state:true,
                  titulo:'Más información',
                  text:'El dolor no te permite apoyar el peso de tu cuerpo en el suelo'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA3.jpg'
          },
          {text:'Por último, ¿presentas inestabilidad de la rodilla?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA4.jpg'},
  ],
        trigger:11,
        template:7,
    },

    {
      id:11,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:12,
    },
    {
      id:12,
      respuestas:[],
      template:8,
    },
    //TRAUMA NO
    {
        id:13,
        preg:'¿El dolor se refiere a alguna otra zona?',
        respuestas:[{id:1,text:'No, es localizado en el lateral',masInfo:'El dolor se localiza en la parte externa de la rodilla',trigger:14,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLALATERAL2LATERAL.jpg'},
                    {id:2,text:'Si, a la parte posterior',masInfo:'El dolor se irradia desde la parte lateral hacia el posterior',trigger:14,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLALATERAL2POSTERIOR.jpg'},
                    {id:3,text:'Si, a la parte anterior',masInfo:'El dolor se irradia desde la parte lateral hacia el anterior',trigger:14,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLALATERAL2ANTERIOR.jpg'}],
        template:4,
    },
    {
        id:14,
        preg:'¿Realizas alguna de las siguientes actividades deportivas?(3-4 veces a la semana)',
        respuestas:[{id:1,text:'Actividades cíclicas',masInfo:'caminar, running, ciclismo, natación, etc.',trigger:15,asociacion:0},
                    {id:2,text:'Actividades con cambios de dirección',masInfo:'fútbol, rugby, baloncesto, balonmano, etc.',trigger:15,asociacion:1},
                    {id:2,text:'Realizo otras actividades deportivas',masInfo:'Actividades que no impliquen correr',trigger:15,asociacion:2},
                    {id:2,text:'No',masInfo:'No realizo ningún deporte',trigger:15,asociacion:3}],
        template:10,
    },
      {
        id:15,
        preg:'¿Cómo evoluciona tu dolor al realizar actividad?',
        respuestas:[{id:1,text:'El dolor mejora',masInfo:'El dolor se alivia al realizar la actividad',trigger:16,asociacion:0},
                    {id:2,text:'El dolor empeora',masInfo:'El dolor se empeora al realizar la actividad',trigger:16,asociacion:1},
                    {id:2,text:'El dolor es muy similar',masInfo:'La actividad no afecta a la intensidad del dolor',trigger:16,asociacion:2}],
        template:10,
      },
      {
        id:16,
        preg: [ 
          {text:'¿Has presentado inflamación en la parte externa de la rodilla?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLALATERAL3.jpg'},
          {text:'Perfecto, ¿se reproduce tu dolor al ponerte de cuclillas?',
              masInfo:{
                  state:true,
                  titulo:'¿No puedes ponerte de cuclillas?',
                  text:'Si no puedes ponerte de cuclillas prueba a flexionar al máximo la rodilla'
              },
              imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/CUCLILLAS.jpg'
          },
          {text:'¿Presentas sensación de bloqueo al flexionar y extender la rodilla?', masInfo:{state:false},imagen:'x',video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/ANTERIOR/RODILLA8.mp4'},
          {text:'¿El dolor se puede localizar mediante la palpación (tocando con la mano)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/RODILLALATERAL4.jpg'},
          {text:'Por último, ¿me podrías indicar si se reproduce tu dolor al realizar una flexión de rodilla contra resistencia?',
              masInfo:{
                state:true,
                titulo:'Más información',
                text:'Realiza fuerza contra tu mano'
              },
              imagen:'x',
              video:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/RODILLA/LATERAL/FLEXIONRODILLA.mp4'
          },
     ],
        trigger:17,
        template:7,
    },
    {
      id:17,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:18,
    },
    {
      id:18,
      respuestas:[],
      template:8,
    }]

export default rodillaExterna