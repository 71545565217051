import React, {Text} from 'react';
import './../../css/main.scss';

const dorsalInferior = [
    {
        id:0,
        preg:'¿Me podrías indicar si has recibido un traumatismo directo sobre las costillas?',
        respuestas:[{id:1,text:'No',masInfo:'No he recibido ningún golpe',trigger:1,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'},
                    {id:2,text:'Sí, un traumatismo leve',masInfo:'He recibido un golpe débil',trigger:1,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                    {id:3,text:'Sí, un traumatismo moderado',masInfo:'He tenido un golpe importante',trigger:1,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/moderado.jpg'},
                    {id:4,text:'Sí, un traumatismo intenso',masInfo:'He tenido uno de los peores golpes que haya tenido nunca',trigger:1,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'}],
        template:4,
      },  
    { 
        id:1,
        preg: [{text:'¿Llevas más de dos meses con los síntomas?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/TIEMPOREPETIDO.jpg'},
                {text:'¿Se reproduce tu dolor al realizar una inclinación lateral con el brazo levantado?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL2.jpg'},
                {text:'¿Realizas actividades físicas que implican movimientos de los miembros superiores?',
                  masInfo:{
                    state:true,
                    titulo:'Ver ejemplos',
                    text:'Actividades deportivas que impliquen levantamiento de pesas, escalada, natación, etc.'
                  },
                  imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL3.jpg'
                },   
                {text:'¿El dolor se irradia hacia el brazo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL4.jpg'},
                {text:'¿Permaneces mucho tiempo sentado (4-5 horas al día)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZA9.jpg'},
                {text:'¿Y el dolor empeora al estar de pie durante largos periodos de tiempo (2-3 horas)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL5.jpg'},
                {text:'Perfecto, ¿el dolor se presenta a lo largo del recorrido de la columna dorsal?',
                    masInfo:{
                      state:true,
                      titulo:'Más información',
                      text:'El dolor se localiza justo a un lado de la columna'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSALINFERIOR1.jpg'
                },   
],
        trigger:2,
        template:7,
      },

    {
      id:2,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:3,
    },
    {
      id:3,
      respuestas:[],
      template:8,
    },
]

export default dorsalInferior