import React, {Text} from 'react';
import './../../css/main.scss';

const dorsalInterescapular = [
    {
        id:0,
        preg:'¿Me podrías indicar si has recibido un traumatismo directo sobre el tórax?',
        respuestas:[{id:1,text:'No', masInfo:'No he recibido ningún golpe',trigger:1,asociacion:0,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/NO.jpg'},
                    {id:2,text:'Sí, leve', masInfo:'He recibido un golpe débil',trigger:1,asociacion:1,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/leve.jpg'},
                    {id:3,text:'Sí, moderado', masInfo:'He tenido un golpe importante', trigger:1,asociacion:2,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/moderado.jpg'},
                    {id:4,text:'Sí, intenso', masInfo:'He tenido uno de los peores golpes que haya tenido nunca', trigger:1,asociacion:3,imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/GENERAL/intenso.jpg'}],
        template:4,
      },  
    { 
        id:1,
        preg: [ {text:'¿Llevas más de dos meses con el dolor?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/TIEMPOREPETIDO.jpg'},
                {text:'¿Se reproduce tu dolor al realizar una inclinación lateral con el brazo levantado?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL2.jpg'},
                {text:'¿Realizas actividades físicas que implican movimientos de los miembros superiores?',
                    masInfo:{
                      state:true,
                      titulo:'Ver ejemplos',
                      text:'Actividades deportivas que impliquen levantamiento de pesas, escalada, natación, etc.'
                    },
                    imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL3.jpg'
                },   
                {text:'¿El dolor empeora al estar de pie durante largos periodos de tiempo (2-3 horas)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL5.jpg'},
                {text:'¿Y al juntar las escapulas?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL6.jpg'},
                {text:'Perfecto, ¿el dolor se irradia hacia el brazo?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/DORSAL/INFERIOR/DORSAL4.jpg'},
                {text:'Por último, ¿permaneces mucho tiempo sentado (4-5 horas al día)?', masInfo:{state:false},imagen:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/CERVICAL/CABEZA-CERVICAL/CABEZA9.jpg'},
        ],
        trigger:2,
        template:7,
      },

    {
      id:2,
      preg:[<p className='text-aurya'>Voy a procesar tus <span style={{fontWeight: 700}}>síntomas</span> para darte una respuesta</p>],
      respuestas:[],
      template:9,
      trigger:3,
    },
    {
      id:3,
      respuestas:[],
      template:8,
    },
]

export default dorsalInterescapular