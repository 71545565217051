const muñeca = [
  {
  id:0,
  preg:'',
  nombre:'muñeca',
  respuestas:[{id:1,text:'Parte dorsal',trigger:1, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MU%C3%91ECA/mu%C3%B1ecaDorsal.jpg',masInfo:'Dolor de muñeca en la parte del dorso'},
              {id:2,text:'Parte palmar',trigger:2, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/MU%C3%91ECA/mu%C3%B1ecaPalmar.jpg',masInfo:'Dolor de muñeca en la parte de la palma'}],
  template:2,
}]

export default muñeca