const pie = [
  {
  id:0,
  preg:'',
  nombre:'pie',
  respuestas:[{id:1,text:'Empeine',trigger:1, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/empeine.jpg',masInfo:'Dolor en la parte de encima del pie'},
              {id:2,text:'Planta del pie',trigger:2, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/plantaPie.jpg',masInfo:'Dolor en la parte de abajo del pie'},
              {id:3,text:'Talón',trigger:3, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/talon.jpg',masInfo:'Dolor en la parte de atrás del pie'},
              {id:4,text:'Dedo gordo',trigger:4, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/dedoGordo.jpg',masInfo:'Dolor en el dedo gordo del pie'},
              {id:5,text:'Dedos',trigger:5, img:'https://imagenes-fisify.s3.eu-west-3.amazonaws.com/PIE/Dedos.jpg',masInfo:'Dolor en los otros cuatros dedos del pie'}],
  template:2,
}]

export default pie