import React, {Component} from 'react';
import '../../css/pantallaFinal.scss';
import '../../css/main.scss';
import Donut from 'react-animated-donut';
import {Spring} from 'react-spring/renderprops';
import logoLight from './../../img/intrologo.png';
import logo from './../../img/logoAzulOscuro.png';
import Fade from 'react-reveal/Fade';
import iconInfo from '../../img/libro.png';
import iconCurar from '../../img/icon-curar.png';
import logoOscuro from '../../img/logoOscuro.png';
import flecha from '../../img/flecha-sintomas.png';
import programaEjercicios from '../../img/imagenChicaEntrenamiento.png'
import videollamada from '../../img/videollamada.png';
import curarText from './CurarText';

class MainResultados extends Component{
    constructor(props){
        super(props)
        this.state ={
            PrimeraProbabilidad:0,
            SegundaProbabilidad:0,
            TerceraProbabilidad:0,
            screen:0,
            cssSelector:1,
            patologySelected:0,
            preguntaCss:'finalTextBlock',
            selector:0,
            textDisplayTrigger:0,
        }
        this.handleInformacion = this.handleInformacion.bind(this);
    }
    handleInformacion(parte){
        console.log('ha entrado')       
        if(parte==='info' && this.state.cssSelector != 1){
            this.setState({cssSelector:1})
            window.scrollTo(0, 0);
        }
        if(parte==='como-curar' && this.state.cssSelector != 2){   
            this.setState({cssSelector:2})
            window.scrollTo(0, 0);
        }       
    }
    handlePatology(number){       
        if(number===1){
            this.setState({patologySelected:0})
            
        }
        if(number===2){
            this.setState({patologySelected:1})
            
        }
        if(number===3){
            this.setState({patologySelected:2})
        }  
        window.scrollTo(0, 0);    
    }

    componentDidMount(){
        this.setState({
            PrimeraProbabilidad:this.props.state.probabilidades[0],
            SegundaProbabilidad:this.props.state.probabilidades[1],
            TerceraProbabilidad:this.props.state.probabilidades[2],
        })


        let timer = setTimeout(this.setState.bind(this, {preguntaCss:'finalTextBlock-active'}), 3000);
        let timer2 = setTimeout(this.setState.bind(this, {textDisplayTrigger:1}), 8000);


    }

    componentWillUnmount(){
        clearTimeout(this.timer);
        clearTimeout(this.timer2);
    }
      
    render(){
        
        const colorCirculos=['rgba(186, 220, 183, 1)','rgba(205, 195, 224, 1)','rgba(215, 174, 174, 1)']
        const probCirculos =[this.state.PrimeraProbabilidad,this.state.SegundaProbabilidad,this.state.TerceraProbabilidad]
        const textDisplay = [
            <p className='text-aurya-small'> Estas son las <span style={{fontWeight:'700'}}>tres patologías</span> más probables según tu sintomatología</p>,
            <p className='text-aurya-small'> Elige una <span style={{fontWeight:'700'}}>lesión</span> para obtener más información</p>
        ]
        if(this.state.screen===0){
            return(
                <div className='primaryContainer'>
                    <div className='logo'>
                        <a href='https://diagnosis.fisify.com'>
                            <img src={logo} width='115px' height='40px'/>
                        </a>
                    </div> 
                    <div className={this.state.preguntaCss}>
                        <div className='lineFinal'>
                            {textDisplay[this.state.textDisplayTrigger]}
                        </div>                                              
                    </div>
                    <Fade bottom distance={'25px'} duration={800} delay={900}>
                    <div className='emptyBlock-final'>
                        <div className='patology3-container'>
                            <div className='patology-container' onClick={()=>this.setState({screen:1},()=>this.handlePatology(1))}>
                                <div className='nombre-patology'>
                                    <div className='number-uno'>
                                        <p className='text-number'>01</p>
                                    </div>
                                    <p className='titulo-patology'>
                                        {this.props.state.patologia[0].titulo}
                                    </p>
                                    <p className='subtitulo-patology-uno'>
                                        {this.props.state.patologia[0].subtitulo}
                                    </p>
                                </div>
                                <div className='porcentaje-patology'>
                                     <div className='probabilidad-container'>
                                         <div className='donut'>
                                                <Spring 
                                                    delay='1000'
                                                    style={{justifySelf:'center'}}
                                                    config={{tension: 100, friction: 42, precision: 0.5}}
                                                    from={{ value: 0,vol:100}}
                                                    to={{ value: this.state.PrimeraProbabilidad,vol:100-this.state.PrimeraProbabilidad}}>
                        
                                                 {props => <Donut width={5} data={[
                                                    { value: props.value, color: colorCirculos[0]},
                                                    {value:props.vol,color:'rgba(255, 255, 255, 0.244)'}

                                                ]}> </Donut >}
                                                       
                                                </Spring> 
                                                
                                                <Spring 
                                                            delay='1000'
                                                            config={{tension: 100, friction: 92, precision: 0.5}}
                                                            from={{ number: 0 }}
                                                            to={{ number: this.state.PrimeraProbabilidad }}>
                                                            {props => <p className='probabilidad-grande'>{Math.floor(props.number)}%</p>}
                                                </Spring>
                                                
                                            </div>
                                        </div>
                                 </div>
                            </div>
                            <div className='patology-container' onClick={()=>this.setState({screen:1},()=>this.handlePatology(2))}>
                                <div className='nombre-patology'>
                                    <div className='number-dos'>
                                        <p className='text-number'>02</p>
                                    </div>
                                    <p className='titulo-patology'>
                                         {this.props.state.patologia[1].titulo}
                                    </p>
                                    <p className='subtitulo-patology-dos'>
                                         {this.props.state.patologia[1].subtitulo}
                                    </p>
                                </div>
                                <div className='porcentaje-patology'>
                                <div className='probabilidad-container'>
                                         <div className='donut'>
                                         <Spring 
                                                delay='1000'
                                                style={{justifySelf:'center'}}
                                                config={{tension: 100, friction: 42, precision: 0.5}}
                                                from={{ value: 0,vol:100 }}
                                                to={{ value: this.state.SegundaProbabilidad,vol:100 - this.state.SegundaProbabilidad }}>
                    
                                                {props => <Donut width={5} data={[
                                                    { value: props.value, color: colorCirculos[1] },
                                                    {value:props.vol,color:'rgba(255, 255, 255, 0.244)'}

                                                ]}> </Donut >}
                                        </Spring>
                                        
                                            <Spring 
                                                    delay='1000'
                                                    config={{tension: 100, friction: 92, precision: 0.5}}
                                                    from={{ number: 0 }}
                                                    to={{ number: this.state.SegundaProbabilidad }}>
                                                    {props => <p className='probabilidad-grande'>{Math.floor(props.number)}%</p>}
                                            </Spring>
                                                                    
                                        </div>
                                </div>                     
                                </div>
                            </div>
                            <div className='patology-container' onClick={()=>this.setState({screen:1},()=>this.handlePatology(3))}>
                                <div className='nombre-patology'>
                                    <div className='number-tres'>
                                         <p className='text-number'>03</p>
                                    </div>
                                    <p className='titulo-patology'>
                                        {this.props.state.patologia[2].titulo}
                                    </p>
                                    <p className='subtitulo-patology-tres'>
                                        {this.props.state.patologia[2].subtitulo}
                                    </p>
                                </div>
                                <div className='porcentaje-patology'>
                                    <div className='probabilidad-container'>
                                         <div className='donut'>
                                        <Spring 
                                            delay='1000'
                                            style={{justifySelf:'center'}}
                                            config={{tension: 100, friction: 42, precision: 0.5}}
                                            from={{ value: 0,vol:100 }}
                                            to={{ value: this.state.TerceraProbabilidad,vol:100 - this.state.TerceraProbabilidad }}>
                
                                            {props => <Donut width={5} data={[
                                                { value: props.value, color: colorCirculos[2],},
                                                {value:props.vol,color:'rgba(255, 255, 255, 0.244)'}]}>
                                                </Donut >}
                                        </Spring>
                                    
                                        <Spring 
                                                    delay='1000'
                                                    config={{tension: 100, friction: 42, precision: 0.5}}
                                                    from={{ number: 0 }}
                                                    to={{ number: this.state.TerceraProbabilidad }}>
                                                    {props => <p className='probabilidad-grande'>{Math.floor(props.number)}%</p>}
                                        </Spring>
                                                 
                                        </div>
                                    </div>              
                                </div>
                            </div>
                        </div>
                    </div>
                    </Fade>
                    </div>
            )
        }
        
        if(this.state.screen === 1){
            //CSS
            const informacionCss = ['informacion-container','informacion-container-selected','informacion-container-shorted']
            const comoCurarCss = ['curar-container','curar-container-shorted','curar-container-selected']
            //patologyCss depende de si estamos en info o como curar y depende de que patologia está seleccionada.(3x3)

                                    //Inicial                                                   //Informacion                                             //Como curar
            const patology1Css = [['patology-1-active','patology-small-1','patology-small-1'],['patology-small-1-active','patology-small-1','patology-small-1'],['patology-1-active','patology-small-1','patology-small-1']]
            const patology2Css = [['patology-small-2','patology-2-active','patology-small-2'],['patology-small-2','patology-small-2-active','patology-small-2'],['patology-small-2','patology-2-active','patology-small-2']]
            const patology3Css = [['patology-small-3','patology-small-3','patology-3-active'],['patology-small-3','patology-small-3','patology-small-3-active'],['patology-small-3','patology-small-3','patology-3-active']]
            
            //Mobile Circulos probabilidad Css
            const cssSubtitulo = ['subtitulo-patology-uno-small','subtitulo-patology-dos-small','subtitulo-patology-tres-small']
            
            //info de patologias para circulos
            const infoCirculos = [[{prob:this.state.SegundaProbabilidad,color:colorCirculos[1],patologySelector:1},{prob:this.state.PrimeraProbabilidad,color:colorCirculos[0],patologySelector:0},{prob:this.state.TerceraProbabilidad,color:colorCirculos[2],patologySelector:2}],
                                [{prob:this.state.PrimeraProbabilidad,color:colorCirculos[0],patologySelector:0},{prob:this.state.SegundaProbabilidad,color:colorCirculos[1],patologySelector:1},{prob:this.state.TerceraProbabilidad,color:colorCirculos[2],patologySelector:2}],
                                [{prob:this.state.PrimeraProbabilidad,color:colorCirculos[0],patologySelector:0},{prob:this.state.TerceraProbabilidad,color:colorCirculos[2],patologySelector:2},{prob:this.state.SegundaProbabilidad,color:colorCirculos[1],patologySelector:1}]]

            const cssSelector = ['light-selector','dark-selector']                    
            return(
                //DESKTOP
                <>
                <div className='primaryContainer desktop'>
                    <div className='white-margin'>
                        <a href='https://diagnosis.fisify.com'>
                            <img src={logoOscuro} className='logoFinal' />
                        </a>
                        <div className={patology1Css[this.state.cssSelector][this.state.patologySelected]} onClick={()=>this.handlePatology(1)}>
                                {(this.state.patologySelected == 0 && this.state.cssSelector != 1)&&
                                <Fade right delay={400} distance={'8px'}> 
                                    <div className='nombre-patology'>
                                        <p className='titulo-patology-small'>
                                            {this.props.state.patologia[0].titulo}
                                        </p>
                                        <p className='subtitulo-patology-uno-small'>
                                            {this.props.state.patologia[0].subtitulo}
                                        </p>
                                    </div>
                                </Fade>}   
                            <div className='porcentaje-patology-small'>
                                <div className='probabilidad-container-small'>
                                    <div className='donut'>
                                        <Spring 
                                            delay='1000'
                                            style={{justifySelf:'center'}}
                                            config={{tension: 100, friction: 42, precision: 0.5}}
                                            from={{ value: 0,vol:100 }}
                                            to={{ value: this.state.PrimeraProbabilidad,vol:100 - this.state.PrimeraProbabilidad }}>
                
                                            {props => <Donut width={5} data={[
                                                { value: props.value, color: colorCirculos[0] },
                                                {value:props.vol,color:'rgba(255, 255, 255, 0.244)'}]}>
                                            </Donut >}
                                        </Spring>                                               
                                        <Spring 
                                            delay='1000'
                                            config={{tension: 100, friction: 92, precision: 0.5}}
                                            from={{ number: 0 }}
                                            to={{ number: this.state.PrimeraProbabilidad }}>
                                            {props => <p className='probabilidad-patologia'>{Math.floor(props.number)}%</p>}
                                        </Spring>
                                                 
                                    </div>
                                </div>              
                            </div>
                        </div>
                        <div className={patology2Css[this.state.cssSelector][this.state.patologySelected]} onClick={()=>this.handlePatology(2)}>
                             {(this.state.patologySelected == 1 && this.state.cssSelector !=1) &&
                                <Fade right delay={400} distance={'8px'}> 
                                    <div className='nombre-patology'>
                                        <p className='titulo-patology-small'>
                                            {this.props.state.patologia[1].titulo}
                                        </p>
                                        <p className='subtitulo-patology-dos-small'>
                                            {this.props.state.patologia[1].subtitulo}
                                        </p>
                                    </div>
                                </Fade>}   
                            <div className='porcentaje-patology-small'>
                                <div className='probabilidad-container-small'>
                                    <div className='donut'>
                                        <Spring 
                                                delay='1000'
                                                style={{justifySelf:'center'}}
                                                config={{tension: 100, friction: 42, precision: 0.5}}
                                                from={{ value: 0,vol:100 }}
                                                to={{ value: this.state.SegundaProbabilidad,vol:100- this.state.SegundaProbabilidad }}>
                    
                                                {props => <Donut width={5} data={[
                                                    { value: props.value, color: colorCirculos[1] },
                                                    {value:props.vol,color:'rgba(255, 255, 255, 0.244)'}

                                                ]}> </Donut >}
                                        </Spring>
                                        
                                        <Spring 
                                                delay='1000'
                                                config={{tension: 100, friction: 92, precision: 0.5}}
                                                from={{ number: 0 }}
                                                to={{ number: this.state.SegundaProbabilidad }}>
                                                {props => <p className='probabilidad-patologia'>{Math.floor(props.number)}%</p>}
                                        </Spring>            
                                    </div>
                                </div>              
                            </div>
                        </div>
                        <div className={patology3Css[this.state.cssSelector][this.state.patologySelected]} onClick={()=>this.handlePatology(3)}>
                            {this.state.patologySelected == 2 && this.state.cssSelector != 1?
                                <Fade right delay={400} distance={'8px'}>  
                                    <div className='nombre-patology'>
                                        <p className='titulo-patology-small'>
                                            {this.props.state.patologia[2].titulo}
                                        </p>
                                        <p className='subtitulo-patology-tres-small'>
                                            {this.props.state.patologia[2].subtitulo}
                                        </p>
                                    </div>
                                </Fade>:''}                          
                            
                            <div className='porcentaje-patology-small'>
                                <div className='probabilidad-container-small'>
                                    <div className='donut'>
                                        <Spring 
                                            delay='1000'
                                            style={{justifySelf:'center'}}
                                            config={{tension: 100, friction: 42, precision: 0.5}}
                                            from={{ value: 0,vol:100 }}
                                            to={{ value: this.state.TerceraProbabilidad,vol:100-this.state.TerceraProbabilidad }}>
                
                                            {props => <Donut width={5} data={[
                                                { value: props.value, color: colorCirculos[2],},
                                                {value:props.vol,color:'rgba(255, 255, 255, 0.244)'}]}>
                                                </Donut >}
                                        </Spring>
                                    
                                        <Spring 
                                            delay='1000'
                                            config={{tension: 100, friction: 42, precision: 0.5}}
                                            from={{ number: 0 }}
                                            to={{ number: this.state.TerceraProbabilidad }}>
                                            {props => <p className='probabilidad-patologia'>{Math.floor(props.number)}%</p>}
                                        </Spring>                                  
                                    </div>
                                </div>              
                            </div>
                        </div>
                    </div>
                    <div className='final-container'>
                        <div className={informacionCss[this.state.cssSelector]} onClick={()=>this.handleInformacion('info')}>
                            <div className={this.state.cssSelector==2?'titulo-container-fixed':'titulo-container'}>
                                {this.state.cssSelector %2 == 0 ?
                                <>
                                    <img src={logoOscuro} className={this.state.cssLogo} width='50px' /><br></br>
                                    <p className='titulo-info'>
                                        Información
                                    </p>
                                    <p className='subtitulo-info'>
                                        Informate sobre tu lesión
                                    </p>
                                </>
                                :
                                <Fade bottom distance={'17px'} duration={900} delay={1200}>
                                <>
                                <div className='def-prob-container'>
                                    <div className='defContainer'>
                                        <div className='etiqueta queEs'>
                                            <p>¿Qué es?</p>
                                        </div>
                                        <p className='titulo-info-inside'>{this.props.state.patologia[this.state.patologySelected].titulo}</p>
                                        <p className='subtitulo-inside'>{this.props.state.patologia[this.state.patologySelected].subtitulo}</p>
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.def}</p>
                                    </div>
                                    <div className='probinfo-container'>
                                        <div className='circuloprob-container'>
                                            <Spring 
                                                    delay='1000'
                                                    style={{justifySelf:'center'}}
                                                    config={{tension: 100, friction: 42, precision: 0.5}}
                                                    from={{ value: 0,vol:100 }}
                                                    to={{ value: probCirculos[this.state.patologySelected] ,vol:100 - probCirculos[this.state.patologySelected] }}>
                        
                                                    {props => <Donut width={8} data={[
                                                        {value: props.value, color: colorCirculos[this.state.patologySelected] },
                                                        {value:props.vol,color:'rgba(255, 255, 255, 0.244)'}

                                                    ]}> </Donut >}
                                            </Spring>                                
                                            <Spring 
                                                    delay='1000'
                                                    config={{tension: 100, friction: 92, precision: 0.5}}
                                                    from={{ number: 0 }}
                                                    to={{ number: probCirculos[this.state.patologySelected] }}>
                                                    {props => <p className='probabilidad-grande'>{Math.floor(props.number)}%</p>}
                                            </Spring>  
                                        </div>
                                    </div>
                                </div>
                                <div className='sintomas-container'>
                                    <div className='etiqueta sintomas'>
                                        <p>Síntomas</p>
                                    </div>
                                    <p className='text-sintomas'>Síntomas más relevantes</p>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.sintomas[0]}</p>
                                    </div>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.sintomas[1]}</p>
                                    </div>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.sintomas[2]}</p>
                                    </div>
                                </div>
                                <div className='sintomas-container'>
                                    <div className='etiqueta causas'>
                                        <p>Causas</p>
                                    </div>
                                    <p className='text-sintomas'>Causas más relevantes</p>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.causas[0]}</p>
                                    </div>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.causas[1]}</p>
                                    </div>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.causas[2]}</p>
                                    </div>
                                </div>
                                {this.props.state.patologia[this.state.patologySelected].texto.link == ''?'':
                                    <a href={this.props.state.patologia[this.state.patologySelected].texto.link} style={{textDecoration:'none'}}>
                                        <div className='boton-como-curar-container'>
                                            <div className='boton-mas-info' onClick={()=>this.handleInformacion('como-curar')}>
                                                <img src={iconInfo} />
                                                <p className=''>Más información</p>
                                            </div>
                                        </div>
                                    </a>
                                }
                                <div className='boton-como-curar-container'>
                                    <div className='boton-como-curar' onClick={()=>this.handleInformacion('como-curar')}>
                                        <img src={iconCurar} />
                                        <p className='continuar-text'>Cómo curar</p>
                                    </div>
                                </div>
                                </>
                                </Fade>
                            }
                            <div>
                        </div>
                    </div>
                        </div>
                        <div className={comoCurarCss[this.state.cssSelector]}  onClick={()=>this.handleInformacion('como-curar')}>
                            <div className={this.state.cssSelector==1?'titulo-container-fixed':'titulo-container'}>
                            {this.state.cssSelector != 2 ?
                                <>
                                    <img src={logoLight} className={this.state.cssLogo} width='50px' /><br></br>
                                    <p className='titulo-como-curar'>
                                        ¿Cómo curar?
                                    </p>
                                    <p className='subtitulo-como-curar'>
                                        Que hacer para que mejores
                                    </p>
                                </>
                                :
                                <Fade bottom distance={'17px'} duration={900} delay={1200}>
                                    <div className='container-90'>
                                        <h2>¿Cómo curar?</h2>
                                        <p>{curarText[this.props.state.patologia[this.state.patologySelected].texto.gravedad]}</p>
                                        {this.props.state.patologia[this.state.patologySelected].texto.gravedad != 2?<>
                                            <h3>Pedir un programa de ejercicios personalizado</h3>
                                            <p>Tu fisioterapeuta te programará los ejercicios más eficaces para aliviar los síntomas y recuperarte mediante una aplicación con vídeos explicativos e información acerca de tu lesión.</p>
                                            <a href='https://fisify.com/tratamiento' target="_blank" style={{textDecoration:'none'}}>
                                                <div className='servicios-container'>
                                                    <img src={programaEjercicios} className='largo' />
                                                    <h3>Quiero un programa de ejercicios personalizado</h3>
                                                    <p>Un fisioterapeuta preparará tu programa en menos de 24h</p>
                                                    <div className='etiqueta-pequeña primero'>
                                                        Personalizado
                                                    </div>
                                                    <div className='etiqueta-pequeña medio'>
                                                        1 mes
                                                    </div>
                                                    <div className='etiqueta-pequeña ultimo'>
                                                        14.99€
                                                    </div>
                                                </div>
                                            </a></>:''}

                                        {this.props.state.patologia[this.state.patologySelected].texto.gravedad != 2?<h3>Consulta por videollamada + Programa de ejercicios</h3>:<h3>Consulta por videollamada</h3>}
                                        <p>Realiza una consulta con un fisioterapeuta el cual te realizará una valoración personal ofreciéndote una respuesta precisa y una solución efectiva a largo plazo.</p>
                                        <a href='https://fisify.com/tratamiento' target="_blank" style={{textDecoration:'none'}}>
                                            <div className='servicios-container'>
                                                <img src={videollamada} className='corto'/>
                                                <h3>Videollamada con un fisioterapeuta</h3>
                                                <p>Diagnóstico preciso más un tratamiento personalizado diseñado a tu medida</p>
                                                <div className='etiqueta-pequeña primero'>
                                                    Especializado
                                                </div>
                                                <div className='etiqueta-pequeña medio'>
                                                    30 min
                                                </div>
                                                <div className='etiqueta-pequeña ultimo'>
                                                    35.99€
                                                </div>
                                            </div>
                                        </a>
                                       
                                       
                                    </div>
                                </Fade>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                {/* MOBILE */}
                <div className='primaryContainer mobile'>
                    <div className='logo'>
                        <a href='https://diagnosis.fisify.com'>
                            <img src={logo} width='115px' height='40px'/>
                        </a>
                    </div> 
                    <div className='white-margin'>
                        <Fade duration={1000} delay={400} spy={this.state.patologySelected}>
                        <div className='probCirculos-container'>
                            <div className='probabilidad-container-small-1' onClick={()=>this.setState({patologySelected:infoCirculos[this.state.patologySelected][0].patologySelector})}>
                                <div className='donut'>
                                    <Spring 
                                        delay='1000'
                                        style={{justifySelf:'center'}}
                                        config={{tension: 100, friction: 42, precision: 0.5}}
                                        from={{ value: 0,vol:100 }}
                                        to={{ value: infoCirculos[this.state.patologySelected][0].prob,vol:100 - infoCirculos[this.state.patologySelected][0].prob }}>
            
                                        {props => <Donut width={5} data={[
                                            { value: props.value, color: infoCirculos[this.state.patologySelected][0].color},
                                            {value:props.vol,color:'rgba(255, 255, 255, 0.244)'}]}>
                                        </Donut >}
                                    </Spring>                              
                                    <Spring 
                                                delay='1000'
                                                config={{tension: 100, friction: 42, precision: 0.5}}
                                                from={{ number: 0 }}
                                                to={{ number: infoCirculos[this.state.patologySelected][0].prob}}>
                                                {props => <p className='probabilidad-patologia-small'>{Math.floor(props.number)}%</p>}
                                    </Spring>                                  
                                </div>
                            </div>
                            
                            <div className='probabilidad-container-small-active' onClick={()=>this.setState({patologySelected:infoCirculos[this.state.patologySelected][1].patologySelector})}>
                                <div className='donut'>
                                    <Spring 
                                        delay='1000'
                                        style={{justifySelf:'center'}}
                                        config={{tension: 100, friction: 42, precision: 0.5}}
                                        from={{ value: 0,vol:100 }}
                                        to={{ value: infoCirculos[this.state.patologySelected][1].prob,vol:100 - infoCirculos[this.state.patologySelected][1].prob}}>
            
                                        {props => <Donut width={8} data={[
                                            { value: props.value, color: infoCirculos[this.state.patologySelected][1].color},
                                            { value:props.vol,color:'rgba(255, 255, 255, 0.144)'}

                                        ]}> </Donut >}
                                    </Spring>
                                    
                                    <Spring 
                                        delay='1000'
                                        config={{tension: 140, friction: 92, precision: 0.8}}
                                        from={{ number: 0 }}
                                        to={{ number: infoCirculos[this.state.patologySelected][1].prob }}>
                                        {props => <p className='probabilidad-grande'>{Math.floor(props.number)}%</p>}
                                    </Spring>
                                                
                                </div>
                            </div>                           
                            <div className='probabilidad-container-small-2' onClick={()=>this.setState({patologySelected:infoCirculos[this.state.patologySelected][2].patologySelector})}>
                                <div className='donut'>
                                    <Spring 
                                        delay='1000'
                                        style={{justifySelf:'center'}}
                                        config={{tension: 100, friction: 42, precision: 0.5}}
                                        from={{ value: 0,vol:100 }}
                                        to={{ value: infoCirculos[this.state.patologySelected][2].prob,vol:100 - infoCirculos[this.state.patologySelected][2].prob }}>
            
                                        {props => <Donut width={5} data={[
                                            { value: props.value, color: infoCirculos[this.state.patologySelected][2].color},
                                            {value:props.vol,color:'rgba(255, 255, 255, 0.244)'}

                                        ]}>
                                                </Donut >}
                                    </Spring>
                                    
                                    <Spring 
                                        delay='1000'
                                        config={{tension: 100, friction: 92, precision: 0.5}}
                                        from={{ number: 0 }}
                                        to={{ number:infoCirculos[this.state.patologySelected][2].prob}}>
                                        {props => <p className='probabilidad-patologia-small'>{Math.floor(props.number)}%</p>}
                                    </Spring>                                                
                                </div>                                
                            </div>
                        </div>
                        </Fade>
                        <Fade duration={1000} delay={400} spy={this.state.patologySelected}>
                        <div className='nombre-patology-final'>
                                <p className='titulo-patology-small'>
                                    {this.props.state.patologia[this.state.patologySelected].titulo}
                                </p>
                                <p className={cssSubtitulo[this.state.patologySelected]}>
                                    {this.props.state.patologia[this.state.patologySelected].subtitulo}
                                </p>
                        </div>
                        </Fade>
                    </div>
                    <div className = {cssSelector[this.state.selector]}>
                        <div className='informacion-selector' onClick={()=>this.setState({selector:0})}>
                            <p className='text-info'>Información</p>
                            <img src={iconInfo} className='icon-final' />
                        </div>
                        <div className='como-curar-selector' onClick={()=>this.setState({selector:1})}>
                            <img src={iconCurar} className='icon-final' />
                            <p className='text-como-curar'>Cómo curar</p>                           
                        </div>
                        {this.state.selector == 0?
                            <Fade left distance={'17px'} duration={900} delay={300}>
                            <>
                            <div className='def-prob-container'>
                                <div className='defContainer'>
                                    <div className='etiqueta queEs'>
                                        <p>¿Qué es?</p>
                                    </div>
                                    <p className='titulo-info-inside'>{this.props.state.patologia[this.state.patologySelected].titulo}</p>
                                    <p className='subtitulo-inside'>{this.props.state.patologia[this.state.patologySelected].subtitulo}</p>
                                    <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.def}</p>
                                </div>
                            </div>
                            <div className='sintoma-causa-container'>
                                <div className='sintomas-container'>
                                    <div className='etiqueta sintomas'>
                                        <p>Síntomas</p>
                                    </div>
                                    <p className='text-sintomas'>Síntomas más relevantes</p>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.sintomas[0]}</p>
                                    </div>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.sintomas[1]}</p>
                                    </div>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.sintomas[2]}</p>
                                    </div>
                                </div>
                                <div className='sintomas-container'>
                                    <div className='etiqueta causas'>
                                        <p>Causas</p>
                                    </div>
                                    <p className='text-sintomas'>Causas más relevantes</p>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.causas[0]}</p>
                                    </div>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.causas[1]}</p>
                                    </div>
                                    <div className='sintoma-container'>
                                        <img src={flecha} className='flecha-sintomas' />
                                        <p className='info-text'>{this.props.state.patologia[this.state.patologySelected].texto.causas[2]}</p>
                                    </div>
                                </div>
                            </div>
                            {this.props.state.patologia[this.state.patologySelected].texto.link == ''?'':
                                    <a href={this.props.state.patologia[this.state.patologySelected].texto.link} style={{textDecoration:'none'}}>
                                        <div className='boton-como-curar-container'>
                                            <div className='boton-mas-info'>
                                                <img src={iconInfo} />
                                                <p className=''>Más información</p>
                                            </div>
                                        </div>
                                    </a>
                                }
                                <div className='boton-como-curar-container'>
                                    <div className='boton-como-curar' onClick={()=>this.setState({selector:1},()=>window.scrollTo(0,0))}>
                                        <img src={iconCurar} />
                                        <p className='continuar-text'>Cómo curar</p>
                                    </div>
                                </div>
                            </>
                            </Fade>
                        
                        :
                        <Fade right distance={'17px'} duration={900} delay={300}>
                        <div className='container-90'>
                            <h2>¿Cómo curar?</h2>
                            <p>{curarText[this.props.state.patologia[this.state.patologySelected].texto.gravedad]}</p>
                            {this.props.state.patologia[this.state.patologySelected].texto.gravedad != 2?
                            <><h3>Pedir un programa de ejercicios personalizado</h3>
                            <p>Tu fisioterapeuta te programará los ejercicios más eficaces para aliviar los síntomas y recuperarte mediante una aplicación con vídeos explicativos e información acerca de tu lesión.</p>
                            <a href='https://fisify.com/tratamiento' target="_blank" style={{textDecoration:'none'}}>
                                <div className='servicios-container'>
                                    <img src={programaEjercicios} className='largo' />
                                    <h3>Quiero un programa de ejercicios personalizado</h3>
                                    <p>Un fisioterapeuta preparará tu programa en menos de 24h</p>
                                    <div className='etiqueta-pequeña primero'>
                                        Personalizado
                                    </div>
                                    <div className='etiqueta-pequeña medio'>
                                        1 mes
                                    </div>
                                    <div className='etiqueta-pequeña ultimo'>
                                        14.99€
                                    </div>
                                </div>
                            </a></>:''}
                            {this.props.state.patologia[this.state.patologySelected].texto.gravedad != 2?
                            <h3>Consulta por videollamada + Programa de ejercicios</h3>:<h3>Consulta por videollamada</h3>}
                            <p>Realiza una consulta con un fisioterapeuta el cual te realizará una valoración personal ofreciéndote una respuesta precisa y una solución efectiva a largo plazo.</p>
                            <a href='https://fisify.com/tratamiento' target="_blank" style={{textDecoration:'none'}}>
                                <div className='servicios-container'>
                                    <img src={videollamada} className='corto'/>
                                    <h3>Videollamada con un fisioterapeuta</h3>
                                    <p>Diagnóstico preciso más un tratamiento personalizado diseñado a tu medida</p>
                                    <div className='etiqueta-pequeña primero'>
                                        Especializado
                                    </div>
                                    <div className='etiqueta-pequeña medio'>
                                        30 min
                                    </div>
                                    <div className='etiqueta-pequeña ultimo'>
                                        35.99€
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        </Fade>}

                    </div>
                </div>
                </>
            )
        }
    }
}

export default MainResultados;